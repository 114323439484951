import moment from "moment"
import Person from "./Person"

const usePersonUtility = () => {
    const isActive = (person:Person):boolean => {
        if(!person) {
            return false
        }
        var entry = person.entry ? moment(person.entry) : moment()
        var exit = person.exit_date ? moment(person.exit_date) : moment()
        return moment().isBetween(entry, exit, 'day', '[]')
    }

    const getAge = (person:Person):number => {
        return person ? moment().diff(person.birthday, 'years') : -1
    }

    const isUnstaffedCustomer =  (person:Person):boolean => {
        return isActive(person) && person.customer && person.assigned_employees.length === 0
    }

    const isUnderusedEmployee = (person: Person): boolean => {
        return (
            isActive(person) && 
            person.employee && 
            person.assigned_customers.length === 0
        )
    }

    const flaggedForDeletion = (person:Person) : boolean => {
        return person && moment().add(1, 'day').isSameOrAfter(person.deleteAt, 'day')
    }

    return {
        isActive,
        getAge,
        isUnstaffedCustomer,
        isUnderusedEmployee,
        flaggedForDeletion
    }
}

export default usePersonUtility