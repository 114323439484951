import Modal, { IModalProps, ModalFooter } from "components/modal/Modal"
import Spacer from "components/Spacer/Spacer";
import Address from "./Address";
import useAddressForm from "./useAddressForm";
import AddressForm from "./AddressForm";
import FormCreateButton from "Forms/FormCreateButton";
import FormDeleteButton from "Forms/FormDeleteButton";
import FormUpdateButton from "Forms/FormUpdateButton";
import { useCreateAddress, useDeleteAddress, useUpdateAddress } from "./AddressApi";

interface Props extends IModalProps<Address> {}

const AddressModal = ({ selected:address, setVisible, ...props }:Props) => {

    const isUpdate = address ? 'id' in address : false
    const title = isUpdate ? "Anschrift bearbeiten" : "Anschrift erfassen";
    const form = useAddressForm(address)
    const createAddress = useCreateAddress()
    const updateAddress = useUpdateAddress()
    const deleteAddress = useDeleteAddress()
    const mutateOptions = {
        onSettled: () => setVisible(false)
    }

    const handleCreate = () => {
        createAddress.mutate(form.data, mutateOptions)
    }

    const handleUpdate = () => {
        updateAddress.mutate(form.data, mutateOptions)
    }

    const handleDelete = () => {
        deleteAddress.mutate(form.data.id, mutateOptions)
    }

    return (
        <Modal 
            title={title} 
            selected={address} 
            setVisible={setVisible}
            {...props}
        >
            <Spacer medium>
                <AddressForm form={form}/>
                <ModalFooter>
                    <FormCreateButton
                        form={form}
                        visible={!isUpdate}
                        onClick={handleCreate}
                    />
                    <FormUpdateButton
                        form={form}
                        visible={isUpdate}
                        onClick={handleUpdate}
                    />
                    <FormDeleteButton
                        form={form}
                        visible={isUpdate}
                        onClick={handleDelete}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

export default AddressModal