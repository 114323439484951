import { FormGroup } from "components/form/FormComponents";
import TextInput from "components/input/TextInput";
import Label from "components/labels/Label";
import Row from "components/Row";
import IForm from "Forms/IForm";
import AddressUtility from "./AddressUtility";

interface Props {
    form:IForm
}

export default function AddressForm({form}:Props) {

    return (
        <>
            <FormGroup>
                <TextInput
                    name='street'
                    value={form.getFieldValue('street')}
                    onChange={(event) => form.setFieldValue('street', event.target.value)}
                    label='Straße'
                />
                <TextInput
                    name='postcode'
                    value={form.getFieldValue('postcode')}
                    onChange={(event) => form.setFieldValue('postcode', event.target.value)}
                    label='Postleitzahl'
                />
                <TextInput
                    name='city'
                    value={form.getFieldValue('city')}
                    onChange={(event) => form.setFieldValue('city', event.target.value)}
                    label='Stadt'
                />
                <TextInput
                    name='district'
                    value={form.getFieldValue('district')}
                    onChange={(event) => form.setFieldValue('district', event.target.value)}
                    label='Stadtteil'
                />
            </FormGroup>
            <FormGroup>
                <Row>
                    <Label subtle>{`Geoposition: ${AddressUtility.getGeopositionLabel(form.data)}`}</Label>
                </Row>
            </FormGroup>
        </>
    )
}