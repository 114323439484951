import Checkbox from "components/checkbox/Checkbox";
import Column from "components/Column";
import { FormGroup } from "components/form/FormComponents";
import DatePicker from "components/input/DatePicker";
import EuroInput from "components/input/euro-input";
import HourInput from "components/input/hour-input";
import KilometerInput from "components/input/kilometer-input";
import TextInput from "components/input/TextInput";
import Row from "components/Row";
import Spacer from "components/Spacer/Spacer";
import IForm from "Forms/IForm";
import TaxBracketSelect from "TaxBrackets/TaxBracketSelect";
import WorkfieldSelect from "WorkDetails/WorkfieldSelect";
import EmployeeStateSelect from "./EmployeeStateSelect";

interface Props {
    form:IForm
}

export default function EmployeeForm({form}:Props) {
    return (
        <Column gap='1em'>
            <div>
                <EmployeeStateSelect
                    label='Status'
                    onChange={({ value }) => form.setFieldValue('state', value)}
                    value={form.data.state}
                />
            </div>
            <Row gap='1em'>
                <Column width={'20em'} gap='0.25em'>
                    <WorkfieldSelect
                        label='Tätigkeit'
                        onChange={({ value }) => form.setFieldValue('work_field_id', value)}
                        value={form.data.work_field_id}
                    />
                    <Checkbox
                        label='Hauswirtschaft'
                        onChange={({ value }) => form.setFieldValue('housekeeping', value)}
                        value={form.data.housekeeping}
                    />
                    <Checkbox
                        label='Betreuung'
                        onChange={({ value }) => form.setFieldValue('home_care', value)}
                        value={form.data.home_care}
                    />
                    <Checkbox
                        label='Demenz'
                        onChange={({ value }) => form.setFieldValue('dementia', value)}
                        value={form.data.dementia}
                    />
                    <TextInput
                        label='Gelernter Beruf'
                        onChange={(event) => form.setFieldValue('occupation', event.target.value)}
                        value={form.data.occupation}
                    />
                    <Checkbox
                        label='Pflegeausbildung'
                        onChange={({ value }) => form.setFieldValue('nursing_training', value)}
                        value={form.data.nursing_training}
                    />
                    <Checkbox
                        label='Nebenjob'
                        onChange={({ value }) => form.setFieldValue('sideline_job', value)}
                        value={form.data.sideline_job}
                    />
                    <Checkbox
                        label='Minijob'
                        onChange={({ value }) => form.setFieldValue('minijob', value)}
                        value={form.data.minijob}
                    />
                    <HourInput
                        label='Monatliche Stunden'
                        onChange={(event) => form.setFieldValue('monthly_hours', event.currentTarget.value)}
                        value={form.data.monthly_hours}
                    />
                    <KilometerInput
                        label='Einsatzradius'
                        onChange={(event) => form.setFieldValue('operation_radius', event.currentTarget.value)}
                        value={form.data.operation_radius}
                    />
                    <EuroInput
                        label='Stundenlohn'
                        onChange={(event) => form.setFieldValue('hourly_wage', event.currentTarget.value)}
                        value={form.data.hourly_wage}
                    />
                </Column>
                <Column width={'20em'}>
                    <FormGroup>
                        <Checkbox
                            label='Führerschein'
                            onChange={({ value }) => form.setFieldValue('driving_license', value)}
                            value={form.data.driving_license}
                        />
                        <Checkbox
                            label='Auto'
                            onChange={({ value }) => form.setFieldValue('car', value)}
                            value={form.data.car}
                        />
                        <Checkbox
                            label='Führungszeugnis'
                            onChange={({ value }) => form.setFieldValue('clearance_certificate', value)}
                            value={form.data.clearance_certificate}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextInput
                            label='Sozialversicherungsnummer'
                            onChange={(event) => form.setFieldValue('social_insurance_number', event.target.value)}
                            value={form.data.social_insurance_number}
                        />
                        <TaxBracketSelect
                            label='Steuerklasse'
                            onChange={({ value }) => form.setFieldValue('tax_bracket_id', value)}
                            value={form.data.tax_bracket_id}
                        />
                        <TextInput
                            label='Steuernummer'
                            onChange={(event) => form.setFieldValue('tax_number', event.target.value)}
                            value={form.data.tax_number}
                        />
                        <Checkbox
                            label='Elterneigenschaft'
                            onChange={({ value }) => form.setFieldValue('parenthood', value)}
                            value={form.data.parenthood}
                        />
                        {
                            Boolean(form.data.parenthood) &&
                            <EuroInput
                                label='Kinderfreibetrag'
                                onChange={(event) => form.setFieldValue('child_allowance', event.currentTarget.value)}
                                value={form.data.child_allowance || 0}
                            />
                        }
                        <Checkbox
                            label='Rentenversicherungspflichtig'
                            onChange={({ value }) => form.setFieldValue('annuity_insurance', value)}
                            value={form.data.annuity_insurance}
                        />
                        <Checkbox
                            label='Befristet'
                            onChange={({ value }) => form.setFieldValue('determinable', value)}
                            value={form.data.determinable}
                        />
                        {
                            Boolean(form.data.determinable) &&
                            <Spacer vSmall>
                                <DatePicker
                                    label='Befristet bis'
                                    onChange={(value) => form.setFieldValue('determinable_till', value)}
                                    value={form.data.determinable_till}
                                />
                            </Spacer>
                        }
                    </FormGroup>
                </Column>
            </Row>
        </Column>
    )
}