import Checkbox from 'components/checkbox/Checkbox'
import Label from 'components/labels/Label'
import styled from 'styled-components'

const StyledSection = styled.div`
    margin-bottom: 0.75em;
    width: 100%;
    display: flex;
`

const SectionHeader = styled.span`
    font-size: 1.0em;
    font-weight: 600;
    margin-top: 0.6em;
    display: block;
    width: ${props => props.width || '10em'};
    flex-shrink: 0;
    text-align: right;
    margin-right: 0.5em;
`

const SectionContent = styled.span`
    flex-grow: 1;
`

const Section = ({label, children, ...props}) => {
    return (
        <StyledSection>
            <SectionHeader width={props.width}>{label}</SectionHeader>
            <SectionContent>
                { children }
            </SectionContent>
        </StyledSection>
    )
}

const StyledSubsection = styled.div`
    margin-bottom: 0.5em;
`

const SubsectionHeader = styled.span`
    font-size: 0.8em;
    color: #888;
    font-weight: 600;
`

const Subsection = ({ label, children }) => {
    return (
        <StyledSubsection>
            <SubsectionHeader>{label}</SubsectionHeader>
            {children}
        </StyledSubsection>
    )
}

const Group = styled.div`
    margin-bottom: 0.75em;

    &:last-child {
        margin-bottom: 0;
    }
`

const GroupHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.3em;
`

const GroupLabel = styled(Label)`
    display: block; 
    font-weight: bold;
    color: #222;
`

const GroupContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25em;
`

const FormGroup = ({ 
    label=null, 
    active=true,
    setActive=undefined,
    children 
}) => {

    const Label = () => {
        if(label) {
            return <GroupLabel>{label}</GroupLabel>
        }
        return null
    }

    return (
        <Group>
            <GroupHeader>
                {
                    setActive ? 
                        <Checkbox value={active} onChange={({value}) => setActive(value)}>
                            <Label/>
                        </Checkbox>
                    :
                    <Label/>
                }
            </GroupHeader>
                  
            <GroupContent>
                {
                    active && children 
                }
            </GroupContent>
        </Group>
    )
}

export { Section, Subsection, FormGroup }