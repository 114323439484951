import CurrencyLabel from "components/labels/currency-label";
import DateLabel from "components/labels/DateLabel";
import HourLabel from "components/labels/hour-label";
import KilometerLabel from "components/labels/kilometer-label";
import TimeLabel from "components/labels/time-label";
import { useMemo } from "react";
import TableCell from "components/table/Cells/TableCell";
import EventUtility from "./EventUtility";
import EventStatus from "./EventStatus";
import Label from "components/labels/Label";
import useEventUtility from "./useEventUtility";

export default (options = {}) => {

    const { eventIsConfirmed } = useEventUtility()

    const columns = useMemo(() => {
        let columnList = [
            {
                Header: 'Kunde',
                accessor: d => d,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>
                                {`${value.customer_first_name} ${value.customer_last_name}`}
                            </Label> 
                        )}
                    />
                ),
            },
            {
                Header: 'Mitarbeiter',
                accessor: d => d,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>
                                {`${value.employee_first_name} ${value.employee_last_name}`}
                            </Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Datum',
                accessor: d => d.date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Uhrzeit',
                accessor: d => d.time,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <TimeLabel startTime={value}></TimeLabel>
                        )}
                    />
                ),
            },
            {
                Header: 'Stunden',
                accessor: d => parseFloat(d.hours),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <HourLabel value={value}></HourLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var events = data.flatRows
                                    .map(row => row.original)
                                    .filter(event => eventIsConfirmed(event))
                    const sum = events.reduce((sum, event) => sum + event.hours, 0)
                    return (
                        <HourLabel bold value={sum}></HourLabel>
                    )
                }
            },
            {
                Header: 'Kilometer',
                accessor: d => parseFloat(d.kilometer),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <KilometerLabel value={value}></KilometerLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var events = data.flatRows
                                    .map(row => row.original)
                                    .filter(event => eventIsConfirmed(event))
                    const sum = events.reduce((sum, event) => sum + event.kilometer, 0)
                    return (
                        <KilometerLabel bold value={sum}></KilometerLabel>
                    )
                }
            },
            {
                Header: 'Status',
                accessor: d => d,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <EventStatus event={value}/>
                        )}
                    />
                ),
                Footer: (data) => {
                    var events = data.flatRows
                                    .map(row => row.original)
                                    .filter(event => eventIsConfirmed(event))
                    const costSum = events.reduce((sum, event) => sum + EventUtility.getCost(event), 0)

                    return <CurrencyLabel bold value={costSum} />
                }
            },
        ];

        if ('hidden' in options) {
            columnList = columnList.filter(column => !options.hidden.includes(column.Header));
        }

        return columnList;
    },
        [options]
    )

    return columns;
}