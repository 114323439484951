import RelationshipSelect from "Relationships/RelationshipSelect";
import TextInput from "components/input/TextInput";
import { FormGroup } from "components/form/FormComponents";

export default function ContactPersonForm({ formData, handleFormChange}) {

    return (
        <FormGroup>
            <TextInput
                label='Name'
                width='14em'
                placeholder="Name"
                name='name'
                type="text"
                onChange={handleFormChange}
                value={formData.name ?? ""}
            />
            <RelationshipSelect
                label='Verhältnis'
                placeholder='Beziehung'
                name='relationship_id'
                value={formData.relationship_id ?? -1}
                onChange={handleFormChange} 
            />
        </FormGroup>                 
    );
}