import { useQuery } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Denomination from "./Denomination"

const useDenominations = () => {
    return useQuery({
        queryKey: ['denominations'],
        queryFn: () => ameli.get<Denomination[]>('/denominations').then(res => res.data)
    })
}

export default useDenominations