import moment, { Moment } from 'moment';
import { useCallback } from 'react';
import OGCalendar, { CalendarProps } from 'react-calendar'
import './calendar.css'

type CalendarEntry = {
    className?:string
}

export type CalendarEntries = { [key: string]: CalendarEntry }

type Props = CalendarProps & {
    entries?: CalendarEntries,
    selectedDate?: Moment,
    setYearMonth: (year: number, month: number) => void
    setDate: (date: Moment) => void
}

const Calendar = ({
    selectedDate,
    setYearMonth,
    setDate,
    entries = {}, 
    ...props
}:Props) => {


    const tileClassName = useCallback(({ date }) => {
        var key = moment(date).format('YYYY-MM-DD')
        return entries[key]?.className || null
    }, [entries])

    const onClickDay = useCallback((date:Date) => {
        setDate(moment(date.toISOString()))
    }, [setDate])

    const onActiveStartDateChange = useCallback(({ activeStartDate }) => {
        if(selectedDate) {
            var newDate = moment(activeStartDate)
            newDate.date(selectedDate.date())
            setDate(newDate)
        }
        else {
            onClickDay(activeStartDate)
        }
    }, [setDate, onClickDay, selectedDate])

    return (
        <OGCalendar
            {...props}
            tileClassName={tileClassName}
            value={selectedDate? selectedDate.toDate() : null}
            onClickDay={onClickDay}
            onActiveStartDateChange={onActiveStartDateChange}
            showNeighboringMonth={false}
        />
    )
}

export default Calendar