
import Label from 'components/labels/Label';
import HoverEditIcon from '../components/Icons/HoverEditIcon';
import { useMemo } from 'react';
import ListView from 'components/ListView/ListView';


const ClinicalPictureList = ({ clinicalPicture }) => {

    const listData = useMemo(() => {
        return [
            ...clinicalPicture?.dementia === 1 ? ["Demenz"] : [],
            ...clinicalPicture?.mobility === 1 ? ["Eingeschränkte Mobilität"] : [],
            ...clinicalPicture?.cancer === 1 ? ["Krebserkrankung"] : [],
            ...clinicalPicture?.heart_disease === 1 ? ["Herzerkrankung"] : [],
            ...clinicalPicture?.copd === 1 ? ["COPD-Erkrankung"] : [],
            ...clinicalPicture?.imbalance === 1 ? ["Gleichgewichtsstörungen"] : [],
            ...clinicalPicture?.dialysis === 1 ? ["Dialysepatient"] : [],
            ...clinicalPicture?.disabled === 1 ? ["Geistige-/körperliche Behinderung"] : [],
            ...clinicalPicture?.pain === 1 ? ["Schmerzpatient"] : [],
            ...clinicalPicture?.other !== '' ? [clinicalPicture?.other] : []
        ]
    }, [clinicalPicture])

    if(listData.length === 0) {
        return (
            <Label>-</Label>
        )
    }

    return (
        <ListView
            data={listData}
            listItem={(data) => (<li><Label medium>{data}</Label></li>)}
            Separator={() => null}
        />
    )
}

export default ClinicalPictureList