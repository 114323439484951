import SubmitButton from "Forms/SubmitButton"
import { IFormButtonProps } from "./IFormButtonProps"


export default function FormDeleteButton({form, label='Löschen', ...props}:IFormButtonProps) {

    return (
        <SubmitButton {...props}>
            {label}
        </SubmitButton>
    )
}