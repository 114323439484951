import useVacationRequests from "Vacations/Requests/useVacationRequests"
import React, { useMemo } from "react"

const ApiDataContext = React.createContext({})

const ApiDataProvider = ({...props}) => {

    const vacationRequestParams = useMemo(() => ({
    }), [])
    const vacationRequests = useVacationRequests(vacationRequestParams)

    return (
        <ApiDataContext.Provider
            { ...props }
            value={{
                vacationRequests
            }}
        />
    )
}

export { ApiDataContext, ApiDataProvider }