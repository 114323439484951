import { useMemo } from "react";
import Dropdown from "../components/dropdown/dropdown";

export default function CareLevelSelect(props) {

    const options = useMemo(() => [
        { value: null, label: 'Ohne' },
        { value: 1, label: 'Pflegegrad 1' },
        { value: 2, label: 'Pflegegrad 2' },
        { value: 3, label: 'Pflegegrad 3' },
        { value: 4, label: 'Pflegegrad 4' },
        { value: 5, label: 'Pflegegrad 5' },
    ], [])

    return (
        <Dropdown 
            options={options}
            {...props}>             
        </Dropdown>
    )
}