import Spacer from "components/Spacer/Spacer"
import Row from "components/Row"
import IconWrapper from "components/Icons/IconWrapper"


const useActionColumn = (actions) => {
    return {
        Header: 'Aktionen',
        accessor: d => d,
        Cell: ({ value, ...props }) => (
            <Spacer medium>
                <Row centered>
                    {
                        actions.map(({onClick, Icon}, index) => (
                            <IconWrapper key={index} onClick={() => onClick(value)}>
                                <Icon/>
                            </IconWrapper>
                        ))
                    }
                </Row>
            </Spacer>
        ),
    }
}

export default useActionColumn