import styled from 'styled-components'

const Badge = styled.span`
    border-radius: 5px;
    background-color: #ce0069;
    color: #f7f7f7;
    font-size: 0.7em;
    padding: 0.2em 0.5em;
`

export default Badge