import { formatFloat } from "utils/string-utility";
import Label from "./Label";

export default function KilometerLabel({value, ...props}) {

    return (
        <Label {...props}>
            {`${formatFloat(value)} km`}
        </Label>
    )
}