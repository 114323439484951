import { Document, Page } from '@react-pdf/renderer'
import OperationReportDocument from './OperationReportDocument';
import OperationReport from './OperationReport';
import { useMemo } from 'react';
import moment from 'moment';

export interface IOperationReportProps {
    report: OperationReport
}

const OperationReportPdf = ({ report }:IOperationReportProps) => {

    const title = useMemo(() => {
        if(!report) {
            return "Unbekannt"
        }
        return `${report.employeeLastName}, ${report.employeeFirstName} ${moment(report.yearMonth).format('MMM/YYYY') }`
    }, [report])

    return (
        <Document title={title}>
            <Page size='A4'>
                <OperationReportDocument report={report}/>
            </Page>
        </Document>
    )
}

export default OperationReportPdf