import { useMemo } from "react"
import { IModel } from "./IModel"
import usePatch from "../Api/usePatch";
import { Actions } from "../Actions/Action";
import usePost from "../Api/usePost";
import useDelete from "../Api/useDelete";
import { PartialObject } from "lodash";
import useObject, { IObject } from "Common/Objects/useObject";

export type IModelInstance<T> = IObject & {
    model: IModel<T>,
    actions: Actions,
}

export default function useModelInstance<T>(model:IModel<T>, initialData:PartialObject<T>={}) : IModelInstance<T> {

    const object = useObject(model.objectDefinition, initialData)

    const update = usePatch(model.apiPath, model.queryKeys, object)
    const create = usePost(model.apiPath, model.queryKeys, object)
    const erase = useDelete(model.apiPath, model.queryKeys, object)

    const actions = useMemo(() => {
        return {
            update,
            create,
            erase
        }
    }, [create, update, erase])

    return {
        ...object,
        model,
        actions
    }
}