import { useCallback } from 'react';
import useApiData from 'hooks/useApiData';
import useSignalingActions from 'Signals/useSignalingActions';
import ameli from 'Api/ameli';

const channel = ['EmployeeNotes']

const actions = (employeeId) => ({
    'create': data => ameli.post(`/notes/employees`, { employee_id: employeeId, ...data }),
    'delete': id => ameli.delete(`/notes/${id}`),
    'update': data => ameli.put(`/notes`, data)
})

const useEmployeeNoteActions = (employeeId) => {
    return useSignalingActions(actions(employeeId), channel)
}

export default function useEmployeeNotes(employeeId) {

    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get(`/notes/employees`, { params: { employee_id: employeeId } })
            .then(response => { resolve(response.data)})
    }), [employeeId])


    const state = useApiData(loadHandler, channel)

    return state
}

export { useEmployeeNoteActions }