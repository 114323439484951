import styled from 'styled-components'
import EmployeeScheduleRow from './EmployeeScheduleRow'
import EmployeeScheduleHeader from './EmployeeScheduleHeader'
import { useContext, useMemo, useState } from 'react'
import { EmployeeContext } from 'Employees/EmployeeContext'
import WeekPicker from 'components/input/WeekPicker'
import Row from 'components/Row'
import Spacer from 'components/Spacer/Spacer'
import CentralizedView from 'components/CentralizedView'
import IconWrapper from 'components/Icons/IconWrapper'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import Column from 'components/Column'
import Checkbox from 'components/checkbox/Checkbox'

const Layout = styled.div`
    display: grid;
    grid-template-columns: 12em repeat(7, 1fr);
    grid-gap: 1px;
    background-color: #dbdbdb;

    &>* {
        background-color: white;
    }
`

const Header = styled.div`
    position: sticky;
    z-index: 4;
    top: 0;
    background-color: white;
    border-bottom: 2px solid #dbdbdb;
`

const EmployeeSchedule = ({ week, setWeek, onClick }) => {

    const { data:employees } = useContext(EmployeeContext)
    const [showEmpty, setShowEmpty] = useState(false)

    const dates = useMemo(() => {
        var result = [];
        var current = new Date(week)
        var day = current.getDay()
        current.setDate((current.getDate() - day + (day === 0 ? -6 : 1)));
        for (var i = 0; i < 7; i++) {
            result.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
        }
        return result;
    }, [week])

    const alterWeek = (value) => {
        var newWeek = new Date(week)
        newWeek.setDate(newWeek.getDate() + 7 * value)
        setWeek(newWeek)
    }
    
    return (
        <div>
            <Header>
                <Spacer medium>
                    <Column gap='0.5em'>
                        <CentralizedView>
                            <Row vCentered gap='0.5em'>
                                <IconWrapper onClick={() => alterWeek(-1)}>
                                    <FaAngleLeft size={28} />
                                </IconWrapper>
                                <WeekPicker
                                    value={week}
                                    onChange={(event) => setWeek(event.value)}
                                    label='Kalenderwoche'
                                    isClearable={false}
                                />
                                <IconWrapper onClick={() => alterWeek(1)}>
                                    <FaAngleRight size={28} />
                                </IconWrapper>
                            </Row>                       
                        </CentralizedView>
                        <Checkbox
                            label='Zeige leere Zeilen'
                            value={showEmpty}
                            onChange={({ value }) => setShowEmpty(value)}
                        />
                    </Column>
                </Spacer>
                <Layout>
                    <EmployeeScheduleHeader dates={dates}/>
                </Layout>
            </Header>
            <Layout>

                {
                    employees?.sort((a, b) => a.last_name.localeCompare(b.last_name)).map((employee) => (
                        <EmployeeScheduleRow
                            showEmpty={showEmpty}
                            onClick={onClick}
                            key={employee.id}
                            employee={employee}
                            dates={dates}
                        />
                    ))
                }
            </Layout>
        </div>
        
    )
}

export default EmployeeSchedule