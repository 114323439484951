import styled from 'styled-components'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: ${props => props.alignment ? props.alignment : props.vCentered ? 'center' : 'flex-start'};
    justify-content: ${props => props.hCentered ? 'center' : 'flex-start'};
    flex-grow: ${props => props.grow ? '1' : '0'};
    gap: ${props => props.gap || '0'}
`
export default Row