import ListView from "components/ListView/ListView"
import Label from "components/labels/Label"


export default function InvolvedPersonsCell({persons}) {

    return (
        <ListView
            data={persons}
            Separator={() => null}
            listItem={(person) => (
                <Label>
                    {`${person.first_name} ${person.last_name}`}
                </Label>
            )}
        />
    )
}