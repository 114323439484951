import NoteList from "Notes/NoteList";
import { FaBook, FaPlus } from "react-icons/fa";
import useEmployeeNotes, { useEmployeeNoteActions } from './useEmployeeNotes';
import useModal from 'components/modal/useModal';
import Tile from "components/Tiles/Tile";
import NoteModal from "OperationNotes/OperationNoteModal";
import Spacer from "components/Spacer/Spacer";


const EmployeeNoteTile = ({employee}) => {

    const { data } = useEmployeeNotes(employee?.person_id)
    const employeeNoteActions = useEmployeeNoteActions(employee?.person_id)
    const modal = useModal()

    return (
        <Tile
            title='Anmerkungen'
            Icon={<FaBook/>}
            actions={[
                {
                    Icon: <FaPlus />,
                    execute:() => modal.select({ employee_id: employee?.person_id })
                }
            ]}
        >
            <NoteModal {...modal} dispatchAction={employeeNoteActions.dispatch}/>
            <Spacer hMedium>
                <NoteList notes={data} onClick={modal.select} />
            </Spacer>
        </Tile>
    )
}

export default EmployeeNoteTile