import React from "react"

export const ColumnFilter = ({ column }) => {
    return (
        column.Filter && column.canFilter ? 
            <div>
                { column.render("Filter")}
            </div>
        :
            null
    )
}