import InputWrapper from 'components/input/InputWrapper';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components'
import { useState } from 'react';

const Select = styled(CreatableSelect)`
    flex-grow: 1;
`

export default function DropdownInput({ label=undefined, options, ...props}){

    const [focused, setFocused] = useState(false)

    function simulateChangeEvent(event) {
        if (props.onChange) {
            if(Array.isArray(event)) {
                const newValue = event.map(element => element.value).join(',');
                props.onChange({value:newValue, name:props.name});
            }
            else if(event){
                props.onChange({value:event.value, label: event.label, name: props.name });
            }
            else {
                props.onChange({value:null, name: props.name});
            }
        }
    }

    const parseValue = (value) => {
        if(value) {
            return value.split(',').map(element => {
                return { 
                    value:element, 
                    label:element 
                };
            });
        }
        return null;
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minWidth: '10em',
            transition: '0.25s',
            backgroundColor: 'transparent',
            boxShadow: state.isFocused ? null : null,
            border: 'none',
            cursor: 'pointer',
            flex: 1,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingLeft: '0.5em'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            marginLeft: '0.0em'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 2
        })
    };

    return (
        <InputWrapper focused={focused} label={label}>
            <Select
                styles={customStyles}
                isClearable
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                placeholder="-"
                value={parseValue(props.value)}
                options={options}          
                onChange={event => simulateChangeEvent(event)}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#ce0069'
                    }
                })}
            />
        </InputWrapper>
    );
}