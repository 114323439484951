import styled from 'styled-components'

const Container = styled.div`
    cursor: pointer;
    pointer-events: ${props => props.enabled ? 'auto' : 'none'};
    opacity: ${props => props.enabled ? 1.0 : 0.35};
    transition: 0.25s;

    &:hover {
        opacity: ${props => props.enabled ? 0.5 : 1.0};;
    }
`

interface ClickableOpacityProps {
    children?: React.ReactNode,
    enabled?: boolean,
    onClick?: () => any
}

const ClickableOpacity = ({children, enabled=true, onClick=undefined}) => {

    if(!onClick) {
        return (
            <>
                { children }
            </>
        )
    }

    return (
        <Container enabled={enabled} onClick={onClick}>
            { children }
        </Container>
    )
}

export default ClickableOpacity
export type { ClickableOpacityProps }