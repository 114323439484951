import { useReducer } from "react";

export interface IModal<T> {
    selected: T | null,
    select: (T) => void,
    visible: boolean,
    setVisible: (visible:boolean) => void
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'select':
            return { ...state, visible: true, selected: action.payload}
        case 'set_visible':
            return { ...state, visible: action.payload }
        default:
            return state
    }
}


export default function useModal<T>(initialSelection={}) : IModal<T> {
    const [state, dispatch] = useReducer(reducer, {
        selected: initialSelection,
        visible: false
    })

    const select = (value) => {
        dispatch({
            type: 'select',
            payload: value
        })
    }

    const setVisible = (value) => {
        dispatch({
            type: 'set_visible',
            payload: value
        })
    }

    return {
        selected: state.selected, 
        select,
        visible: state.visible,
        setVisible
    }
}