import Label from "components/labels/Label";
import TableCell from "../Cells/TableCell";

export const labelColumn = (header, accessor) => ({
    Header: header,
    accessor,
    Cell: ({value, ...props}) => (
        <TableCell
            {...props}
            renderLabel={() => (<Label>{value}</Label>)}
        />
    )
})