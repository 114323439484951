import styled from 'styled-components'

const Container = styled.div`
    background-color: white;
    margin-right: 0.2em;
    border-radius: 4px;
    color: #ce0069;
    padding: 0.24em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ce0069;

    &:hover {
        transition: 0.3s;
        background-color: #ce0069;
        color: white;
    }
`

const IconButton = ({Icon, ...props}) => {
    return (
        <Container {...props}>
            <Icon size={14}/>
        </Container>
    )
}

export default IconButton