import { useCallback, useMemo } from "react"

const useLookup = (key, data) => {
    const lookup = useMemo(() => {
        var result = {}
        for(var i= 0; i < data?.length; i++) {
            var entry = data[i]
            var keyValue = entry[key]
            var value = result[keyValue] || []
            result[keyValue] = [...value, i]
        }

        return result
    }, [data, key])

    const get = useCallback((key) => {
        var values = lookup[key] || []
        return values.map(index => data[index])
    }, [lookup, data])

    const getFirst = useCallback((key) => {
        return get(key)[0] || null
    }, [get])

    return {
        get,
        getFirst
    }
}

export default useLookup