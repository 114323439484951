import CustomerSelect from "../Customer/CustomerSelect"
import DatePicker from "components/input/DatePicker"
import HourInput from "components/input/hour-input"
import Row from "components/Row"
import EmployeeSelect from "Employees/EmployeeSelect"
import { FormGroup } from "components/form/FormComponents"
import Checkbox from "components/checkbox/Checkbox"

const AssignmentForm = ({form}) => {

    if(!form) {
        return null
    }

    return (
        <FormGroup>
            <CustomerSelect 
                label='Kunde' 
                value={form.getFieldValue('customer_id')} 
                onChange={e => form.setFieldValue('customer_id', e.target.value)}
                name='customer_id' 
            />
            <EmployeeSelect
                label='Mitarbeiter'
                value={form.getFieldValue('employee_id')}
                onChange={e => form.setFieldValue('employee_id', e.target.value)}
                name='employee_id'
            />
            <Row grow>
                <DatePicker 
                    grow label='Beginn' 
                    optional
                    value={form.getFieldValue('start_date')} 
                    onChange={date => form.setFieldValue('start_date', date)} 
                    name='start_date' 
                />
                <DatePicker 
                    grow 
                    optional
                    label='Ende' 
                    value={form.getFieldValue('end_date')} 
                    onChange={date => form.setFieldValue('end_date', date)} 
                    name='end_date' 
                />
            </Row>
            <HourInput 
                label="Stunden pro Monat" 
                value={form.getFieldValue('monthly_hours')}
                onChange={e => form.setFieldValue('monthly_hours', e.currentTarget.value)} 
                name='monthly_hours' 
            />
            <Checkbox
                label='In Kundenbörse anzeigen'
                value={form.getFieldValue('advertise')}
                onChange={({ value }) => form.setFieldValue('advertise', value)}
            /> 
        </FormGroup>
    )
}

export default AssignmentForm