import { useEffect, useRef, useState } from "react"
import styled from 'styled-components'

const Input = styled.input`
    width: 100%;
    border: none;
    box-sizing: border-box;
`

const TableCellInput = ({value:initialValue, onChange, ...props}) => {
    const [value, setValue] = useState(initialValue)
    const ref = useRef()

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        if(ref.current) {
            ref.current.focus()
        }
    }, [ref])

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            ref.current.blur()
        }
    }

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const onFocus = (event) => {
        event.target.select();
    }

    const onBlur = () => {
        onChange(value)
        props.onBlur()
    }

    return (
        <Input 
            {...props} 
            ref={ref} 
            value={value || ''}
            onFocus={onFocus} 
            onBlur={onBlur} 
            onChange={handleChange}
            onKeyDown={onKeyDown}
        />
    )
}

export default TableCellInput