import React from 'react';
import useAbsences from './useAbsences';

const AbsenceContext = React.createContext([]);

const AbsenceProvider = ({ params, children }) => {
    const absence = useAbsences(params)

    return (
        <AbsenceContext.Provider value={absence}>
            {children}
        </AbsenceContext.Provider>
    )
}

export { AbsenceContext, AbsenceProvider }