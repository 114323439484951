import IconRow from "components/Icons/IconRow"
import Label from "components/labels/Label"
import { MdEmail } from "react-icons/md"

interface Props {
    email:String | null,
    onClick?: () => any
}

export default function EmailRow({email, onClick}:Props) {
    if(!email) {
        return null
    }

    return (
        <IconRow
            onClick={onClick}
            Icon={<MdEmail/>}
        >
            <Label small subtle>{email}</Label>
        </IconRow>
    )
}