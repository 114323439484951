import { useCallback } from 'react';
import useApiData from '../hooks/useApiData';
import useSignalingActions from 'Signals/useSignalingActions';
import ameli from 'Api/ameli';
 
const channel = ['UserAppointments']

const actions = {
    'create': data => ameli.post('user_appointments', data),
    'delete': data => ameli.delete(`user_appointments/${data}`),
    'update': data => ameli.put(`user_appointments`, data)
}

const useUserAppointmentActions = () => {
    return useSignalingActions(actions, channel)
}


export default function useUserAppointments({ params }) {

    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get('user_appointments', { params })
            .then(response => resolve(response.data))
    }), [params])


    const state = useApiData(loadHandler, channel)

    return state
}

export { useUserAppointmentActions }