import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import { ClinicalPicture } from "./ClinicalPicture"


export const useClinicalPicture = (id:number=-1) : UseQueryResult<ClinicalPicture> => {
    return useQuery({
        queryKey: ['clinical_pictures', id],
        queryFn: () => ameli.get<ClinicalPicture>(`clinical_pictures/${id}`).then(res => res.data[0] || null),
        enabled: id > 0
    })
}

export function useClinicalPictureMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['clinical_pictures']})
        }
    })
}

export const useClinicalPictureUpdate = () => {
    return useClinicalPictureMutation<ClinicalPicture>(
        (data: ClinicalPicture) => ameli.put('clinical_pictures', data)
    )
}

export const useClinicalPictureDelete = () => {
    return useClinicalPictureMutation<number>(
        (clinicalPictureId:number) => ameli.delete(`clinical_pictures/${clinicalPictureId}`)
    )
}