import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import Spacer from "components/Spacer/Spacer";
import ClinicalPictureForm from "./ClinicalPictureForm";
import FormUpdateButton from "Forms/FormUpdateButton";
import { ClinicalPicture } from "./ClinicalPicture";
import useClinicalPictureForm from "./useClinicalPictureForm";
import { useClinicalPictureDelete, useClinicalPictureUpdate } from "./ClinicalPicturesApi";
import FormDeleteButton from "Forms/FormDeleteButton";

export default function ClinicalPictureModal({selected, ...props} : IModalProps<ClinicalPicture>) {

    const form = useClinicalPictureForm(selected)
    const isUpdate = Boolean(form.data?.id)
    const updateClinicalPicture = useClinicalPictureUpdate()
    const deleteClinicalPicture = useClinicalPictureDelete()

    const update = () => {
        updateClinicalPicture.mutate(form.data, {
            onSuccess: () => {
                props.setVisible(false)
            }
        })
    }

    const remove = () => {
        deleteClinicalPicture.mutate(form.data.id, {
            onSuccess: () => {
                props.setVisible(false)
            }
        })
    }

    return (
        <Modal selected={selected} title="Krankengeschichte" {...props}>
            <Spacer medium>
                <ClinicalPictureForm form={form}/>
                <ModalFooter>
                    <FormUpdateButton
                        form={form}
                        visible={isUpdate}
                        onClick={update}
                    />
                    <FormDeleteButton
                        form={form}
                        visible={isUpdate}
                        onClick={remove}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}