import Table from "components/table/table";
import CurrencyLabel from 'components/labels/currency-label';
import HourLabel from 'components/labels/hour-label';
import DateLabel from 'components/labels/DateLabel';
import Label from 'components/labels/Label';
import TableCell from "components/table/Cells/TableCell";
import Theme from "Theming/Theme";

const columns = [
    {
        Header: 'Typ',
        accessor: d => d.budget_type_label,
        Cell: ({ value, ...props }) => (
            <TableCell
                {...props}
                renderLabel={() => (
                    <Label>{value}</Label>
                )}
            />
        ),
    },
    {
        Header: 'Aktiv',
        accessor: d => d.budget_amount,
        Cell: ({ value, ...props }) => (
            <TableCell
                {...props}
                renderLabel={() => (
                    <CurrencyLabel value={value}></CurrencyLabel>
                )}
            />
        ),
        Footer: (data) => {
            var sum = 0;
            data.flatRows.forEach(row => {
                sum += parseFloat(row.original.budget_amount);
            });
            return (
                <CurrencyLabel bold value={sum}></CurrencyLabel>
            )
        }
    },
    {
        Header: 'Verbleibend',
        accessor: d => d.remaining_amount,
        Cell: ({ value, ...props }) => (
            <TableCell
                {...props}
                renderLabel={() => (
                    <CurrencyLabel value={value}></CurrencyLabel>
                )}
            />
        ),
        Footer: (data) => {
            var remainingSum = 0;
            data.flatRows.forEach(row => {
                remainingSum += parseFloat(row.original.remaining_amount);
            });
            return (
                <CurrencyLabel bold value={remainingSum}></CurrencyLabel>
            )
        }
    },
    {
        Header: 'Stunden',
        accessor: d => parseFloat(d.remaining_amount),
        Cell: ({ value, ...props }) => (
            <TableCell
                {...props}
                renderLabel={() => (
                    <HourLabel value={value / 35.0}></HourLabel>
                )}
            />
        ),
        Footer: (data) => {
            var hourSum = 0;
            data.flatRows.forEach(row => {
                hourSum += parseFloat(row.original.remaining_amount);
            });
            return (
                <HourLabel bold value={hourSum / 35.0}></HourLabel>
            )
        }
    },
    {
        Header: 'Verfällt am',
        accessor: d => d,
        Cell: ({ value, ...props }) => (
            <TableCell
                {...props}
                renderLabel={() => {
                    var color = Theme.textColor
                    var bold = false

                    if (value.declined) {
                        color = 'red'
                        bold=true
                    }
                    return (
                        <DateLabel color={color} bold={bold} date={value.budget_decline_date} />
                    )
                }}      
            />
        ),
    },
]

export default function BudgetGroupTable({ budgets }) {

    return (
        <Table
            data={budgets}
            columns={columns}
        />
    );
}