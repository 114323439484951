import styled from 'styled-components'

const FlexGrow = styled.div`
    flex-grow: 1;
`

const FlexShrink = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    min-height: 0;
    min-width: 0;
`

export {
    FlexGrow,
    FlexShrink
}