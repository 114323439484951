import MonthSelect from "components/Time/MonthSelect";
import YearSelect from "components/Time/YearSelect";
import { useInput } from "hooks/input";
import moment from "moment";
import Spacer from "components/Spacer/Spacer";
import Row from "components/Row";
import TextInput from '../../../components/input/TextInput';
import Button from "components/Button";
import { useMemo } from 'react';
import useModal from "components/modal/useModal";
import Table from 'components/table/table';
import Tile from "components/Tiles/Tile";
import { useOutletContext } from "react-router-dom";
import EmployeeHeaderBar from "Employees/EmployeeHeaderBar";
import EventModal from "Events/EventModal";
import useEventColumns from "Events/useEventColumns";
import { useEvents } from "Events/EventApi";
import { PersonOutletContextType } from "Persons/PersonOutlet";
import Event from "Events/Event";
import { FaPlus } from "react-icons/fa";

export default function EmployeeOperations() {

    const { person } = useOutletContext<PersonOutletContextType>()
    const { value: year, bind: bindYear } = useInput(moment().year());
    const { value: month, bind: bindMonth } = useInput(moment().month()+1);
    const { value: filter, bind: bindFilter } = useInput("");
    const modal = useModal<Event>()


    const columns = useEventColumns({
        'hidden': ['Mitarbeiter']
    });

    const getPeriod = (year, month) => {
        if (year && month) {
            return ([
                moment().year(year).month(month - 1).startOf('month').startOf('day').format('YYYY-MM-DD HH:mm'),
                moment().year(year).month(month - 1).endOf('month').endOf('day').format('YYYY-MM-DD HH:mm')
            ])
        }
        else if (year) {
            return ([
                moment().year(year).startOf('year').startOf('day').format('YYYY-MM-DD HH:mm'),
                moment().year(year).endOf('year').endOf('day').format('YYYY-MM-DD HH:mm')
            ])
        }
        return [undefined, undefined]
    }

    const params = useMemo(() => {
        const [periodStart, periodEnd] = getPeriod(year, month)

        return {
            period_start: periodStart,
            period_end: periodEnd,
            employee_id: person?.id
        }
    }, [person, year, month])
    const { data, isPending } = useEvents(params)

    return (
        <>
            <EmployeeHeaderBar person={person}/>
            <Spacer medium shrink>
                <Tile
                    title='Einsätze'
                    actions={[
                        {
                            Icon: <FaPlus/>,
                            execute: () => modal.select({
                                employee_id: person?.id,
                                creator_id: person?.id,
                                date: moment().format('yyyy-MM-DD'),
                                title: 'Einsatz',
                                duration: 0,
                                kilometer: 0,
                                kilometer_cost: 0.5,
                                hourly_wage: 35,
                                supplement_id: 1,
                                deselected_budgets: [],
                                confirmed: true
                            })
                        }
                    ]}
                >
                    <Spacer hMedium>
                        <Row vCentered>
                            <TextInput label='Filtern' size={20} {...bindFilter} />
                            <MonthSelect label='Monat' value={month} {...bindMonth} />
                            <YearSelect label='Jahr' value={year} {...bindYear} />
                        </Row>
                    </Spacer>
                    <Table
                        isLoading={isPending}
                        data={data}
                        columns={columns}
                        filter={filter}
                        onClick={modal.select}
                    />
                </Tile>               
            </Spacer>  
            <EventModal {...modal}/>
        </>
    );
}