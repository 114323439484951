import Label from "components/labels/Label";
import styled from 'styled-components'

const Wrapper = styled.div`
    padding-top: 1.3em;
    margin-right: 0.2em;
`

const InputLabel = (props) => {
    return (
        <Wrapper>
            <Label {...props}/>
        </Wrapper>
    )
}

export default InputLabel