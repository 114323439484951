import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import Spacer from "components/Spacer/Spacer";
import SubmitButton from 'Forms/SubmitButton';
import { useMemo } from 'react';
import BudgetCreateForm from './BudgetCreateForm';
import Column from '../components/Column';
import Row from "components/Row";
import Table from "components/table/table";
import useBudgetPreviewColumns from './useBudgetPreviewColumns';
import Label from "components/labels/Label";
import ScrollView from "components/ScrollView";
import ameli from "Api/ameli";
import useBudgetCreateForm from "./useBudgetCreateForm";
import { useQuery } from "@tanstack/react-query";
import { useBudgetCreate } from "./BudgetApi";

interface Props extends IModalProps<any> {}

export default function BudgetCreateModal({
    selected,
    setVisible,
    ...props
}:Props) {

    const {columns} = useBudgetPreviewColumns()
    const form = useBudgetCreateForm(selected)
    const createBudget = useBudgetCreate()

    const params = useMemo(() => {
        return form.data
    }, [form.data])

    const budgetPreview = useQuery({
        queryKey: ['budgets', 'data', params],
        queryFn: () => ameli.get<any>('budgets/data', { params }).then(res => res.data)
    })

    const create = () => {
        createBudget.mutate(budgetPreview.data, {
            onSettled: () => {
                setVisible(false)
            }
        })
    }

    return (
        <Modal
            selected={selected}
            setVisible={setVisible}
            title={"Budget erfassen"}
            {...props}
        >
            <Spacer medium>
                <Row gap='1em'>
                    <Column>
                        <BudgetCreateForm form={form} careLevel={selected?.care_level} />
                    </Column>
                    <Column width={"50em"}>
                        <Label bold>Vorschau</Label>
                        {
                            budgetPreview.data?.length > 0 ?
                                (
                                    <ScrollView height='25em'>
                                        <Table data={budgetPreview.data} columns={columns} />
                                    </ScrollView>
                                )
                                :
                                (
                                    <Spacer vMedium>
                                        <Label subtle>Es werden keine Budgets erfasst</Label>
                                    </Spacer>
                                )
                        }
                    </Column>
                </Row>
                <ModalFooter>
                    <SubmitButton
                        disabled={budgetPreview.data?.length === 0}
                        onClick={create}
                    >
                        Erfassen
                    </SubmitButton>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}