import moment from "moment"
import { maxDate, minDate } from "./timeUtility"

export const isValidPeriod = (start, end) => {
    if (!start || !end) {
        return false
    }
    start = moment(start)
    end = moment(end)

    return start.isSameOrBefore(end)
}

export const periodToString = (period, fallback='Offen') => {

    if(!isValidPeriod(period[0], period[1])) {
        return 'Ungültiger Zeitraum'
    }
    var start = period[0] ? moment(period[0]) : null
    var end = period[1] ? moment(period[1]) : null
    var startString = start ? start.format('DD.MM.YY') : ''
    var endString = end ? end.format('DD.MM.YY') : ''

    if(start && end) {
        if(start.isSame(end, 'day')) {
            return startString
        }
        return `${startString} - ${endString}`
    }
    if(start) {
        return `Ab ${startString}`
    }
    if(end) {
        return `Bis ${endString}`
    }
    return fallback
}

export const periodsOverlap = (startA, endA, startB, endB) => {

    return ((!startA || !endB || moment(startA).isSameOrBefore(moment(endB)))) &&
        (!endA || !startB || (moment(endA).isSameOrAfter(moment(startB))))
}

export const dateInPeriod = (date, periodStart, periodEnd) => {
    return periodsOverlap(moment(date).startOf('day'), date, periodStart, periodEnd)
}

export const getOverlappingDates = (startA, endA, startB, endB) => {
    var start = maxDate(startA, startB)
    var end = minDate(endA, endB)
    if (!start || !end || start.isAfter(end)) {
        return []
    }
    var dates = []
    while (start.diff(end, 'days') <= 0) {
        dates.push(start.clone().format('YYYY-MM-DD'));
        start.add(1, 'days')
    }
    return dates
}