import React, { InputHTMLAttributes, useState } from 'react';
import InputField from './InputField'
import InputWrapper from './InputWrapper'

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    value: any,
    size?: number,
    optional?: boolean,
    label: string,
    grow?: boolean,
    type?:string
}

const TextInput = React.forwardRef(({ value = '', size = 5, grow = true, label = undefined, optional, ...props }: TextInputProps, ref) => {

    const [focused, setFocused] = useState(false)

    return (
        <InputWrapper focused={focused} grow={grow} label={label} optional={optional}>
            <InputField
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                size={size} 
                value={value} 
                ref={ref} 
                type="text" 
                {...props}
            />            
        </InputWrapper>
    )
})

TextInput.displayName='TextInput'

export default TextInput