
import React, { useState, useCallback } from 'react';
import moment from 'moment';
import parseError from './parseError';

const ErrorContext = React.createContext({});


const ErrorProvider = ({...props}) => {
    const [error, setError] = useState(null)
    const [timestamp, setTimestamp] = useState(null)

    const clearError = useCallback(() => {
        setError(null)
        setTimestamp(null)
    }, [setError, setTimestamp])

    const writeError = useCallback((error) => {
        console.log(error)
        if(!error) {
            clearError()
            return
        }
        setError(parseError(error))
        setTimestamp(`${moment().format("dddd DD.MM.YYYY HH:mm")}Uhr`)
    }, [setError, setTimestamp, clearError])

    const sendReport = useCallback(() => {
        clearError()
        return Promise.resolve()
        /*
        return ameli.post('/error_reports', error)
            .then(() => {
                clearError()
            })
            .catch(error => {
                clearError()
                console.log(error)
            })
        */
    }, [clearError])

    return (
        <ErrorContext.Provider
            {...props}
            value={{
                error,
                clearError,
                writeError,
                timestamp,
                sendReport
            }}
        />
    )
}

export  { ErrorProvider, ErrorContext }