import { Outlet } from "react-router-dom";
import Person from "./Person";

export type PersonOutletContextType = {
    person: Person
}

interface Props {
    person:Person
}

export default function PersonOutlet({person}:Props) {
    return (
        <Outlet context={{ person } satisfies PersonOutletContextType} />
    )
}