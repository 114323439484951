import { useMemo } from 'react';
import Button from 'components/Button';
import { useBudgetCreate, useBudgetTypes } from './BudgetApi';
import useGroupedBudgets from './useGroupedBudgets';
import { usePersonClinicalPicture } from 'Persons/PersonApi';


const BudgetCreateButton = ({children, ownerId, typeId, date}) => {

    const { data: types } = useBudgetTypes()

    const type = useMemo(() => {
        return types?.find(t => t.id === typeId)
    }, [types, typeId])

    const clinicalPicture = usePersonClinicalPicture(ownerId)


    const budgetParams = useMemo(() => {
        return {
            owner_id: ownerId,
            date
        }
    }, [ownerId, date])

    const { data:budgets } = useGroupedBudgets(budgetParams)

    const data = useMemo(() => {
        const typeInUse = budgets.some(budget => budget.budget_type_id === typeId)

        if(!ownerId || typeInUse || !type || type.required_care_level > clinicalPicture.data?.care_level) {
            return null
        }

        return {
            owner_id: ownerId,
            budget_type_id: type?.id,
            amount: 0,
            begin_date: null,
            decline_date: null,
        }
        /*
        return {
            owner_id: ownerId,
            budget_type_id: type?.id,
            amount: type?.getAmount(clinicalPicture.data?.care_level),
            begin_date: type?.suggestBeginDate(date),
            decline_date: type?.suggestDeclineDate(date),
        }
            */
    }, [type, ownerId, date, budgets, typeId])

    const createBudget = useBudgetCreate()

    if(!data) {
        return null
    }

    return (
        <Button onClick={() => createBudget.mutate(data)}>
            { children }
        </Button>
    )
}

export default BudgetCreateButton