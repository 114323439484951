import Label from "components/labels/Label";
import TableCell from "components/table/Cells/TableCell";
import { useBudgetTypes } from "./BudgetApi";

const { default: CurrencyLabel } = require("components/labels/currency-label");
const { default: DateLabel } = require("components/labels/DateLabel");
const { useMemo, useCallback } = require("react");


export default function useBudgetPreviewColumns(props) {

    const { data: types } = useBudgetTypes()

    const getTypeLabel = useCallback((id) => {
        var type = types.find(t => t.id === parseInt(id))
        return type ? type.label : "-"
    }, [types])

    const columns = useMemo(() =>
        [
            {
                Header: 'Typ',
                accessor: d => d.budget_type_id,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{getTypeLabel(value)}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Betrag',
                accessor: d => d.amount,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value}></CurrencyLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var sum = 0;
                    data.flatRows.forEach(row => {
                        sum += parseFloat(row.original.amount);
                    });
                    return (
                        <CurrencyLabel bold value={sum}></CurrencyLabel>
                    )
                }
            },
            {
                Header: 'Gültig ab',
                accessor: d => d.begin_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Verfällt am',
                accessor: d => d.decline_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Anmerkung',
                accessor: d => d.note,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
        ],
        [getTypeLabel]
    );

    return { columns };
}