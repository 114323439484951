import moment from "moment";
import "moment/locale/de";

export const toYearString = (dateString) => {
    return new Date(dateString).getFullYear();
}

export const localizeYearString = (yearString) => {
    return moment(yearString).format('DD.MM.YYYY');
}

export const toShortMonthString = (dateString) => {
    return moment.monthsShort()[new Date(dateString).getMonth()];
}

export const toYearMonthString = (dateString) => {
    return `${toShortMonthString(dateString)} ${toYearString(dateString)}`;
}

export const compareStrings = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;

    return 0;
}

export const formatFloat = (value, suffix=null, fallback='0,00') => {
    value = parseFloat(value);
    if (value === null || isNaN(value)) {
        return fallback
    }
    if(suffix) {
        return `${parseFloat(value).toFixed(2).replace('.', ',') } ${suffix}`
    }
    return parseFloat(value).toFixed(2).replace('.', ',');
}


export const formatSeconds = (seconds, format='HH:mm:ss') => {
    return moment().startOf('day').seconds(seconds).format(format)
}
