import { ReactNode } from 'react'
import styled from 'styled-components'
import ConditionalView from './Decorator/ConditionalView'

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const Label = styled.label`
    min-width: ${props => props.width || '11em'};
    font-weight: 400;
    color: #888;
    padding-left: 0;
`

const Content = styled.div`
    flex-grow: 1;
`

interface Props {
    label?:string,
    children?: ReactNode,
    visible?:boolean
}

const TileSection = ({label="", children, visible=true}: Props) => {
    
    return (
        <ConditionalView condition={visible}>
            <Container>
                <Label>{label}</Label>
                <Content>
                    { children }
                </Content>
            </Container>
        </ConditionalView>
    )
}

export default TileSection