import { useEvents } from "Events/EventApi";
import EventListItem from "Events/EventListItem";
import ListView from "components/ListView/ListView";
import ScrollView from "components/ScrollView";
import moment from "moment";
import { useMemo } from "react";

const EmployeeManagementView = () => {

    const params = useMemo(() => ({
        period_start: moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm'),
        period_end: moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm')
    }), [])

    const events = useEvents(params)


    const substitutes = useMemo(() => {
        if(events.isPending) {
            return []
        }
        return events.data.filter(event => event.employee_id && event.creator_id != event.employee_id)
    }, [events.data])
    
    return (
        <ScrollView>
            <ListView
                data={substitutes}
                listItem={(event) => {
                    return <EventListItem event={event}/>
                }}
            />
        </ScrollView>
    )
}

export default EmployeeManagementView