import React, { useMemo } from "react";
import Dropdown from "../dropdown/dropdown";

export default function MonthSelect(props) {

    const options = useMemo(() =>
        [
            { value: null, label: "Alle"},
            { value: 1, label: "Januar" },
            { value: 2, label: "Februar" },
            { value: 3, label: "März" },
            { value: 4, label: "April" },
            { value: 5, label: "Mai" },
            { value: 6, label: "Juni" },
            { value: 7, label: "Juli" },
            { value: 8, label: "August" },
            { value: 9, label: "September" },
            { value: 10, label: "Oktober" },
            { value: 11, label: "November" },
            { value: 12, label: "Dezember" },
        ]
    , []);

    return (
        <Dropdown 
            options={options} 
            {...props}>           
        </Dropdown>
    )
}