
import React, { useState } from 'react';
import { useContext } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components'
import IconWrapper from './Icons/IconWrapper';
import ClickableOpacity from './Decorator/ClickableOpacity';

const CollapseContext = React.createContext({})

const CollapseProvider = ({ startCollapsed = true, children }) => {

    const [collapsed, setCollapsed] = useState(startCollapsed)

    return (
        <CollapseContext.Provider
            value={{
                collapsed,
                setCollapsed
            }}
        >
            { children }
        </CollapseContext.Provider>
    )
}


const Collapsable = ({ header, content, startCollapsed=true, ...props }) => {

    return (
        <div {...props}>
            <CollapseProvider startCollapsed={startCollapsed}>
                <Header>
                    { header }
                </Header>
                <CollapsableContent>
                    { content }
                </CollapsableContent>
            </CollapseProvider>
        </div>
    )
}

const Header = ({children}) => {
    const { collapsed, setCollapsed } = useContext(CollapseContext)

    return (
        <ClickableOpacity onClick={() => setCollapsed(!collapsed)}>
            { children }
        </ClickableOpacity>
    )
}

const Content = styled.div`
    display: ${props => props.collapsed ? 'none' : 'initial'};
`

const CollapsableContent = ({ children }) => {
    const { collapsed } = useContext(CollapseContext)

    return (
        <Content collapsed={collapsed}>
            { children }
        </Content>
    )
}

const Chevron = styled(FaChevronRight)`

    transform: rotate(${props => props.collapsed ? '0' : '90deg'});
    transition: 0.2s;
`

const CollapseChevron = ({ visible=true }) => {

    const { collapsed, setCollapsed } = useContext(CollapseContext)

    return (
        <IconWrapper color={visible ? undefined : "transparent"} onClick={() => setCollapsed(!collapsed)}>
            <Chevron collapsed={collapsed ? 1 : 0}/>
        </IconWrapper>
    )
}

export { Collapsable, CollapsableContent, CollapseChevron, CollapseContext }