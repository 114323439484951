import React, { useCallback, useContext, useMemo } from "react";
import Dropdown from "components/dropdown/dropdown";
import { HealthInsuranceContext } from "./HealthInsuranceContext";
import HealthInsuranceModal from "./HealthInsuranceModal";
import useModal from "components/modal/useModal";
import { useHealthInsuranceActions } from 'HealthInsurances/HealthInsuranceContext';

export default function HealthInsuranceSelect({onChange, name,  ...props}) {

    const { data:insurances } = useContext(HealthInsuranceContext)
    const healthInsuranceActions = useHealthInsuranceActions()
    const modal = useModal()

    const dispatchWrapper = useCallback((actionKey, data) => {
        if(actionKey === 'create') {
            return healthInsuranceActions.dispatch(actionKey, data).then(({data}) => onChange({
                name,
                value: data.id
            }))
        }
        return healthInsuranceActions.dispatch(actionKey, data)
    }, [onChange, healthInsuranceActions, name])

    const preOptions = useMemo(() => 
        [
            { label: "-", value: null },
            { label: "Neue Krankenkasse", value: -1 }
        ]
    , []);

    const handleChange = useCallback((v) => {
        if(v.value === -1) {
            modal.select({})
        }
        else {
            onChange(v)
        }
    }, [onChange, modal])

    return (
        <>
            <HealthInsuranceModal {...modal} dispatchAction={dispatchWrapper}/>
            <Dropdown 
                options={preOptions}
                data={insurances}
                optionFactory={(insurance) => {
                    return { value: insurance.id, label: insurance.name };
                }}
                name={name}
                {...props}
                onChange={handleChange}
                >
            </Dropdown>
        </>
    );
}