import useLookup from '../Collections/useLookup';
import useApiData from '../Api/useApiData';

const channel = ['Absences']

const useAbsences = (params) => {
    const state = useApiData('absences', params, channel)
    const employeeIdLookup = useLookup('employee_id', state.data)

    return {
        ...state,
        employeeIdLookup
    }
}

export default useAbsences

