import { useMemo } from "react"
import NumberUtility from "utils/NumberUtlity"
import { generateBooleanField } from 'Forms/formFieldFactory';


const useAbsenceFields = (absence) => {

    const fields = useMemo(() => {

        return {
            id: {
                initialValue: absence?.id
            },
            employee_id: {
                initialValue: absence?.employee_id,
                validate: NumberUtility.isPositiveInteger
            },
            begin_date: {
                initialValue: absence?.begin_date,
                validate: (date) => date
            },
            end_date: {
                initialValue: absence?.end_date,
                validate: (date) => date
            },
            type_id: {
                initialValue: absence?.type_id,
                validate: NumberUtility.isPositiveInteger
            },
            request_date: {
                initialValue: absence?.request_date,
                validate: (date) => date !== null
            },
            details: {
                initialValue: absence?.details,
            },
            paid: generateBooleanField(absence?.paid, false)
        }
    }, [absence])

    return fields
}

export default useAbsenceFields