import { FaTimes } from 'react-icons/fa'
import PersonSelect from "Persons/PersonSelect";
import Spacer from "components/Spacer/Spacer";
import Card from "components/Card";
import Row from "components/Row";
import IconWrapper from "components/Icons/IconWrapper";
import styled from 'styled-components';
import FlexFill from 'components/FlexFill';
import { usePersons } from 'Persons/PersonApi';
import { useMemo } from 'react';
import { DropdownProps } from 'components/dropdown/dropdown';
import Person from 'Persons/Person';
import Label from 'components/labels/Label';

const StyledCard = styled(Card)`
    borderRadius: 4px;
    margin-right: 0.2em;
    margin-bottom: 0.2em;
`

interface Props extends DropdownProps {
    involvedPersons: Person[]
}

export default function InvolvedPersonsEdit({involvedPersons, ...props}:Props) {

    const personParams = useMemo(() => {
        return {

        }
    }, [])

    const persons = usePersons(personParams)

    function simulateChangeEvent(newValue) {    
        if(typeof(props.onChange) === 'function') {
            props.onChange({
                currentTarget : {
                    name: props.name,
                    value : newValue
                }
            })
        }
    }

    function onSelectedPersonChange(event) {
        addPerson(event.target.value);
    }

    function addPerson(id) {
        if (!involvedPersons.find(p => p.id === id)) {
            var person = persons.data?.find(p => p.id === id)
            if(person) {
                simulateChangeEvent([...involvedPersons, person])
            }
        }
    }

    function removePerson(person) {
        if(person) {
            var newValue = involvedPersons.filter((p) => p.id !== person.id);
            simulateChangeEvent(newValue);
        }
    }


    return (
        <div>
            <PersonSelect 
                persons={persons.data}
                grow={props.grow} 
                label={props.label} 
                placeholder='Person auswählen' 
                onChange={onSelectedPersonChange}
            />
            <Spacer vTiny/>
            <div>
                {involvedPersons.map(person => {
                    return ( 
                        <StyledCard key={person.id}>
                            <Spacer small>
                                <Row vCentered>
                                    <Label>
                                        {`${person?.first_name} ${person?.last_name}`}
                                    </Label> 
                                    <FlexFill/>
                                    <IconWrapper onClick={() => removePerson(person)}>
                                        <FaTimes/>
                                    </IconWrapper>
                                </Row>
                            </Spacer>
                        </StyledCard>
                    )
                })}
            </div>
        </div>
    )
}