import ameli from "Api/ameli";
import Dropdown from "components/dropdown/dropdown";
import { useState, useEffect } from "react";

export default function AbsenceTypeSelector(props) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        ameli.get("/absences/types")
            .then(response => {
                const newOptions = response.data.map(absenceType => {
                    return { value: absenceType.id, label: absenceType.label }
                })
                setOptions(newOptions)
            })
            .catch(error => setOptions([]))
    }, []);

    return (
        <Dropdown 
            options={options}
            {...props}>
        </Dropdown>
    );
}