import DateLabel from "components/labels/DateLabel";
import HourLabel from "components/labels/hour-label";
import KilometerLabel from "components/labels/kilometer-label";
import TimeLabel from "components/labels/time-label";
import TableCellInput from "components/table/Cells/TableCellInput";
import TableCell from "components/table/Cells/TableCell";
import { useMemo } from "react";
import useActionColumn from "components/table/Columns/useActionColumn";

export const useOperationReportColumns = (actions=[]) => {

    const actionColumn = useActionColumn(actions)

    const columns = useMemo(() => {
            let columnList = [
                {
                    Header: 'Datum',
                    accessor: d => d,
                    Cell: ({ value, ...props }) => (
                        <TableCell
                            {...props}
                            renderLabel={() => (
                                <DateLabel date={value.date} />
                            )}
                            renderInput={(onChange, setEditing) => (
                                <TableCellInput
                                    type='date'
                                    value={value.date}
                                    onChange={(newValue) => {
                                        value.setDate(newValue)
                                        onChange(value)
                                    }}
                                    onBlur={() => setEditing(false)}
                                />
                            )}
                        />
                    ),
                },
                {
                    Header: 'Beginn',
                    accessor: d => d,
                    Cell: ({ value, ...props }) => (
                        <TableCell
                            {...props}
                            renderLabel={() => (
                                <TimeLabel startTime={value.startTime}></TimeLabel>
                            )}
                            renderInput={(onChange, setEditing) => (
                                <TableCellInput
                                    type='time'
                                    value={value.startTime}
                                    onChange={(newValue) => {
                                        value.setStartTime(newValue)
                                        onChange(value)
                                    }}
                                    onBlur={() => setEditing(false)}
                                />
                            )}
                        />
                    ),
                },
                {
                    Header: 'Ende',
                    accessor: d => d,
                    Cell: ({value,...props}) => {
                        return (
                            <TableCell
                                {...props}
                                renderLabel={() => (
                                    <TimeLabel
                                        startTime={value.endTime}
                                    />
                                )}
                                renderInput={(onChange, setEditing) => (
                                    <TableCellInput
                                        type='time'
                                        value={value.endTime}
                                        onChange={(newValue) => {
                                            value.setEndTime(newValue)
                                            onChange(value)
                                        }}
                                        onBlur={() => setEditing(false)}
                                    />
                                )}
                            />
                        )
                    }
                },
                {
                    Header: 'Kilometer',
                    accessor: d => d,
                    Cell: ({value, ...props}) => {
                        return (
                            <TableCell 
                                {...props}
                                renderLabel={() => (
                                    <KilometerLabel value={value.kilometer}/>
                                )}
                                renderInput={(onChange, setEditing) => (
                                    <TableCellInput
                                        value={value.kilometer}
                                        onChange={(newValue) => {
                                            value.setKilometer(newValue)
                                            onChange(value)
                                        }}
                                        onBlur={() => setEditing(false)}
                                    />
                                )}
                            >
                            </TableCell>
                        )
                    },
                    Footer: (data) => {
                        var kilometerSum = data.flatRows.reduce((sum, row) => sum + parseFloat(row.original.kilometer), 0.0);
                        return (
                            <KilometerLabel bold value={kilometerSum}></KilometerLabel>
                        )
                    }
                },
                {
                    Header: 'Stunden',
                    accessor: d => d,
                    Cell: ({ value, ...props }) => (
                        <TableCell
                            {...props}
                            renderLabel={() => (
                                <HourLabel value={value.hours}></HourLabel>
                            )}
                            renderInput={(onChange, setEditing) => (
                                <TableCellInput
                                    value={value.hours}
                                    onChange={(newValue) => {
                                        value.setHours(newValue)
                                        onChange(value)
                                    }}
                                    onBlur={() => setEditing(false)}
                                />
                            )}
                        />
                    ),
                    Footer: (data) => {
                        var hourSum = data.flatRows.reduce((sum, row) => sum + parseFloat(row.original.hours), 0.0);
                        return (
                            <HourLabel bold value={hourSum}></HourLabel>
                        )
                    }
                },
                actionColumn
            ];

            return columnList;
        }, 
        [actionColumn]
    )

    return columns;
}