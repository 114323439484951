import { useContext, useMemo } from "react";
import Spacer from "components/Spacer/Spacer";
import Label from "components/labels/Label";
import Screen from "app/Views/Screen";
import SideBar, { SideBarSection } from "Sidebar/SideBar";
import ScreenLayout from "../ScreenLayout";
import ScrollView from "components/ScrollView";
import { Outlet } from "react-router-dom";
import Page from './../../../components/Page';
import { ApiDataContext } from "Api/ApiDataContext";
import SidebarNavigation from "Sidebar/SidebarNavigation";


export default function EmployeeManagementScreen() {

    const { vacationRequests } = useContext(ApiDataContext)

    const routes = useMemo(() => ([
        {
            label: "Übersicht",
            to:''
        },
        {
            label: "Übersicht+",
            to: 'Übersicht'
        },
        {
            label: "Abwesenheiten",
            to: 'Abwesenheiten'
        },
        {
            label: "Urlaubsanträge",
            to: "Urlaubsanträge",
            badge: vacationRequests.data.filter(r => !r.rejected).length
        }
    ]), [vacationRequests.data])

    return (
        <Screen>
            <ScreenLayout.SideBar>
                <SideBar>
                    <SideBarSection>
                        <Spacer medium>
                            <Label large bold>Personalplanung</Label>
                        </Spacer>
                    </SideBarSection>
                    <SideBarSection>
                        <ScrollView>
                            <SidebarNavigation routes={routes}/>
                        </ScrollView>
                    </SideBarSection>
                </SideBar> 
                <Page>
                    <Outlet/>
                </Page>     
            </ScreenLayout.SideBar>
        </Screen>
    )
}