import Dropdown from "components/dropdown/dropdown";
import { useContext, useMemo } from "react";
import { CustomerContext } from "./CustomerContext";

export default function CustomerSelect({placeholder='-', ...props}) {

    const { active } = useContext(CustomerContext)

    const options = useMemo(() => {
        var result = active
            .map((customer) => {
                var label = `${customer.last_name}, ${customer.first_name}`
                
                if(customer?.state === null) {
                    label = `${label} (Interessent)`
                }

                if(customer?.state === 0) {
                    label = `${label} (Ehemalig)`
                }

                return {
                    value: customer.id,
                    label
                }
            })
            .sort((a,b) => a.label.localeCompare(b.label))
        return [
            {
                value: undefined,
                label: placeholder
            },
            ...result
        ] 
    }, [active, placeholder])

    return (
        <Dropdown
            placeholder={placeholder}
            options={options}
            {...props}>
        </Dropdown>
    )
}