
import Column from 'components/Column';
import ConditionalView from 'components/Decorator/ConditionalView';
import Label from 'components/labels/Label';
import Person from 'Persons/Person';

interface Props {
    person:Person
}

const PersonShortInfo = ({ person }:Props) => {

    if(!person) {
        return <Label subtle>Person nicht gefunden</Label>
    }

    return (
        <Column gap='0.5em'>
            <Label bold>{`${person.first_name} ${person.last_name}`}</Label>
            <ConditionalView condition={Boolean(person.address)}>
                <div>
                    <Label>{person.address.street}</Label>
                    <Label>{person.address.postcode}</Label>
                    <Label>{person.address.city}</Label>
                </div>
            </ConditionalView>
        </Column>
    )
}

export default PersonShortInfo