import Spacer from 'components/Spacer/Spacer'
import styled from 'styled-components'

const TFoot = styled.tfoot`
`

const TD = styled.td`
    z-index: 1;
    position: sticky;
    bottom: 0px;
    background-color: white;
    
    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: -2px;
        border-top: 2px solid #b3b3b3;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%; 
    }
`

const TableFooter = ({footerGroups}) => {
    return (
        <TFoot>
            {footerGroups.map((footerGroup, i) => (
                <tr key={i} {...footerGroup.getFooterGroupProps()}>
                    {footerGroup.headers.map(column => {
                        
                        return column.filteredRows.length > 0 ? (
                            <TD {...column.getFooterProps()}>
                                <Spacer hMedium vLarge>
                                    {column.render('Footer')}
                                </Spacer>
                            </TD>
                        ) : null
                    })}
                </tr>
            ))}
        </TFoot>
    )
}

export default TableFooter