import CancelationTypeSelect from "CancelationTypes/CancelationTypeSelect"
import DenominationSelect from "Denominations/DenominationSelect"
import FamilyStatusSelect from "FamilyStatus/FamilyStatusSelect"
import IForm from "Forms/IForm"
import HealthInsuranceSelect from "HealthInsurances/HealthInsuranceSelect"
import NationalitySelect from "Nationalities/NationalitySelect"
import TitleSelect from "Titles/TitleSelect"
import Column from "components/Column"
import Row from "components/Row"
import { FormGroup } from "components/form/FormComponents"
import DatePicker from "components/input/DatePicker"
import TextArea from "components/input/TextArea"
import TextInput from "components/input/TextInput"

interface Props {
    form: IForm
}

const PersonForm = ({form}: Props) => {

    return (
        <Column>
            <FormGroup>
                <DatePicker
                    label='Automatische Löschung am'
                    value={form.getFieldValue('deleteAt')}
                    onChange={(value) => form.setFieldValue('deleteAt', value)}
                />
            </FormGroup>
            <FormGroup>
                <TitleSelect
                    label='Anrede'
                    value={form.getFieldValue('title_id')}
                    onChange={({value}) => form.setFieldValue('title_id', value)}
                />
                <Row>
                    <TextInput 
                        value={form.getFieldValue('first_name')} 
                        onChange={(event) => form.setFieldValue('first_name', event.target.value)} 
                        label='Vorname' 
                    />
                    <TextInput
                        value={form.getFieldValue('last_name')}
                        onChange={(event) => form.setFieldValue('last_name', event.target.value)}
                        label='Nachname'
                    />
                </Row>
                <Row>
                    <DatePicker
                        optional
                        label='Geburtstag'
                        value={form.getFieldValue('birthday')}
                        onChange={(value) => form.setFieldValue('birthday', value)}
                    />
                    <TextInput
                        optional
                        value={form.getFieldValue('birthplace')}
                        onChange={(event) => form.setFieldValue('birthplace', event.target.value)}
                        label='Geburtsort'
                    />
                </Row>
                <NationalitySelect
                    optional
                    label='Nationalität'
                    value={form.getFieldValue('nationality_id')}
                    onChange={({ value }) => form.setFieldValue('nationality_id', value)}
                    allowNull
                />
                <FamilyStatusSelect
                    optional
                    label='Familienstatus'
                    value={form.getFieldValue('family_status_id')}
                    onChange={({ value }) => form.setFieldValue('family_status_id', value)}
                    allowNull
                />
                <DenominationSelect
                    optional
                    label='Konfession'
                    value={form.getFieldValue('denomination_id')}
                    onChange={({ value }) => form.setFieldValue('denomination_id', value)}
                    allowNull
                />
                <HealthInsuranceSelect
                    optional
                    label='Krankenkasse'
                    value={form.getFieldValue('health_insurance_id')}
                    onChange={({ value }) => form.setFieldValue('health_insurance_id', value)}
                    name='health_insurance_id'
                />
                <TextInput
                    optional
                    label='Versicherungsnummer'
                    value={form.getFieldValue('health_insurance_number')}
                    onChange={(event) => form.setFieldValue('health_insurance_number', event.target.value)}
                />
                <Row>
                    <DatePicker
                        optional
                        label='Dienstbeginn'
                        value={form.getFieldValue('entry')}
                        onChange={(value) => form.setFieldValue('entry', value)}
                    />
                    <DatePicker
                        optional
                        label='Dienstende'
                        value={form.getFieldValue('exit_date')}
                        onChange={(value) => form.setFieldValue('exit_date', value)}
                    />
                </Row>
                {
                    form.data.exit_date && (
                        <>
                            <CancelationTypeSelect
                                label='Beendigungsgrund'
                                value={form.getFieldValue('cancelation_type_id')}
                                onChange={({ value }) => form.setFieldValue('cancelation_type_id', value)}
                            />
                            <TextArea
                                optional={true}
                                minRows={3}
                                label='Beendigungsdetails'
                                name='cancelation_details'
                                value={form.getFieldValue('cancelation_details')}
                                onChange={(event) => form.setFieldValue('cancelation_details', event.target.value)}
                            />
                        </>
                    )
                }
            
            </FormGroup>
        </Column>
    )
}

export default PersonForm