import Label from "components/labels/Label"
import LabelRow from "components/labels/LabelRow";
import moment from "moment";
import { useMemo } from 'react';
import { localizeDateString } from '../utils/timeStringUtility';

const BirthdayLabel = ({ date }) => {

    const ageString = useMemo(() => {
        if(!date) {
            return ""
        }
        return `(${moment().diff(moment(date), 'year')} Jahre)`
    }, [date])

    const birthDateString = useMemo(() => {
        if(date) {
            return localizeDateString(date)
        }
        return "-"
    }, [date])

    return (
        <LabelRow>
            <Label>{birthDateString}</Label>
            <Label subtle>{ageString}</Label>
        </LabelRow>
    )
}

export default BirthdayLabel