import Label from "components/labels/Label"
import ListItem from "components/ListView/ListItem";
import ListView from "components/ListView/ListView"
import Row from "components/Row";
import Spacer from "components/Spacer/Spacer";
import moment from "moment";
import styled from 'styled-components'
import Person from "./Person";
import usePersonUtility from "./usePersonUtility";

const DateLabel = styled(Label)`
    width: 3em;
`

interface Props {
    persons: Person[],
    isPending: boolean
}

const BirthdayList = ({ persons, isPending}: Props) => {

    const { getAge } = usePersonUtility()

    const listItem = (person:Person) => {
        const today = moment().format('DD.MM') === moment(person.birthday).format('DD.MM') 
        const dateString = today ? "Heute" : moment(person.birthday).format('DD.MM');
        const age = getAge(person)
        const label = today ? ` ist ${age} geworden` : ` wird ${age + 1}`
        return (
            <ListItem>
                <Spacer medium>
                    <Row>
                        <DateLabel bold={today}>{dateString}</DateLabel>
                        <Label>{`${person.first_name} ${person.last_name} ${label}`}</Label>
                    </Row>
                </Spacer>
            </ListItem>
        )
    }

    return (
        <ListView 
            isLoading={isPending} 
            data={persons} 
            listItem={listItem}
        />
    )
}

export default BirthdayList