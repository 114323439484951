import TextInput from './input/TextInput';

const SearchField = (props) => {

    return (
        <TextInput
            label={null}
            grow 
            type="text"
            placeholder='Suchen...'
            {...props}
        />
    )
}

export default SearchField;