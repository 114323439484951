import Column from "components/Column";
import Badge from "components/Decorator/Badge";
import ConditionalView from "components/Decorator/ConditionalView";
import ListView from "components/ListView/ListView";
import Row from "components/Row";
import Spacer from "components/Spacer/Spacer";
import Label from "components/labels/Label";
import SidebarNavLink from "./SidebarNavLink";


export default function SidebarNavigation({ routes }) {
    return (
        <ListView
            data={routes}
            listItem={(route) => (
                <SidebarNavLink end to={route.to}>
                    <Row>
                        <Column grow>
                            <Spacer medium>
                                <Label>{route.label}</Label>
                            </Spacer>
                        </Column>
                        <ConditionalView condition={route.badge && route.badge > 0}>
                            <Spacer vMedium hSmall>
                                <Badge>{route.badge}</Badge>
                            </Spacer>
                        </ConditionalView>
                    </Row>
                </SidebarNavLink>
            )} 
        />
    )
}