import React, { useCallback } from "react"
import useApiData from '../hooks/useApiData';
import useSignalingActions from "Signals/useSignalingActions";
import ameli from "Api/ameli";

const channel = ['HealthInsurances']

const actions = {
    'create': data => ameli.post('health_insurances', data),
    'delete': data => ameli.delete(`health_insurances/${data}`),
    'update': data => ameli.put(`health_insurances`, data)
}

const useHealthInsuranceActions = () => {
    return useSignalingActions(actions, channel)
}

const HealthInsuranceContext = React.createContext([])

const defaultParams = {}

const HealthInsuranceProvider = ({ params = defaultParams, ...props}) => {

    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get('health_insurances', { params: params })
            .then(response => {
                resolve(response.data.map((insurance) => {
                    return {
                        ...insurance,
                    }
                }))
            })
    }), [params])


    const state = useApiData(loadHandler, channel)

    return (
        <HealthInsuranceContext.Provider
            {...props}
            value={state}
        />
    )
}

export { HealthInsuranceContext, HealthInsuranceProvider, useHealthInsuranceActions }
