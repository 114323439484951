import Column from 'components/Column'
import ConditionalView from 'components/Decorator/ConditionalView'
import Row from 'components/Row'
import styled from 'styled-components'

const Wrapper = styled(Row)`

    ${props => props.onClick ?
        `
            &:hover {    
                cursor: pointer;
                background-color: #f7f7f7;
            }
        `
        :
        ''
    }
`


const Line = styled.div`
    margin-top: 4px;
    margin-bottom: 4px;
    align-self: stretch;
    width: 3px;
    background-color: #cdcdcd;
    margin-right: 12px;
    border-radius: 20px;
`

const ListItem = ({
    menuData=[], 
    children, 
    displayLine=false,
    ...props
}) => {
    return (
        <Wrapper {...props}>
            <ConditionalView condition={displayLine}>
                <Line/>
            </ConditionalView>
            <Column grow>
                {children}
            </Column>
        </Wrapper>
    )
}

export default ListItem