import CurrencyLabel from "components/labels/currency-label";
import DateLabel from "components/labels/DateLabel";
import HourLabel from "components/labels/hour-label";
import KilometerLabel from "components/labels/kilometer-label";
import YearMonthLabel from "components/labels/year-month-label";
import TableCell from "components/table/Cells/TableCell";
import React, { useMemo } from "react";

export const useCustomerBillColumns = () => {
    const columns = useMemo(() =>
        [
            {
                Header: 'Monat',
                accessor: 'bill_date',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <YearMonthLabel value={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Stunden',
                accessor: d => d.hours,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <HourLabel value={value}></HourLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var sum = 0;
                    data.flatRows.forEach(row => {
                        sum += parseFloat(row.values.Stunden);
                    });
                    return (
                        <HourLabel bold value={sum}></HourLabel>
                    )
                }
            },
            {
                Header: 'Kilometer',
                accessor: d => d.kilometer,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <KilometerLabel value={value}></KilometerLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var sum = 0;
                    data.flatRows.forEach(row => {
                        sum += parseFloat(row.values.Kilometer);
                    });
                    return (
                        <KilometerLabel bold value={sum}></KilometerLabel>
                    )
                }
            },
            {
                Header: 'Summe',
                accessor: d => d.amount,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value}></CurrencyLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var sum = 0;
                    data.flatRows.forEach(row => {
                        sum += parseFloat(row.values.Summe);
                    });
                    return (
                        <CurrencyLabel bold value={sum}></CurrencyLabel>
                    )
                }
            },
            {
                Header: 'Gefordert am',
                accessor: 'bill_request_date',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Forderung',
                accessor: data => parseFloat(data.request_amount),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value} />
                        )}
                    />
                ),
                Footer: (data) => {
                    var requestSum = 0;
                    data.flatRows.forEach(row => {
                        requestSum += parseFloat(row.values.Forderung);
                    });
                    return (
                        <CurrencyLabel bold value={requestSum} />
                    )
                }
            },
            {
                Header: 'Geldeingang am',
                accessor: 'bill_collection_date',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Gezahlt',
                accessor: 'bill_collection_amount',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value}></CurrencyLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var collectionSum = 0;
                    data.flatRows.forEach(row => {
                        collectionSum += parseFloat(row.values.bill_collection_amount);
                    });
                    return (
                        <CurrencyLabel bold value={collectionSum}></CurrencyLabel>
                    )
                }
            },
            {
                Header: 'Differenz',
                accessor: data => parseFloat(data.bill_collection_amount) - (parseFloat(data.request_amount)),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel colored={true} value={value} />
                        )}
                    />
                ),
                Footer: (data) => {
                    var differenceSum = 0;
                    data.flatRows.forEach(row => {
                        differenceSum += parseFloat(row.values.Differenz);
                    });
                    return (
                        <CurrencyLabel bold colored={true} value={differenceSum} />
                    )
                }
            },
        ],
        []
    );

    return columns
}