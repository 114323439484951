import React from "react";
import Label from 'components/labels/Label';

export default function TimeLabel(props) {

    return (
        <Label {...props}>{formatTime(props.startTime, props.endTime)}</Label>
    );

    function formatTime(startTime, endTime) {
        if(startTime) {
            startTime = startTime.length > 5 ? startTime.slice(0, -3) : startTime;
            if(endTime) {
                endTime = endTime.length > 5 ? endTime.slice(0, -3) : endTime;
                return `${startTime}-${endTime}`
            }
            return `${startTime}`;
        }
        return '';
    }
}