import styled from 'styled-components';
import Label from "components/labels/Label";
import ClickableOpacity from 'components/Decorator/ClickableOpacity';
import ListView from 'components/ListView/ListView';
import Column from 'components/Column';
import Button from 'components/Button';
import Row from 'components/Row';
import { ModalContext } from 'components/modal/ModalContext';
import { useContext } from 'react';
import usePhoneNumbers from './usePhoneNumbers';

const NumberContainer = styled(ClickableOpacity)`
    display: flex;
    flex-direction: row;
`

const TypeLabel = styled(Label)`
    width: 4.5em;
`

const NumberLabel = styled(Label)`
    padding: 0 0 4px 0;
`

export default function PhoneListControl({ contactDetailsId }) {

    const { phoneModal } = useContext(ModalContext)
    const { data: phoneNumbers, stuff } = usePhoneNumbers(contactDetailsId)

    if(!phoneNumbers) {
        return null
    }

    return (
        <Column gap='0.5em'>
            <ListView
                data={phoneNumbers}
                Separator={() => null}
                listItem={(phoneNumber) => (
                    <NumberContainer onClick={() => phoneModal.select(phoneNumber)}>
                        <NumberLabel>{`${phoneNumber.number} (${phoneNumber.type_label})`}</NumberLabel>
                    </NumberContainer>
                )}
            />
            <Row>
                <Button 
                    small
                    onClick={() => phoneModal.select({
                        contact_details_id: contactDetailsId
                    })}
                >+ Telefonnummer</Button>
            </Row>
        </Column>
    )
}