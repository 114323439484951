import { useForm } from "components/form/form";
import SubmitButton from "Forms/SubmitButton";
import Modal, { ModalFooter } from "components/modal/Modal";
import HealthInsuranceForm from "./HealthInsuranceForm";
import Spacer from "components/Spacer/Spacer";

export default function HealthInsuranceModal({ selected, visible, setVisible, dispatchAction}) {

    const {
        formData,
        handleFormChange,
        formDataChanged,
        formDataValid
    } = useForm(
        selected,
        {
            'validate': (data) => (data && data.name && data.name !== '')
        }
    );

    const isUpdate = formData && 'id' in formData;

    const submitCallback = () => {
        setVisible(false)
    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            title={"Krankenkasse"}
            minWidth="500px">
            <Spacer medium>
                <HealthInsuranceForm
                    data={formData}
                    handleChange={handleFormChange} 
                />
                <ModalFooter>
                    <SubmitButton
                        visible={isUpdate}
                        disabled={!formDataChanged || !formDataValid}
                        onClick={() => dispatchAction('update', formData).then(submitCallback)}>
                        Übernehmen
                    </SubmitButton>
                    <SubmitButton
                        visible={!isUpdate}
                        disabled={!formDataValid}
                        onClick={() => dispatchAction('create', formData).then(submitCallback)}>
                        Erfassen
                    </SubmitButton>
                    <SubmitButton
                        visible={isUpdate}
                        onClick={() => dispatchAction('delete', formData.id).then(submitCallback)}>
                        Löschen
                    </SubmitButton>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}