import { useCallback } from "react"
import useApiData from '../hooks/useApiData';
import ameli from "Api/ameli";

const channel = ['OperationTaskSubtitles']

const useOperationTaskSubtitles = (params) => {
    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get('operation_tasks/subtitles', { params })
            .then(response => {
                resolve(response.data.map((operation) => {
                    return {
                        ...operation,
                    }
                }))
            })
    }), [params])

    const state = useApiData(loadHandler, channel)

    return state
}

export default useOperationTaskSubtitles