import { useEffect } from "react";

export default function useKeyListener(keys, callback) {
    useEffect(() => {
        const listener = event => {
            if (keys === null || keys.includes(event.code)) {
                event.preventDefault();
                callback(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [keys, callback]);
}