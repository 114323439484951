import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Person from "./Person"
import { ClinicalPicture } from "ClinicalPictures/ClinicalPicture"
import moment from "moment"

export interface PersonGetParams {
    is_customer?:boolean,
    is_employee?:boolean,
    start_date?: string,
    end_date?: string,
    with_upcoming_birthday?: boolean,
    person_id?: number,
    limit?: number,
    page?: number
}

const parsePerson = (person: Person): Person => {
    return {
        ...person,
        deleteAt: person.deleteAt ? moment(person.deleteAt) : null
    }
}

const parsePersons = (persons:Person[]) : Person[] => {
    return persons.map(parsePerson)
}

export function usePersons(params: PersonGetParams= {}) {

    return useQuery({
        queryKey: ['persons', params],
        queryFn: () => ameli.get<Person[]>('/persons', { params }).then(res => parsePersons(res.data))
    })
}

export function usePerson(personId:number) {

    return useQuery({
        queryKey: ['persons', personId],
        queryFn: () => ameli.get<Person>(`/persons/${personId}`).then(res => parsePerson(res.data)),
        enabled: Boolean(personId)
    })
}

export function usePersonClinicalPicture(personId:number) {
    return useQuery({
        queryKey: ['persons', personId, 'clinical_pictures'],
        queryFn: () => ameli.get<ClinicalPicture>(`/persons/${personId}/clinicalPictures`).then(res => res.data),
        enabled: Boolean(personId)
    })
}

export function usePersonMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
        }
    })
}

export const usePersonCreate = () => {
    return usePersonMutation<Person>(
        (data: Person) => ameli.post('persons', data)
    )
}


export const usePersonUpdate = () => {
    return usePersonMutation<Person>(
        (data:Person) => ameli.put('persons', data)
    )
}

export const usePersonDelete = () => {
    return usePersonMutation<number>(
        (personId: number) => ameli.delete(`persons/${personId}`)
    )
}

export const useCreatePersonAddress = (personId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) => ameli.post(`persons/${personId}/addresses`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
            callback()
        }
    })
}

export const useUpdatePersonAddress = (personId, addressId, callback=()=>{}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) => ameli.put(`persons/${personId}/addresses/${addressId}`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
            callback()
        }
    })
}

export const useDeletePersonAddress = (personId, addressId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => ameli.delete(`persons/${personId}/addresses/${addressId}`),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
            callback()
        }
    })
}


export const useCreatePersonContactDetails = (personId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation<any, Error, any>({
        mutationFn: (body) => ameli.post(`persons/${personId}/contactDetails`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
            callback()
        }
    })
}

export const useUpdatePersonContactDetails = (personId, contactDetailsId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) => ameli.put(`persons/${personId}/contactDetails/${contactDetailsId}`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
            callback()
        }
    })
}

export const useDeletePersonContactDetails = (personId, contactDetailsId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => ameli.delete(`persons/${personId}/contactDetails/${contactDetailsId}`),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
            callback()
        }
    })
}


export const useCreatePersonBankInformation = (personId:number) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params:any) => ameli.post(`persons/${personId}/bankInformation`, params),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['bank_informations'] })
        }
    })
}