import React, { useCallback, useEffect, useMemo } from "react";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import TableHead from "./TableHead";
import styled from 'styled-components'
import TableBody from "./TableBody";
import ScrollView from './../ScrollView';
import TableFooter from "./TableFooter";

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
`

export default function Table({ 
    columns, 
    data, 
    onClick=null, 
    onChange=(value) => {},
    ...props
}) {

    const securedData = useMemo(() => {
        return data || []
    }, [data])

    const handleChange = useCallback((rowIndex, value) => {
        onChange(securedData?.map((row, index) => {
            if (index === rowIndex) {
                return value
            }
            return row
        }))
    }, [onChange, securedData])

    const {
        getTableProps, 
        getTableBodyProps, 
        headerGroups,
        footerGroups,
        rows, 
        prepareRow,
        setGlobalFilter,
    } = useTable(
        { 
            columns,
            data:securedData,
            autoResetFilters: false,
            autoResetSortBy: false,
            reportChange: handleChange
        }, 
        useFilters, 
        useGlobalFilter, 
        useSortBy
    );

    useEffect(() => {
        setGlobalFilter(props.filter || "");
    }, [props.filter, setGlobalFilter])  

    return (
        <ScrollView>
            <StyledTable {...getTableProps()}>
                <TableHead headerGroups={headerGroups}/>
                <TableBody 
                    onClick={onClick}
                    prepareRow={prepareRow}
                    rows={rows} 
                    {...getTableBodyProps()}
                />
                <TableFooter footerGroups={footerGroups}/>
            </StyledTable>
        </ScrollView>
    );
  }