import ClickableOpacity from "components/Decorator/ClickableOpacity"
import Row from "components/Row"
import IconWrapper from "./IconWrapper"
import styled from 'styled-components';

const Content = styled.div`
    padding-top: 0.0em;
`

export interface IconRowProps {
    onClick?: () => any,
    children?: React.ReactNode,
    Icon?:React.ReactNode
}

export default function IconRow({ onClick, children, Icon=null }: IconRowProps) {
    return (
        <ClickableOpacity onClick={onClick}>
            <Row gap='0.25em'>
                <IconWrapper size={'16'}>
                    { Icon }
                </IconWrapper>
                <Content>
                    { children }
                </Content>
            </Row>
        </ClickableOpacity>
    )
}