
import { IModel } from "../../Models/IModel"
import ServiceDetails from "./ServiceDetails"
import DataTypes from "Common/Objects/DataTypes"
import { PropertyDefinitions } from "Common/Objects/PropertyDefinition"

const properties: PropertyDefinitions = {
    id: {
        type: 'single',
        primaryKey: true,
        label: "Id",
        valueType: new DataTypes.Integer({
            unsigned: true,
            allowNull: true
        })
    },
    serviceId: {
        type: 'single',
        label: "Dienstleistung Id",
        valueType: new DataTypes.Integer({
            unsigned: true,
            allowNull: true
        })
    },
    content: {
        type: 'single',
        label: 'Inhalt',
        valueType: new DataTypes.Text({
            length: 128
        }),
        required: true
    },
    createdAt: {
        type: 'single',
        label: 'Erstellt am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
    updatedAt: {
        type: 'single',
        label: 'Bearbeitet am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
}

const ServiceDetailsModel: IModel<ServiceDetails> = {
    name: 'serviceDetails',
    labels: ['Dienstleistungsdetail', 'Dienstleistungsdetails'],
    objectDefinition: {
        label: "Dienstleistung Details",
        type: 'object',
        properties,
    },
    apiPath: 'service_details',
}

ServiceDetailsModel.queryKeys = ['services']

export default ServiceDetailsModel
