import ListItem from 'components/ListView/BetterListItem';

export default function OperationTaskListItem({task, ...props}) {
    
    return (
        <ListItem 
            title={task.title || 'Ohne Titel'}
            subtitle={task?.subtitle}
            {...props}
        />   
    )    
}