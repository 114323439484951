import SubmitButton from "Forms/SubmitButton"
import { IFormButtonProps } from "./IFormButtonProps"

export default function FormUpdateButton({form, label='Speichern', ...props}:IFormButtonProps) {

    return (
        <SubmitButton
            disabled={!form.fieldsChanged || !form.fieldsValid}
            {...props}
        >
            {label}
        </SubmitButton>
    )
}