import SubmitButton from "Forms/SubmitButton"
import { IFormButtonProps } from "./IFormButtonProps"

export default function FormCreateButton({visible, form, label='Erfassen', ...props}:IFormButtonProps) {

    if(!visible) {
        return null
    }

    return (
        <SubmitButton
            disabled={!form.fieldsValid}
            {...props}
        >
            {label}
        </SubmitButton>
    )
}