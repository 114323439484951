import Table from "components/table/table";
import { useInput } from "hooks/input";
import { useCustomerBillColumns } from "../../../Customer/useCustomerBillColumns";
import moment from "moment";
import YearSelect from "components/Time/YearSelect";
import useBills from '../../../Bills/useBills';
import Spacer from '../../../components/Spacer/Spacer';
import Row from "components/Row";
import TextInput from '../../../components/input/TextInput';
import Tile from "components/Tiles/Tile";
import { useOutletContext } from "react-router-dom";
import CustomerHeaderBar from "Customer/CustomerHeaderBar";
import { useContext } from "react";
import { ModalContext } from "components/modal/ModalContext";
import { PersonOutletContextType } from "Persons/PersonOutlet";

export default function CustomerBills() {

    const { person } = useOutletContext<PersonOutletContextType>()
    const { value: year, bind: bindYear } = useInput(moment().year());
    const { value: filter, bind: bindFilter } = useInput("");
    const columns = useCustomerBillColumns();    
    const { data } = useBills({
        owner_id: person?.id,
        year
    })
    const { billModal } = useContext(ModalContext)

    return (
        <>
            <CustomerHeaderBar/>
            <Spacer medium shrink>
                <Tile>
                    <Spacer hMedium>
                        <Row>
                            <TextInput size={20} label="Suchen" type='text' {...bindFilter}/>
                            <YearSelect label='Jahr' value={year} {...bindYear}></YearSelect>
                        </Row>
                    </Spacer>
                    <Table
                        filter={filter}
                        onClick={billModal.select}
                        columns={columns}
                        data={data || []}
                    />
                </Tile>
            </Spacer>
        </>
    );
}