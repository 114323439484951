import NavigationLink from "components/Navigation/NavigationLink"
import DefaultTab from "./DefaultTab"
import { useLocation } from "react-router-dom"


const DefaultNavigationTab = ({ to, label }) => {

    const { search } = useLocation()

    return (
        <NavigationLink to={`${to}/${search}`} end>
            {({ isActive }) => (
                <DefaultTab active={isActive} label={label} />
            )}
        </NavigationLink>
    )
}

export default DefaultNavigationTab
