import Button, { ButtonProps } from 'components/Button';
import { Oval, ThreeDots } from 'react-loader-spinner'

export interface SubmitButtonProps extends ButtonProps {
    visible?: boolean,
    isPending?: boolean,
    confirmMessage?: string
}

export default function SubmitButton({ 
    isPending=false, 
    visible=true, 
    disabled=false,
    confirmMessage,
    onClick,
    ...props
}: SubmitButtonProps) {

    const handleClick = () => {
        if(confirmMessage) {
            if(window.confirm(confirmMessage)) {
                onClick()
            }
        }
        else {
            onClick()
        }
    }   

    return (
        visible ? 
            <Button 
                disabled={isPending || disabled} 
                onClick={handleClick}
                {...props}
            >
                {
                    isPending ? (
                        <Oval
                            height={20}
                            width={20}
                            color={props.color}
                        />
                    )
                    : 
                    props.children
                }
            </Button>
            :
            null
    )
}