import { useState } from "react";
import IInputProps from "./IInputProps";
import Input from "./Input";
import InputWrapper from "./InputWrapper";

interface IIntegerInputProps extends IInputProps {

}

export default function FloatInput({
    name,
    value,
    setValue,
    allowNull,
    enabled,
    ...props
}:IIntegerInputProps) {

    const [focused, setFocused] = useState(false)

    const handleChange = (e) => {
        let value = e.target.value
        if (value === '' && allowNull) {
            setValue(null)
        }
        else {
            setValue(parseFloat(value))
        }
    }

    return (
        <InputWrapper focused={focused} setFocused={setFocused} enabled={enabled} {...props}>
            <Input 
                tabIndex={enabled ? undefined : -1}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                name={name}
                type='number' 
                onChange={handleChange} 
                value={value == null || isNaN(value) ? '' : value}
                {...props}
            />
        </InputWrapper>
    )
}