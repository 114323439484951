import Action, { ActionOptions } from "../Actions/Action";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Theme from "Theming/Theme";
import { PartialObject } from "lodash";
import { useCallback, useMemo } from "react";
import { IObject } from "../Objects/useObject";
import { useApiContext } from "./ApiContext";

export default function usePost<T>(apiPath:string, queryKeys:string[]=[], object:IObject) : Action {
    const queryClient = useQueryClient()
    const { axiosInstance } = useApiContext()

    const mutation = useMutation({
        mutationKey: [apiPath, 'post'],
        mutationFn: (data: PartialObject<T>) => axiosInstance.post(apiPath, data),
    })

    const execute = useCallback((options?:ActionOptions) => {
        return mutation.mutate(object.value, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [apiPath]
                })
                queryKeys?.forEach(key => {
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                })
                options?.onSuccess()
            },
            onError: () => {
                options?.onError()
            },
            onSettled: () => {
                options?.onSettled()
            }
        })
    }, [apiPath, queryKeys, queryClient, mutation, object])

    const disabled = useMemo(() => {
        return !(object.valid && !object.isUpdate)
    }, [object])

    const visible = useMemo(() => {
        return !object.isUpdate
    }, [object])

    return {
        label: 'Erfassen',
        error: mutation.error,
        isPending: mutation.isPending,
        color: Theme.whiteColor,
        backgroundColor: Theme.primaryColor,
        execute,
        disabled,
        visible
    }
}