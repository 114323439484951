
import styled from 'styled-components';

const SideBar = styled.div`
    display: flex;
    flex-direction:column;
    grid-area: sidebar;
    background-color: white;
    border-right: 1px solid #dbdbdb;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    min-height: 0;
    flex-shrink: 0;
    z-index: 1;
    overflow: hidden;
`

const SideBarSection = styled.div`
    border-bottom: 1px solid #ccc;

    &:last-child {
        border: none;
    }
`


export default SideBar
export { SideBarSection }