import moment, { Moment } from "moment"
import { parseLocalizedFloat } from "utils/floatUtility"


class OperationReportEntry {

    reportId:number
    date:string
    startTime:string
    endTime:string
    start: Moment
    kilometer: number
    hours: number

    constructor(reportId, date, startTime, endTime, hours, kilometer) {
        this.reportId = reportId
        this.date = date
        this.setStartTime(startTime)
        this.setEndTime(endTime)
        this.setHours(hours)
        this.setKilometer(kilometer)
    }

    refreshStart = () => {
        if(!this.date || !this.startTime) {
            this.start = null
        }
        this.start = moment(this.date + " " + this.startTime, 'YYYY-MM-DD HH:mm:ss')
    }

    setDate = (value) => {
        this.date = value
        this.refreshStart()
    }

    setKilometer = (value) => {
        this.kilometer = parseLocalizedFloat(value, this.kilometer)
    }

    setHours = (value) => {
        value = parseLocalizedFloat(value, this.hours)
        this.hours = value
    }

    setStartTime = (value) => {
        this.startTime = value ? moment(value, 'HH:mm').format('HH:mm') : null
        this.refreshStart()
    }

    setEndTime = (value) => {
        this.endTime = value ? moment(value, 'HH:mm').format('HH:mm') : null
    }

    static clone(other) {
        return new OperationReportEntry(other.reportId, other.date, other.startTime, other.endTime, other.hours, other.kilometer)
    }


    static fromEvent = (event) => {
        var hours = event.hours
        var kilometer = event.kilometer
        var date = event.date
        var startTime = event.time || '10:00'
        var endTime = startTime ? moment(startTime, 'HH:mm:ss').add(hours, 'hours').format('HH:mm:ss') : null

        return new OperationReportEntry(undefined, date, startTime, endTime, hours, kilometer)
    }

    static fromDatabase = (data) => {
        return new OperationReportEntry(
            data.report_id,
            data.date,
            data.start_time,
            data.end_time,
            data.hours,
            data.kilometer
        )
    }

    static toDatabase = (entry) => {
        return {
            report_id: entry.reportId,
            date: entry.date,
            start_time: entry.startTime,
            end_time: entry.endTime,
            hours: entry.hours,
            kilometer: entry.kilometer
        }
    }

    combine(other) {
        var entry = OperationReportEntry.clone(this)
        entry.hours += other.hours
        entry.kilometer += other.kilometer
        entry.setStartTime(
            !entry.startTime ? other.startTime 
            : !other.startTime ? entry.startTime 
            : moment.min(moment(entry.startTime, 'HH:mm'), moment(other.startTime, 'HH:mm')).format('HH:mm'))
        entry.setEndTime(entry.startTime ? moment(entry.startTime, 'HH:mm:ss').add(entry.hours, 'hours').format('HH:mm:ss') : null)
        return entry
    }

    valid = () => {
        return Boolean(this.date)
    }
}

export default OperationReportEntry