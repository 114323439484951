import moment from "moment"
import ListItem from "components/ListView/BetterListItem";
import Theme from "Theming/Theme";

const NoteCard = ({note, showEditTimestamp=true, onClick=(note)=>{}, ...props}) => {

    const renderTimestamp = showEditTimestamp && note.timestamp;
    const dateString = moment(note.timestamp).format('DD.MM.yyyy');
    const timeString = moment(note.timestamp).format('HH:mm');

    return (
        <ListItem 
            title={note.title || 'Ohne Titel'} 
            subtitle={renderTimestamp ? 
                `Geändert am ${dateString} um ${timeString} Uhr`
                : undefined
            }
            onClick={() => onClick(note)}
            bannerColor={Theme.subtleColor}
        >
        </ListItem>
    )
}

export default NoteCard