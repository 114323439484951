import Modal, { ModalFooter } from "components/modal/Modal";
import Spacer from "components/Spacer/Spacer";
import BudgetForm from "./BudgetForm";
import { IModalProps } from './../components/modal/Modal';
import Budget from "./Budget";
import { useBudgetDelete, useBudgetUpdate } from "./BudgetApi";
import useBudgetForm from "./useBudgetForm";
import FormUpdateButton from "Forms/FormUpdateButton";
import FormDeleteButton from "Forms/FormDeleteButton";

interface Props extends IModalProps<Budget>{}

export default function BudgetModal({
    selected:budget, 
    setVisible,
    ...props
}:Props) {

    const updateBudget = useBudgetUpdate()
    const deleteBudget = useBudgetDelete()
    const form = useBudgetForm(budget)

    const update = () => {
        updateBudget.mutate(form.data, {
            onSettled: () => {
                setVisible(false)
            }
        })
    }

    const remove = () => {
        deleteBudget.mutate(form.data.budget_id, {
            onSettled: () => {
                setVisible(false)
            }
        })
    }

    return (
        <Modal
            {...props}
            selected={budget}
            setVisible={setVisible}
            title={"Budget bearbeiten"}>
            <Spacer medium>
                <BudgetForm form={form}/>
                <ModalFooter>
                    <FormUpdateButton
                        form={form}
                        visible
                        onClick={update}
                    />
                    <FormDeleteButton
                        form={form}
                        visible
                        onClick={remove}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}