import styled from 'styled-components'
import Label from './Label'

const LabelRow = styled.div`
    display: flex;

    ${props => props.onClick ? 
        `
            :hover {
                ${Label} {
                    color: #ce0069;
                    text-decoration: underline;
                }
            }
        `
        : 
        ''
    }
    
`

export default LabelRow