import { useMemo } from "react";
import Employee from "./Employee";
import useForm from "Forms/useForm";
import { generateBooleanField, generateDateField, generatePositiveFloatField, generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory";

export default function useEmployeeForm(employee:Employee) {
    const fields = useMemo(() => ({
        person_id: generatePositiveIntegerField(employee?.person_id, false),
        work_field_id: generatePositiveIntegerField(employee?.work_field_id, false),
        clearance_certificate: generateBooleanField(employee?.clearance_certificate, false),
        driving_license: generateBooleanField(employee?.driving_license, false),
        car: generateBooleanField(employee?.car, false),
        bank_information_id: generatePositiveIntegerField(employee?.bank_information_id, false),
        tax_bracket_id: generatePositiveIntegerField(employee?.tax_bracket_id, false),
        tax_number: generateTextField(employee?.tax_number, false),
        tax_office_id: generatePositiveIntegerField(employee?.tax_number, false),
        social_insurance_number: generatePositiveIntegerField(employee?.social_insurance_number, false),
        child_allowance: generatePositiveFloatField(employee?.child_allowance, false),
        home_care: generateBooleanField(employee?.home_care, false),
        housekeeping: generateBooleanField(employee?.housekeeping, false),
        dementia: generateBooleanField(employee?.dementia, false),
        determinable: generateBooleanField(employee?.determinable, false),
        determinable_till: generateDateField(employee?.determinable_till, false),
        occupation: generateTextField(employee?.occupation, false),
        sideline_job: generateBooleanField(employee?.sideline_job, false),
        monthly_hours: generatePositiveFloatField(employee?.monthly_hours, false),
        operation_radius: generatePositiveFloatField(employee?.operation_radius, false),
        hourly_wage: generatePositiveFloatField(employee?.hourly_wage, false),
        parenthood: generateBooleanField(employee?.parenthood, false),
        annuity_insurance: generateBooleanField(employee?.annuity_insurance, false),
        nursing_training: generateBooleanField(employee?.nursing_training, false),
        minijob: generateBooleanField(employee?.minijob, false),
        state: generateBooleanField(employee?.state, false)
    }), [employee])

    return useForm(fields)
}