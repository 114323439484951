import { formatPeriodString } from "Time/timeUtility"
import Label from "./Label"

const PeriodLabel = ({ start, end, format = undefined, ...props }) => {
    return (
        <Label {...props}>
            {formatPeriodString(start, end, '', format)}
        </Label>
    )
}

export default PeriodLabel