import DateLabel from "components/labels/DateLabel";
import { useMemo } from "react";
import TableCell from "components/table/Cells/TableCell";
import Label from "components/labels/Label";

export default () => {
    const columns = useMemo(() =>
        [
            {
                Header: 'Mitarbeiter',
                accessor: d => d,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{`${value.employee_first_name} ${value.employee_last_name}`}</Label>
                        )}
                    />
                )
            },
            {
                Header: 'Antragsdatum',
                accessor: d => d.request_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (<DateLabel date={value} />)}
                    />
                )
            },
            {
                Header: 'Zeitraum von',
                accessor: d => d.start_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (<DateLabel date={value} />)}
                    />
                )
            },
            {
                Header: 'Zeitraum bis',
                accessor: d => d.end_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => {
                            return value ?
                                <DateLabel date={value}></DateLabel>
                                :
                                "Offen"
                        }}
                    />
                )
            },
            {
                Header: 'Bezahlt',
                accessor: d => d.paid,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (<Label>{value ? "Ja" : "Nein"}</Label>)}
                    />
                )
            },
            {
                Header: 'Status',
                accessor: d => d.rejected,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (<Label>{value ? "Abgelehnt" : "Ausstehend"}</Label>)}
                    />
                )
            },
        ],
        []
    );

    return columns;
}