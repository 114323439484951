import styled from 'styled-components'

const SideBar = styled.div`
    flex: 1;
    display: grid;
    grid-template-rows: minmax(auto, 1fr);
    grid-template-columns: minmax(20em, 20em) 1fr;
    grid-template-areas:
        "sidebar page";
    overflow: hidden;
`

const ScreenLayout = {
    SideBar
}

export default ScreenLayout