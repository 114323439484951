import { useCallback, useEffect, useMemo, useReducer } from "react"

const parseField = (field) => {
    if (field.enabled === false) {
        if ('disabledValue' in field) {
            return field.disabledValue
        }
        return undefined
    }
    if ('value' in field) {
        return field.value
    }
    if ('fields' in field) {
        return parseFields(field.fields, undefined)
    }
    return undefined
}

const parseFields = (fields, fallback) => {
    var values = {}
    for (const [name, field] of Object.entries(fields)) {
        var value = parseField(field)
        if (value !== undefined) {
            values[name] = value
        }
    }
    return Object.entries(values).length === 0 ? fallback : values
}

const getFieldObject = (fields, path) => {
    var keys = path.split('.')
    var obj = fields
    while (keys.length > 0) {
        var key = keys.shift()
        if ('fields' in obj) {
            obj = obj['fields']
        }
        if (!obj || !(key in obj)) {
            return {}
        }
        if (keys.length === 0) {
            return obj[key]
        }
        obj = obj[key]
    }
    return {}
}

const getFieldArray = (fields, includeDisabled = false) => {
    var result = []
    for (const [, field] of Object.entries(fields)) {
        if (field.enabled === false && !includeDisabled) {
            continue
        }
        if ('fields' in field) {
            result.push(...getFieldArray(field.fields, includeDisabled))
        }
        result.push(field)
    }
    return result
}

const getFieldEntry = (field, key, fallback) => {
    if (!field) {
        return fallback
    }
    return key in field && field[key] !== undefined ? field[key] : fallback
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'set_field_value': {
            let field = getFieldObject(state, action.path)
            field.value = action.payload
            field.changed = field.value !== field.initialValue
            var valueToValidate = 'value' in field ? field.value : field.initialValue
            field.valid = field.validate ? field.validate(valueToValidate) : true
            return { ...state }
        }
        case 'set_field_enabled': {
            let field = getFieldObject(state, action.path)
            field.enabled = action.payload
            return { ...state }
        }
        case 'set_fields':
            if (!action.payload) {
                return {}
            }
            var fields = {}
            Object.keys(action.payload).forEach(key => {
                var field = action.payload[key]
                var value = field.initialValue
                fields[key] = {
                    ...field,
                    value,
                    changed: false,
                    valid: field.validate ? field.validate(value) : true
                }
            })
            return fields
        default:
            return state
    }
}

const useForm = (initialFields) => {

    const [fields, dispatch] = useReducer(reducer, initialFields)

    useEffect(() => {
        dispatch({
            type: 'set_fields',
            payload: initialFields
        })
    }, [initialFields, dispatch])

    const setFieldValue = useCallback((path, value) => {
        dispatch({
            type: 'set_field_value',
            path: path,
            payload: value
        })
    }, [dispatch])

    const setFieldEnabled = useCallback((path, value) => {
        dispatch({
            type: 'set_field_enabled',
            path: path,
            payload: value
        })
    }, [dispatch])

    const getField = useCallback((path) => {
        return getFieldObject(fields, path)
    }, [fields])

    const getFieldValue = useCallback((path, fallback=undefined) => {
        return getFieldEntry(getField(path), 'value', fallback)
    }, [getField])

    const fieldsChanged = useMemo(() => {
        return getFieldArray(fields, true).some(field => field.changed)
    }, [fields])

    const fieldsValid = useMemo(() => {
        var invalid = getFieldArray(fields).some(field => !field.valid)
        return !invalid
    }, [fields])

    const data = useMemo(() => {
        return parseFields(fields, {})
    }, [fields])

    return {
        getField,
        setFieldValue,
        getFieldValue,
        fieldsChanged,
        fieldsValid,
        setFieldEnabled,
        getFieldEnabled: (path, fallback = true) => getFieldEntry(getField(path), 'enabled', fallback),
        data
    }
}

export default useForm