import { useCallback } from "react"
import useApiData from '../hooks/useApiData';
import useSignalingActions from "Signals/useSignalingActions";
import ameli from "Api/ameli";

const channel = ['ContactPersons']

const actions = {
    'create': data => ameli.post('contact_persons', data),
    'delete': data => ameli.delete(`contact_persons/${data}`),
    'update': data => ameli.put(`contact_persons`, data)
}

const useContactPersonActions = () => {
    return useSignalingActions(actions, channel)
}

const useContactPersons = (params={}) => {
    
    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get('contact_persons', { params })
            .then(response => {
                resolve(response.data.map((operation) => {
                    return {
                        ...operation,
                    }
                }))
            })
    }), [params])


    const state = useApiData(loadHandler, channel)

    return state
}

export default useContactPersons
export { useContactPersonActions }