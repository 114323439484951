import { useMutation, UseMutationOptions, useQuery, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import BankInformation from "./BankInformation"


export const useBankInformation = (id: number) => {
    return useQuery({
        queryKey: ['bank_informations', id],
        queryFn: () => ameli.get<BankInformation>(`bank_informations/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}


export function useBankInformationMutation(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation({
        mutationFn,
        onSettled: (...props) => {
            queryClient.invalidateQueries({ queryKey: ['bank_informations'] })
        }
    })
}


export const useBankInformationUpdate = () => {
    return useBankInformationMutation((body) => ameli.put(`bank_informations`, body))
}

export const useBankInformationDelete = () => {
    return useBankInformationMutation((id: number) => ameli.delete(`bank_informations/${id}`))
}
