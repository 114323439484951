import EmployeeSelect from "Employees/EmployeeSelect"
import Column from "components/Column"
import Row from "components/Row"
import { FormGroup } from "components/form/FormComponents"
import DatePicker from "components/input/DatePicker"
import TextArea from "components/input/TextArea"
import TimeInput from "components/input/TimeInput"
import EventDemandSelect from "./EventDemandSelect"
import Dropdown from "components/dropdown/dropdown"
import { useCallback, useContext, useMemo } from "react"
import HourInput from "components/input/hour-input"
import KilometerInput from "components/input/kilometer-input"
import EuroInput from "components/input/euro-input"
import ConditionalView from "components/Decorator/ConditionalView"
import EventConfirmationSelect from "./EventConfirmationSelect"
import EventUtility from "./EventUtility"
import { CustomerContext } from "Customer/CustomerContext"
import ameli from "Api/ameli"
import { useQuery } from '@tanstack/react-query';
import { EmployeeContext } from "Employees/EmployeeContext"
import PersonSelect from "Persons/PersonSelect"

const EventForm = ({form, defaultFocus}) => {

    const { archived:archivedCustomers, active:activeCustomers } = useContext(CustomerContext)
    const { active:activeEmployees } = useContext(EmployeeContext) 

    const assignedCustomers = useQuery({
        queryKey: ["employee", form.data.employee_id, form.data.date, "assigned_customers"],
        queryFn: () => ameli.get(`/employees/${form.data.employee_id}/responsible_customers`, { params: { period_start: form.data.date, period_end: form.data.date } }).then(res => res.data),
        enabled: Boolean(form.data.date && form.data.employee_id)
    })

    const sortCustomers = useCallback((a,b) => {
        return a.last_name?.localeCompare(b.last_name)
    }, [])

    const makeOptions = useCallback((customers) => {
        if(!customers) {
            return []
        }
        return customers
            .sort(sortCustomers)
            .map((customer) => {
                var label = `${customer.last_name}, ${customer.first_name}`

                if (customer.customer?.state === null) {
                    label = `${label} (Interessent)`
                }

                if (customer.customer?.state === 0) {
                    label = `${label} (Ehemalig)`
                }

                return {
                    value: customer.id,
                    label
                }
            })
    }, [sortCustomers])

    const customerOptions = useMemo(() => {
        var options = []

        options.push({
            label: `Zugewiesen (${assignedCustomers.data?.length})`,
            options: makeOptions(assignedCustomers.data)
        })
        options.push({
            label: `Aktuell (${activeCustomers.length})`,
            options: makeOptions(activeCustomers)
        })
        options.push({
            label: `Archiviert (${archivedCustomers.length})`,
            options: makeOptions(archivedCustomers)
        })
        return options
    }, [makeOptions, activeCustomers, archivedCustomers, assignedCustomers.data])

    return (
        <Column>
            <FormGroup>
                <PersonSelect
                    persons={activeEmployees}
                    label='Mitarbeiter'
                    name='employee_id'
                    value={form.data.employee_id}
                    onChange={({value}) => {
                        form.setFieldValue('employee_id', value)
                        if(!('id' in form.data)) {
                            form.setFieldValue('creator_id', value)
                        }
                    }}
                />

                <Dropdown
                    label='Kunde'
                    options={customerOptions}
                    name='customer_id'
                    value={form.data.customer_id}
                    onChange={({value, label}) => {
                        form.setFieldValue('customer_id', value)
                        form.setFieldValue('title', label)
                    }}
                />
             
                <Row>
                    <DatePicker
                        grow
                        ref={defaultFocus}
                        label='Datum'
                        value={form.getFieldValue('date')}
                        onChange={value => form.setFieldValue('date', value)}
                    />
                    <TimeInput
                        grow
                        label='Uhrzeit'
                        value={form.getFieldValue('time')}
                        onChange={e => form.setFieldValue('time', e.target.value)}
                    />
                </Row>

                <Row>
                    <HourInput
                        label='Stunden'
                        name='hours'
                        value={parseFloat(form.data.duration ?? 0) / 3600}
                        onChange={(e) => form.setFieldValue('duration', parseFloat(e.currentTarget.value || 0) * 3600)}
                    />
                    <EuroInput
                        grow
                        label='Stundensatz'
                        name='hourly_wage'
                        value={form.data.hourly_wage ?? 0}
                        onChange={(e) => form.setFieldValue('hourly_wage', e.currentTarget.value)}
                    />
                </Row>
                <Row>
                    <KilometerInput
                        label='Kilometer'
                        name='kilometer'
                        value={form.data.kilometer ?? 0}
                        onChange={(e) => form.setFieldValue('kilometer', e.currentTarget.value)}
                    />
                    <EuroInput
                        grow
                        label='Kilometersatz'
                        name='kilometer_cost'
                        value={form.data.kilometer_cost ?? 0}
                        onChange={(e) => form.setFieldValue('kilometer_cost', e.currentTarget.value)}
                    />
                </Row>
                <TextArea
                    label='Details'
                    optional
                    minRows={3}
                    name='details'
                    value={form.getFieldValue('details')}
                    onChange={e => form.setFieldValue('details', e.target.value)}
                />
                <ConditionalView condition={EventUtility.isUnclear(form.data)}>
                    <EventDemandSelect
                        label='Vertretungsbedarf'
                        onChange={({ value }) => form.setFieldValue('demand', value)}
                        value={form.getFieldValue('demand')}
                    />
                </ConditionalView>
                <EventConfirmationSelect
                    label='Status'
                    onChange={({ value }) => form.setFieldValue('confirmed', value)}
                    value={form.getFieldValue('confirmed')}
                />
            </FormGroup>
           
        </Column>
    )

}

export default EventForm