import BetterNumberFormat from "./better-number-format";

export default function EuroInput(props) {

    return (
        <BetterNumberFormat
            onFocus={(event) => event.target.select()}
            onClick={(event) => event.target.select()}
            suffix=' €'    
            allowedDecimalSeparators={[",", "."]}
            {...props}
        />
    );
}