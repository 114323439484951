import EmployeeSelect from "Employees/EmployeeSelect"
import ConditionalView from "components/Decorator/ConditionalView"
import Row from "components/Row"
import Checkbox from "components/checkbox/Checkbox"
import { FormGroup } from "components/form/FormComponents"
import DatePicker from "components/input/DatePicker"
import TextArea from "components/input/TextArea"


const VacationRequestForm = ({ form }) => {
    return (
        <div>
            <FormGroup>
                <EmployeeSelect
                    label='Mitarbeiter'
                    value={form.getFieldValue('employee_id')}
                    onChange={({value}) => form.setFieldValue('employee_id', value)}
                />
                <DatePicker
                    label='Antragsdatum'
                    value={form.getFieldValue('request_date')}
                    onChange={(value) => form.setFieldValue('request_date', value)}
                />
                <Row>
                    <DatePicker
                        grow
                        label='Beginn'
                        value={form.getFieldValue('start_date')}
                        onChange={(value) => form.setFieldValue('start_date', value)}
                    />
                    <DatePicker
                        grow
                        label='Ende'
                        value={form.getFieldValue('end_date')}
                        onChange={(value) => form.setFieldValue('end_date', value)}
                    />
                </Row>
                <Checkbox
                    label='Bezahlt'
                    value={form.getFieldValue('paid')}
                    onChange={({ value }) => form.setFieldValue('paid', value)}
                />
                <TextArea
                    minRows={3}
                    optional
                    label='Anmerkungen'
                    value={form.getFieldValue('details')}
                    onChange={e => form.setFieldValue('details', e.target.value)}
                />
            </FormGroup>
        </div>
    )
}

export default VacationRequestForm