import { useCallback } from "react"
import useApiData from 'hooks/useApiData';
import useSignalingActions from "Signals/useSignalingActions";
import ameli from "Api/ameli";

const channel = ['OperationTasks']

const actions = {
    'create': data => ameli.post('operation_tasks', data),
    'delete': data => ameli.delete(`operation_tasks/${data}`),
    'update': data => ameli.put(`operation_tasks`, data)
}

const useOperationTaskActions = () => {
    return useSignalingActions(actions, channel)
}


const useOperationTasks = (params) => {
    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get('operation_tasks', { params })
            .then(response => {
                resolve(response.data.map((operation) => {
                    return {
                        ...operation,
                    }
                }))
            })
    }), [params])


    const state = useApiData(loadHandler, channel)

    return state
}

export default useOperationTasks
export { useOperationTaskActions }