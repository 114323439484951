import IconRow from "components/Icons/IconRow";
import PhoneNumber from "./PhoneNumber";
import { FaPhone } from "react-icons/fa";
import ListView from "components/ListView/ListView";
import Label from "components/labels/Label";

interface Props {
    phoneNumbers: PhoneNumber[],
    onClick?: () => any
}

export default function PhoneNumberIconRow({phoneNumbers, onClick}:Props) {
    if(phoneNumbers.length === 0) {
        return null
    }

    return (
        <IconRow Icon={<FaPhone/>} onClick={onClick}>
            <ListView
                data={phoneNumbers}
                Separator={() => null}
                listItem={(data) => (
                    <Label small subtle>{`${data.number} (${data.type_label})`}</Label>
                )}
            />
        </IconRow>
    )
}