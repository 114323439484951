import { DataType } from "../Objects/DataTypes"
import { PartialObject } from "lodash"


export type Property<T extends PropertyData> = ObjectProperty<T> | SingleProperty<T> | ArrayProperty<T>

export type PropertyData = {}

export type Properties<T extends PropertyData> = {
    [key: string]: Property<T>
}

export type ObjectProperty<T extends PropertyData> = T & {
    type: 'object',
    properties: Properties<T>
}

export type SingleProperty<T extends PropertyData> = T & {
    type: 'single',
    valueType: DataType
}

export type ArrayProperty<T extends PropertyData> = T & {
    type: 'array',
    properties: Property<T>[]
}


const pathIncluded = (path: string, keys: string[]): boolean => {
    if (!Array.isArray(keys)) {
        return false
    }
    if (keys.includes(path)) {
        return true
    }
    return false
}

export function includeProperties(object: ObjectProperty<any>, keys: string[], path: string = ''): ObjectProperty<any> {
    if (pathIncluded(path, keys)) {
        return object
    }

    var includedChildren: Properties<any> = {}
    Object.keys(object.properties).forEach(key => {
        var child = object.properties[key]
        var childPath = [path, key].filter(e => e !== '').join('/')
        if (child.type === 'object') {
            child = includeProperties(child, keys, childPath)
            if (child) {
                includedChildren[key] = child
            }
        }
        else if (pathIncluded(childPath, keys)) {
            includedChildren[key] = child
        }
    })

    if (Object.values(includedChildren).length === 0) {
        return null
    }

    return {
        ...object,
        properties: includedChildren
    }
}

export const getProperty = (object: ObjectProperty<any>, key: string, path: string = ''): Property<any> => {
    if (path === key) {
        return object
    }

    var result = null

    Object.keys(object.properties).forEach(name => {
        var child = object.properties[name]
        var childPath = [path, name].filter(e => e !== '').join('/')
        if (child.type === 'object') {
            child = getProperty(child, key, childPath)
            if (child) {
                result = child
                return child
            }
        }
        else if (childPath === key) {
            result = child
            return
        }
    })

    return result
}

export function setProperty<T extends PropertyData>(object:ObjectProperty<T>, key:string, value:PartialObject<T>) {
    var property = getProperty(object, key)
    if(property) {
        property = {
            ...property,
            ...value
        }
    }
}


export const excludeProperties = (object: ObjectProperty<any>, keys: string[], path: string = ''): ObjectProperty<any> => {
    if (pathIncluded(path, keys)) {
        return null
    }

    var includedChildren: Properties<any> = {}
    Object.keys(object.properties).forEach(key => {
        var child = object.properties[key]
        var childPath = [path, key].filter(e => e !== '').join('/')
        if (child.type === 'object') {
            child = excludeProperties(child, keys, childPath)
            if (child) {
                includedChildren[key] = child
            }
        }
        else if (!pathIncluded(childPath, keys)) {
            includedChildren[key] = child
        }
    })

    if (Object.values(includedChildren).length === 0) {
        return null
    }

    return {
        ...object,
        properties: includedChildren
    }
}
