
import { AxiosInstance } from "axios"
import React, { useContext } from "react"

type ApiContextValue = {
    axiosInstance:AxiosInstance
}

const ApiContext = React.createContext<ApiContextValue>(null)

type ApiProviderProps = {
    axiosInstance: AxiosInstance,
    children: React.ReactNode
}

const ApiProvider = ({ axiosInstance, ...props}:ApiProviderProps) => {
    return (
        <ApiContext.Provider value={{axiosInstance}} {...props}/>
    )
}

const useApiContext = () =>{
    return useContext(ApiContext)
}

export { ApiContext, ApiProvider, useApiContext }