import DataTypes from "Data/DataTypes"
import NumberUtility from "utils/NumberUtlity"
import IFormField from "./IFormField"

const getValidator = (type, unsigned=false) => {
    switch(type) {
        case DataTypes.INTEGER:
            return (value) => {
                var parsed = parseInt(value)
                return !isNaN(parsed) && (!unsigned || parsed > 0)
            }
        case DataTypes.FLOAT:
            return (value) => {
                var parsed = parseFloat(value)
                return !isNaN(parsed) && (!unsigned || parsed > 0)
            }
        default:
            return (value) => true
    }
}

export const generateFormField = (field, initialValue) : IFormField => {

    const validator = getValidator(field)

    return {
        enabled: field.enabled || true,
        initialValue,
        validate: (value) => {
            if ((value === null || value === undefined) && !field.required) {
                return true
            }
            return validator(field)
        }
    }
}

export const generateObjectField = (initialValue, required): IFormField => {
    return {
        initialValue,
        enabled: true,
        validate: (value) => !required || value != null,
    }
}

export const generateDateField = (initialValue, required):IFormField => {
    return {
        initialValue,
        enabled: true,
        validate: (date) => !required || date,
    }
}

export const generateTextField = (initialValue, required): IFormField => {
    return {
        initialValue,
        enabled: true,
        validate: (text) => !required || text,
    }
}

export const generateBooleanField = (initialValue, required): IFormField => {
    return {
        enabled: true,
        initialValue: initialValue === null ? null : Boolean(initialValue),
        validate: (value) => !required || value
    }
}

export const generateTimeField = (initialValue, required): IFormField => {
    return {
        enabled: true,
        initialValue,
        validate: (time) => !required || time
    }
}

export const generateDateTimeField = (initialValue, required): IFormField => {
    return {
        enabled: true,
        initialValue,
        validate: (date) => !required || date
    }
}

export const generatePositiveIntegerField = (initialValue, required): IFormField => {
    return {
        enabled: true,
        initialValue,
        validate: value => !required || NumberUtility.isPositiveInteger(parseInt(value))
    }
}

export const generatePositiveFloatField = (initialValue, required): IFormField => {
    return {
        enabled: true,
        initialValue,
        validate: value => {
            var parsed = parseFloat(value)
            return !required || (!isNaN(parsed) && parsed > 0)
        }
    }
}

export const generateFloatField = (initialValue, required): IFormField => {
    return {
        enabled: true,
        initialValue,
        validate: value => {
            var parsed = parseFloat(value)
            return !required || !isNaN(parsed)
        }
    }
}
