
import { useCallback } from 'react';
import useApiData from 'hooks/useApiData';
import useSignalingActions from 'Signals/useSignalingActions';
import ameli from 'Api/ameli';

const channel = ['CustomerNotes']

const actions = (customerId) => ({
    'create': data => ameli.post('/notes/customers', { customer_id: customerId, ...data }),
    'delete': id => ameli.delete(`/notes/${id}`),
    'update': data => ameli.put(`/notes`, data)
})

const useCustomerNoteActions = (customerId) => {
    return useSignalingActions(actions(customerId), channel)
}

export default function useCustomerNotes(customerId) {
    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get(`/notes/customers`, { params: { customer_id: customerId } })
            .then(response => {
                resolve(response.data.map((operation) => {
                    return {
                        ...operation,
                    }
                }))
            })
    }), [customerId])

    const state = useApiData(loadHandler, channel)

    return state
}

export { useCustomerNoteActions }