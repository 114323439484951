import { FormGroup } from "components/form/FormComponents";
import TextInput from "components/input/TextInput";
import IForm from "Forms/IForm";

interface Props {
    form:IForm
}

export default function ContactDetailsForm({ form }: Props) {
    return (
        <FormGroup>
            <TextInput
                name='email'
                value={form.getFieldValue('email')}
                onChange={(event) => form.setFieldValue('email', event.target.value)}
                label='Email'
            />
            <TextInput
                name='website'
                value={form.getFieldValue('website')}
                onChange={(event) => form.setFieldValue('website', event.target.value)}
                label='Website'
            />
        </FormGroup>
    )
}