import styled from 'styled-components';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: page;
    min-height: 0;
    position: relative;
`


export default Page