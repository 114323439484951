import { useMemo } from "react";
import { labelColumn } from "components/table/Columns/ColumnFactory";
import AddressUtility from "./AddressUtility";

export default function useAddressColumns() {
    const columns = useMemo(() => (
        [
            labelColumn("Straße", data => data.street),
            labelColumn("Postleitzahl", data => data.postcode),
            labelColumn("Stadt", data => data.city),
            labelColumn("Stadtteil", data => data.district),
            labelColumn("Geoposition", AddressUtility.getGeopositionLabel),
        ]),
        []
    );

    return columns;
}