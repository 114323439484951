import useKeyListener from "hooks/use-key-listener"
import { useState } from "react"
import InputWrapper from "./InputWrapper"
import styled from 'styled-components';
import { InputWrapperProps } from "components/input/InputWrapper";
import TextareaAutosize from 'react-textarea-autosize';

const InputField = styled(TextareaAutosize)`
    border: none;
    font-size: 1em;
    background-color: transparent;
    padding: 0.5em 0.5em;
    transition: 0.3s;
    font-family: lato;
    width: 100%;
    resize: vertical;

    &:hover {
        border-color: #aaa;
    }

    &:focus {
        border-color: #ce0069;
        outline:none;
`

type TextAreaProps = InputWrapperProps & {
    setValue: (value:string) => any,
    value: string,
}

const TextArea = ({ setValue, value, ...props }:TextAreaProps) => {

    const [focused, setFocused] = useState(false)

    useKeyListener(['Enter'], () => {
        if (setValue) {
            setValue([value, '\n'].join(''))
        }
    })

    return (
        <InputWrapper focused={focused} {...props}>
            <InputField
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </InputWrapper>
    )
}

export default TextArea