type sizes = 'small' | 'medium' | 'large'

const Theme = {
    primaryColor: '#ce0069',
    secondaryColor: '#7a143d',
    whiteColor: 'white',
    subtleColor: '#aaa',
    dangerColor: 'rgb(165 15 15)',
    warningColor: '#d37500',
    successColor: '#4B884D',
    textColor: '#444',
    subtleTextColor: '#898989',

    infoBackgroundColor: '#0968A0',
    infoColor: 'white',

    formActionColor: '#888',
    formActionBackground: 'white',

    vTiny: "0.15em",
    vSmall: "0.35em",
    vMedium: "0.75em",
    vLarge: "1em",

    hTiny: "0.25em",
    hSmall: "0.5em",
    hMedium: "1em",
    hLarge: "1.5em"

}

export default Theme