import { FaTrash } from "react-icons/fa"
import OperationReport from "./OperationReport"
import useOperationReportActions from "./useOperationReportActions"
import HourLabel from "components/labels/hour-label"
import KilometerLabel from "components/labels/kilometer-label"
import Column from "components/Column"
import ListItem from "components/ListView/BetterListItem"
import { useMemo } from "react"
import Action from "Common/Actions/Action"
import Theme from "Theming/Theme"

interface Props  {
    report:OperationReport,
    actions?: Action[]
}


const OperationReportListItem = ({ report, actions }:Props) => {

    const operationReportActions = useOperationReportActions()

    const finalActions = useMemo(() => {
        const result:Action[] = [
            ...actions,
            {
                Icon: <FaTrash/>,
                execute: () => {
                    if(window.confirm("Stundenbeleg wirklich löschen?")) {
                        operationReportActions.dispatch(
                            'delete',
                            report.id
                        )
                    }
                }
            }
        ]

        return result
    }, [actions, report])

    return (
        <ListItem
            title={report.yearMonth.format('MMM YYYY')}
            actions={finalActions}
            bannerColor={Theme.subtleColor}
        >
            <Column>
                <HourLabel small subtle value={report.hours}/>
                <KilometerLabel small subtle value={report.kilometer}/>
            </Column>
        </ListItem>
    )
}

export default OperationReportListItem