import ListView from '../components/ListView/ListView';
import Label from "components/labels/Label";
import LabelRow from "components/labels/LabelRow";

export default function ContactPersonList({ data, onSelect}) {

    return (
        <div> 
            {
                data.length > 0 ? 
                    <ListView
                        data={data}
                        Separator={() => null}
                        listItem={(person) => (
                            <li>
                                <LabelRow onClick={() => onSelect(person)}>
                                    <Label ignoreCursor>{person.name}</Label>
                                    <Label subtle ignoreCursor>({person.relationship_label})</Label>
                                </LabelRow>
                            </li>)}
                    />
                :
                    <Label subtle>-</Label>
            }
        </div>
    );
}