import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Dropdown from "../dropdown/dropdown";

export default function YearSelect(props) {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const startYear = 2017;
        const currentYear = moment().year();
        const newOptions = [];
        
        newOptions.push({value: null, label: "Alle"});
        for(var year = startYear; year <= currentYear; year++) {
            newOptions.push({
                value: year,
                label: year.toString()
            })   
        }

        setOptions(newOptions);
    }, [setOptions])

    return (
        <Dropdown options={options} {...props}></Dropdown>
    )
}