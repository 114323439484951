import Tile from "components/Tiles/Tile";
import Table from "components/table/table";
import useAddressColumns from "./useAddressColumns";
import { useMemo } from "react";
import { useAddresses } from "./AddressApi";
import AddressModal from "./AddressModal";
import useModal from "components/modal/useModal";
import Address from "./Address";

export default function AddressTable() {

    const params = useMemo(() => (
        { unclear: true }
    ), [])
    const addresses = useAddresses(params)
    const modal = useModal<Address>()

    const columns = useAddressColumns()

    if(!addresses) {
        return null
    }

    return (
        <Tile
            title={`Anschriften (${addresses.data?.length})`}
        >
            <AddressModal {...modal}/>
            <Table
                columns={columns}
                data={addresses.data}
                onClick={(address) => modal.select(address)}>
            </Table>
        </Tile>
    )
}