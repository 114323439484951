
import ameli from 'Api/ameli';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Employee from './Employee';

export interface EmployeeGetParams {
    person_id?: number
}

export const useEmployee = (employeeId:number) => {
    return useQuery({
        queryKey: ['employees', employeeId],
        queryFn: () => ameli.get<Employee>(`employees/${employeeId}`).then(res => res.data),
        enabled: isNaN(employeeId) === false
    })
}

export function useEmployees(params: EmployeeGetParams) {
    return useQuery({
        queryKey: ['employees', params],
        queryFn: () => ameli.get<Employee[]>(`employees`, { params }).then(res => res.data),
        initialData: [],
        enabled: params != null
    })
}

export function useEmployeeMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['employees'] })
            queryClient.invalidateQueries({ queryKey: ['persons'] })
        }
    })
}

export const useEmployeeUpdate = () => {
    return useEmployeeMutation<Employee>(
        (data: Employee) => ameli.put('employees', data)
    )
}

export const useEmployeeCreate = () => {
    return useEmployeeMutation<Employee>(
        (data: Employee) => ameli.post('employees', data)
    )
}

export const useEmployeeDelete = () => {
    return useEmployeeMutation<number>(
        (employeeId: number) => ameli.delete(`employees/${employeeId}`)
    )
}
