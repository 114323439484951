import CustomerService from "./CustomerService";
import DataTypes from "Common/Objects/DataTypes";
import { IModel } from "../Models/IModel";
import ServiceSelect from "Services/ServiceSelect";
import ServiceModel from "../Services/ServiceModel";
import { PropertyDefinitions } from "Common/Objects/PropertyDefinition";
import Service from "../Services/Service";
import CustomerSelect from "Customer/CustomerSelect";


const properties:PropertyDefinitions = {
    id: {
        type: 'single',
        label: 'Id',
        primaryKey: true,
        valueType: new DataTypes.Integer({
            allowNull: false,
            unsigned: false
        })
    },
    customerId: {
        type: 'single',
        label: 'Kunde',
        valueType: new DataTypes.Integer({
            allowNull: false,
            unsigned: false
        }),
        required: true,
        renderControl: (field) => {
            return (
                <CustomerSelect
                    key={field.path}
                    label={field.label}
                    value={field.value}
                    onChange={({value}) => {
                        field.setValue(value)
                    }}
                />
            )
        }
    },
    serviceId: {
        type: 'single',
        label: 'Dienstleistung Id',
        valueType: new DataTypes.Integer({
            allowNull: false,
            unsigned: false
        }),
        required: true,
        
    },
    service: {
        ...ServiceModel.objectDefinition,
        renderControl: (field) => {
            return (
                <ServiceSelect
                    key={field.path}
                    label={field.label}
                    value={field.value}
                    onChange={(value ) => {
                        field.setValue(value)
                    }}
                />
            )
        }
    },
    squareMeter: {
        type: 'single',
        label: 'Quadratmeter',
        valueType: new DataTypes.Float({
            allowNull: true,
            unsigned: false
        }),
    },
    details: {
        type: 'single',
        label: 'Details',
        required: false,
        valueType: new DataTypes.Text({
            length: 256
        })
    },
    createdAt: {
        type: 'single',
        label: 'Erstellt am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
    updatedAt: {
        type: 'single',
        label: 'Bearbeitet am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
}

const CustomerServiceModel:IModel<CustomerService> = {
    name: 'customerService',
    labels: ['Gewünschte Leistung', 'Gewünschte Leistungen'],
    objectDefinition: {
        label: '',
        type: 'object',
        properties,
        adjustData: (state) => {
            const service = state.values['service'] as Service
            return {
                enabled: {
                    ...state.enabled,
                    squareMeter: service? service.useSquareMeter : false
                },
                values: {
                    ...state.values,
                    serviceId: service?.id
                }
            }
        }
    },
    apiPath: 'customer_services'
}

export default CustomerServiceModel