import { ReactNode } from "react"
import styled from "styled-components"

const StyledButton = styled.button`
    user-select: none;
    color: ${props =>
        props.color ? props.color :
        'white'
    };
    display: inline-flex;
    background-color: ${props => 
        props.backgroundColor ? props.backgroundColor :
        props.secondary ? '#7d7d7d' : 
        '#ce0069'
    };
    border: none;
    padding: ${props => props.small ? '0.35em' : '0.57em'} 0.75rem;
    border-radius: 4px;
    transition: 0.25s;
    font-family: inherit;
    font-size: ${props => props.small ? '0.8em' : 'inherit'};
    font-weight: inherit;
    flex-grow: ${props => props.grow ? 1 : 0};
    text-wrap: nowrap;

    height: 2.5em;
    justify-content: center;
    align-items: center;

    &:hover {
       cursor: pointer;
       opacity: 0.6;
    }

    &:disabled {
        opacity: 0.3;
    }
`

export interface ButtonProps {
    secondary?:boolean,
    children?: ReactNode,
    onClick?: () => void,
    disabled?: boolean,
    color?:string,
    backgroundColor?:string
}


export default function Button({ secondary = false, ...props }: ButtonProps) {

    return (
        <StyledButton
            type="button"
            {...props}
            secondary={secondary}>
            {props.children}
        </StyledButton>
    )
}