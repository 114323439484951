import Dropdown from "components/dropdown/dropdown"
import { useMemo } from "react"

export default function EmployeeStateSelect(props) {

    const options = useMemo(() => ([
        { label: 'Bewerber', value: null },
        { label: 'Mitarbeiter', value: true }
    ]), [])

    return (
        <Dropdown
            options={options}
            {...props}
            allowNull={false}
            placeholder="Bewerber"
        >
        </Dropdown>
    )
}