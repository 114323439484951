import InvolvedPersonsEdit from "./InvolvedPersons/InvolvedPersonsEdit";
import DatePicker from "components/input/DatePicker";
import TextInput from "components/input/TextInput";
import TimeInput from "components/input/TimeInput";
import Row from "components/Row";
import TextArea from 'components/input/TextArea';
import Spacer from "components/Spacer/Spacer";

export default function UserAppointmentForm({
    formData,
    handleFormChange
}) {

    return (
        <div>
            <TextInput label='Titel' name='label' onChange={handleFormChange} value={formData.label}/>
            <Spacer vSmall/>
            <DatePicker label='Datum' name='date' depracatedChangeHandler={handleFormChange} value={formData.date}/>
            <Spacer vSmall />
            <Row>
                <TimeInput grow label='Beginn' name='start_time' onChange={handleFormChange} value={formData.start_time ?? ''}/>
                <TimeInput grow label='Ende' name='end_time' onChange={handleFormChange} value={formData.end_time ?? ''}/>
            </Row>
            <Spacer vSmall />
            <TextArea label='Beschreibung' minRows={5} name='description' onChange={handleFormChange} value={formData.description} />
            <Spacer vSmall />
            <InvolvedPersonsEdit
                grow
                label='Betroffen'
                name='involved'
                onChange={handleFormChange}
                involvedPersons={formData.involved ?? []} 
            />
        </div>
    );
}