import ameli from "Api/ameli"
import useSignalingActions from "Signals/useSignalingActions"

const channel = ['Vacations']

const actions = {
    'create_rule': data => ameli.post('vacations/rules', data),
    'delete_rule': data => ameli.delete(`vacations/rules/${data}`),
    'update_rule': data => ameli.put(`vacations/rules`, data),
    'create_booking': data => ameli.post('vacations/bookings', data),
    'delete_booking': data => ameli.delete(`vacations/bookings/${data}`),
    'update_booking': data => ameli.put(`vacations/bookings`, data),
}

const useVacationActions = () => {
    return useSignalingActions(actions, channel)
}

export default useVacationActions