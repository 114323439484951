import InputWrapper, { InputWrapperProps } from 'components/input/InputWrapper';
import { useCallback, useMemo } from 'react';
import ReactSelect from 'react-select'
import styled from 'styled-components';
import { useState } from 'react';

const Select = styled(ReactSelect)`
    flex-grow: 1;
`

export type DropdownOption<T> = {
    value:T,
    label:string,
    object?:Object
}

export interface DropdownProps extends InputWrapperProps {
    allowNull?:boolean,
    isMulti?:boolean,
    onChange?:(any) => any,
    optionFactory?:(any) => any,
    data?:any[],
    value?:any,
    options?: DropdownOption<any>[],
    placeholder?:string
}

export default function Dropdown({label=undefined, placeholder='-', allowNull=false, ...props}:DropdownProps) {

    const [focused, setFocused] = useState(false)

    const simulateChangeEvent = (option) => {
        var value = option ? option.value : null
        if(props.isMulti) {
            props.onChange(option)
            return
       }
        if(props.onChange) {
            props.onChange({ ...option, name: props.name, target: { value, name:props.name}});
        }
        if (option && typeof option.onSelected === 'function') {
            option.onSelected();
        }
    }

    const getOptionFactory = useCallback(() => {
        return (
            typeof props.optionFactory === 'function' ?
                props.optionFactory
            :
                (data) => data
        )
    }, [props.optionFactory])

    const generateOptions = useCallback((dataArray) => {
        let factory = getOptionFactory();
        if(!dataArray) {
            return []
        }
        return dataArray.map(data => factory(data));
    }, [getOptionFactory])

    const options = useMemo(() => {
        var finalOptions = []
        if(allowNull) {
            finalOptions.push({
                label: '-',
                value: null
            })
        }
        if(props.options) {
            finalOptions.push(...props.options)
        }
        
        finalOptions.push(...generateOptions(props.data))
        return finalOptions
    }, [props.options, allowNull, generateOptions, props.data])

    const filterValue = () => {
        if(props.isMulti) {
            return props.value
        }
        if (options && options.length > 0) {
            var value = options.map(option => {
                if (props.value != null && option.value === props.value) {
                    return option
                }
                else if(option.options) {
                    return option.options.find(o =>
                        o.value === props.value
                    )
                }
                return null
            });
            return value ?? null
        }
        return null;
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minWidth: '10em',
            transition: '0.25s',
            flex: 1,
            backgroundColor: 'transparent',
            boxShadow: state.isFocused ? null : null,
            border: 'none',
            cursor: 'pointer'
        }),
        valueContainer: (provided) => ({
            ...provided,
            paddingLeft: '0.5em',
        }),
        singleValue: (provided) => ({
            ...provided,
            marginLeft: '0.0em',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 2
        }),
        option: (provided) => ({
            ...provided,
            minHeight: '2em'
        })
    };


    return (
        <InputWrapper optional={props.optional} focused={focused} grow={props.grow} label={label}>
            <Select 
                styles={customStyles}
                noOptionsMessage={() => 'Keine Option gefunden.'}
                placeholder={placeholder}
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                options={options}
                onChange={event => simulateChangeEvent(event)}
                value={filterValue()} 
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#ce0069'
                    }
                })}
                >
            </Select>
        </InputWrapper>
    );
}