import { useMemo } from "react";
import Label from "components/labels/Label";
import useFamilyStatus from "./useFamilyStatus";

interface Props {
    familyStatusId: number
}

export default function FamilyStatusLabel({ familyStatusId }:Props) {

    const { data } = useFamilyStatus()

    const label = useMemo(() => {
        var familyStatus = data?.find(d => d.family_status_id === familyStatusId)
        return familyStatus?.family_status_label || "-"
    }, [data, familyStatusId])

    return (
        <Label>{label}</Label>
    );
}