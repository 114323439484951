import Modal, { ModalFooter } from "components/modal/Modal"
import AssignmentForm from "./AssignmentForm";
import SubmitButton from "Forms/SubmitButton";
import Spacer from "components/Spacer/Spacer";
import useAssignmentForm from "./useAssignmentForm";
import { useAssignmentCreate, useAssignmentDelete, useAssignmentUpdate } from "./AssignmentApi";
import { useQueryClient } from "@tanstack/react-query";

const AssignmentModal = ({selected:assignment, visible, setVisible}) => {

    const form = useAssignmentForm(assignment)
    const isUpdate = assignment && 'id' in assignment
    const title = isUpdate ? "Zuweisung bearbeiten" : "Zuweisung erfassen"
    const queryClient = useQueryClient()

    const apiCallback = () => {
        setVisible(false)
        queryClient.invalidateQueries({ queryKey: ['persons']})
    }

    const create = useAssignmentCreate(apiCallback)
    const update = useAssignmentUpdate(apiCallback)
    const remove = useAssignmentDelete(apiCallback)

    return (
        <Modal title={title} visible={visible} setVisible={setVisible}>
            <Spacer medium>
                <AssignmentForm form={form}/>
                <ModalFooter>
                    <SubmitButton
                        visible={isUpdate}
                        disabled={(!form.fieldsChanged || !form.fieldsValid)}
                        onClick={() => update.mutate(form.data)}>
                        Übernehmen
                    </SubmitButton>
                    <SubmitButton
                        visible={isUpdate}
                        onClick={() => remove.mutate(form.data.id)}>
                        Löschen
                    </SubmitButton>
                    <SubmitButton
                        visible={!isUpdate}
                        disabled={(!form.fieldsValid)}
                        onClick={() => create.mutate(form.data)}>
                        Erfassen
                    </SubmitButton>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

export default AssignmentModal