import { useEffect, useMemo, useRef, useState} from 'react';
import '@tomtom-international/web-sdk-maps/dist/maps.css'
import tt from '@tomtom-international/web-sdk-maps';
import KilometerLabel from 'components/labels/kilometer-label';
import { FaCar, FaPlane, FaWalking } from 'react-icons/fa';
import useRouteData from '../../../components/map/route-data';
import CareLevelFilter from 'Customer/Filter/CareLevelFilter';
import useMarkers from '../../../components/map/markers';
import ReactDOMServer from 'react-dom/server'
import './../../../Map/map.css'

import Spacer from 'components/Spacer/Spacer';
import Row from 'components/Row';
import Label from 'components/labels/Label';
import { FormGroup } from 'components/form/FormComponents';
import styled from 'styled-components'
import * as ttapi from '@tomtom-international/web-sdk-services'
import TextInput from 'components/input/TextInput';
import Button from 'components/Button';
import { formatSeconds } from '../../../utils/string-utility';
import PersonShortInfo from '../../../Persons/PersonShortInfo';
import Page from 'components/Page';
import SideBar from 'Sidebar/SideBar';
import Screen from 'app/Views/Screen';
import ScreenLayout from '../ScreenLayout';
import { usePersons } from 'Persons/PersonApi';
import moment from 'moment';
import PersonSelect from 'Persons/PersonSelect';
import SearchField from 'components/search-field';
import Checkbox from 'components/checkbox/Checkbox';
import Column from 'components/Column';

const IconWrapper = styled.span`
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
    border-bottom-color: ${props => props.color || 'transparent'}
`

const defaultZoom = 10;

export default function MapView() {

    const [searchResult, setSearchResult] = useState(null);
    const [searchInput, setSearchInput] = useState("")
    const [selectedCareLevels, setSelectedCareLevels] = useState([])
    const [displayCustomers, setDisplayCustomers] = useState(true)
    const [displayEmployees, setDisplayEmployees] = useState(true)
    const [query, setQuery] = useState("")

    const customerParams = useMemo(() => ({
        is_customer: true,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
    }), [])
    const { data:customers } = usePersons(customerParams)
    const [selectedCustomer, selectCustomer] = useState(null)
    const displayedCustomers = useMemo(() => {
        if(!displayCustomers) {
            return []
        }

        const adjustedQuery = query.toLowerCase()
        const careLevels = selectedCareLevels.map(c => c.value)
        return customers?.filter(person => {
            if (careLevels.length > 0 && !careLevels.includes(person.care_level)) {
                return false
            }
            return (
                person.contactDetails?.phoneNumbers?.find(number => number.number.includes(adjustedQuery)) ||
                `${person.first_name} ${person.last_name}`.toLowerCase().includes(adjustedQuery) ||
                String(person.id).includes(adjustedQuery)
            )
        })
    }, [query, customers, selectedCareLevels, displayCustomers])

    const employeeParams = useMemo(() => ({
        is_employee: true,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
    }), [])
    const { data: employees } = usePersons(employeeParams)
    const [selectedEmployee, selectEmployee] = useState(null)
    const displayedEmployees = useMemo(() => {
        if (!displayEmployees) {
            return []
        }

        const adjustedQuery = query.toLowerCase()
        const careLevels = selectedCareLevels.map(c => c.value)
        return employees?.filter(person => {
            if (careLevels.length > 0 && !careLevels.includes(person.care_level)) {
                return false
            }
            return (
                person.contactDetails?.phoneNumbers?.find(number => number.number.includes(adjustedQuery)) ||
                `${person.first_name} ${person.last_name}`.toLowerCase().includes(adjustedQuery) ||
                String(person.id).includes(adjustedQuery)
            )
        })
    }, [query, customers, selectedCareLevels, displayEmployees])

    const mapElement = useRef();
    const [map, setMap] = useState(null);


    useMarkers(
        searchResult,
        map,
        {
            markerClass: 'search-marker',
            onClick: (data, marker) => setSearchResult(null),
            popupContent: (data, popup) =>
                `<h3>${data.query}</h3>`
        }
    )

    useMarkers(
        displayedEmployees,
        map,
        {
            markerClass: 'employee-marker',
            onClick: (data) => { selectEmployee(data) },
            accessor: (person) => [person.address?.longitude || 0, person.address?.latitude || 0]
        }
    );

    useMarkers(
        displayedCustomers,
        map,
        {
            markerClass: 'customer-marker',
            onClick: (data) => { selectCustomer(data) },
            accessor: (person) => [person.address?.longitude || 0, person.address?.latitude || 0]
        }
    );

    useMarkers(
        selectedCustomer,
        map,
        {
            markerClass: 'customer-marker active',
            onClick: (data) => { selectCustomer(null) },
            accessor: (person) => [person.address?.longitude || 0, person.address?.latitude || 0],
            popupContent: (data) =>
                ReactDOMServer.renderToString(
                    <Spacer medium>
                        <PersonShortInfo person={data}/>
                    </Spacer>
                )
        }
    );

    useMarkers(
        selectedEmployee,
        map,
        {
            markerClass: 'employee-marker active',
            onClick: (data) => { selectEmployee(null) },
            accessor: (person) => [person.address?.longitude || 0, person.address?.latitude || 0],
            popupContent: (data) =>
                ReactDOMServer.renderToString(
                    <Spacer medium>
                        <PersonShortInfo person={data} />
                    </Spacer>
                )
        }
    );

    useEffect(() => {
        let map = tt.map({
            key: process.env.REACT_APP_TOM_TOM_API_KEY,
            container: mapElement.current,
            center: [7.2, 51.5],
            zoom: defaultZoom,
            language: 'DE'
        });

        setMap(map);

        return () => {
            map.remove()
            selectCustomer(null);
            selectEmployee(null);
        };
    }, [selectCustomer, selectEmployee]);


    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const routeData = useRouteData(start, end, map);


    useEffect(() => {
        setEnd(selectedCustomer && selectedCustomer.address ?
            {
                latitude: selectedCustomer.address.latitude,
                longitude: selectedCustomer.address.longitude
            }
            :
            null
        )
    }, [map, setEnd, selectedCustomer])


    useEffect(() => {
        setStart(selectedEmployee && selectedEmployee.address ?
            {
                latitude: selectedEmployee.address.latitude,
                longitude: selectedEmployee.address.longitude
            }
            :
            null
        )
    }, [selectedEmployee, map, setEnd])

    const searchAddress = (query) => {
        if (query !== '') {
            ttapi.services.geocode({
                key: process.env.REACT_APP_TOM_TOM_API_KEY,
                query: query
            })
                .then(response => {
                    if (response.results.length > 0) {
                        let result = response.results[0];
                        map.flyTo({
                            center: [result.position.lng, result.position.lat],
                            zoom: 12
                        });

                        setSearchResult({
                            query: query,
                            latitude: result.position.lat,
                            longitude: result.position.lng
                        });
                    }
                })
        }
        else {
            setSearchResult(null);
        }
    }

    return (
        <Screen>
            <ScreenLayout.SideBar>

                <SideBar>
                    <Spacer medium>
                        <Column gap='1.5em'>
                            <Column>
                                <Row vCentered>
                                    <TextInput grow label='Suchen' value={searchInput} onChange={(event) => setSearchInput(event.target.value)} />
                                    <Button onClick={() => searchAddress(searchInput)}>
                                        Suchen
                                    </Button>
                                </Row>
                            </Column> 
                            <FormGroup label='Filter'>
                                <SearchField value={query} onChange={(event) => setQuery(event.target.value)} /> 
                                <CareLevelFilter
                                    label='Pflegegrad'
                                    selectedCareLevels={selectedCareLevels}
                                    setSelectedCareLevels={setSelectedCareLevels}
                                />

                                <div>
                                    <Checkbox 
                                        label='Zeige Mitarbeiter'
                                        value={displayEmployees}
                                        onChange={({value}) => setDisplayEmployees(value)}
                                    />
                                    <Checkbox
                                        label='Zeige Kunden'
                                        value={displayCustomers}
                                        onChange={({value}) => setDisplayCustomers(value)}
                                    />
                                </div>
                            </FormGroup> 
                            <FormGroup label='Routenplaner'>
                                <PersonSelect
                                    persons={employees}
                                    label='Mitarbeiter'
                                    onChange={(event) => selectEmployee(employees.find(e => e.id === event.value))}
                                    value={selectedEmployee ? selectedEmployee.id : -1}
                                />
                                <PersonSelect
                                    persons={customers}
                                    label='Kunde'
                                    onChange={(event) => selectCustomer(customers.find(c => c.id === event.value))}
                                    value={selectedCustomer ? selectedCustomer.id : -1}
                                />
                                <div>
                                    <Row>
                                        <IconWrapper>
                                            <FaPlane />
                                        </IconWrapper>
                                        <KilometerLabel value={routeData.air_distance}></KilometerLabel>
                                    </Row>
                                    <Row>
                                        <IconWrapper color='#ce0069'>
                                            <FaCar />
                                        </IconWrapper>
                                        <KilometerLabel value={routeData.drive_distance}></KilometerLabel>
                                        <Label>{formatSeconds(routeData.drive_duration)}</Label>
                                    </Row>
                                    <Row>
                                        <IconWrapper color='#00aa00'>
                                            <FaWalking />
                                        </IconWrapper>
                                        <KilometerLabel value={routeData.walk_distance}></KilometerLabel>
                                        <Label>{formatSeconds(routeData.walk_duration)}</Label>
                                    </Row>
                                </div>
                            </FormGroup>                
                        </Column>
                    </Spacer>             
                </SideBar>
                <Page>
                    <div
                        ref={mapElement}
                        style={{ position: 'relative', flexGrow: 1, minHeight: "600px", minWidth: '600px' }}>
                    </div>
                </Page>
            </ScreenLayout.SideBar>
        </Screen>
    )
}