import { useQuery } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Title from "./Title"

const useTitles = () => {
    return useQuery({
        queryKey: ['titles'],
        queryFn: () => ameli.get<Title[]>('titles').then(res => res.data)
    })
}

export default useTitles