import NumberFormat from "react-number-format";
import InputWrapper from "./InputWrapper";
import styled from 'styled-components';
import { useState } from 'react';

const InputField = styled(NumberFormat)`
    border: none;
    font-size: 1em;
    line-height: 1em;
    transition: 0.3s;
    background-color: transparent;
    padding: 0;
    padding-left: 0.5em;
    font-family: lato;
    height: 36px;
    flex-grow: 1;

    &:hover {
        border-color: #aaa;
    }

    &:focus {
        border-color: #ce0069;
        outline:none;
`

export default function BetterNumberFormat({grow=undefined, label=undefined, ...props}) {

    const [focused, setFocused] = useState(false)

    function onValueChange(value) {
        if (value.value !== props.value) {
            if (typeof (props.onValueChange) === 'function') {
                props.onValueChange(value);
            }
            else if (typeof (props.onChange) === 'function') {
                props.onChange({
                    currentTarget: {
                        name: props.name,
                        value: value.value
                    }
                });
            }
        }
    }

    return (
        <InputWrapper focused={focused} label={label} grow={grow}>
            <InputField
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onValueChange={onValueChange}
                onChange={() => { }}>
            </InputField>
        </InputWrapper>
    )
}