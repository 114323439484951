import ameli from "Api/ameli"
import useSignalingActions from "Signals/useSignalingActions"

const actionChannel = ['OperationReports']

const actions = {
    'create': data => ameli.post('operation_reports', data),
    'delete': data => ameli.delete(`operation_reports/${data}`),
}

const useOperationReportActions = () => {
    return useSignalingActions(actions, actionChannel)
}

export default useOperationReportActions