import moment from "moment"
import { useMemo } from "react"

const useYearMonth = (year, month) => {
    const state = useMemo(() => {
        if(!month || !year) {
            return {
                yearMonth: moment().startOf('month')
            }
        }
        return {
            yearMonth: moment().year(year).month(month - 1).startOf('month')
        }

    }, [year, month])

    return {
        state,
        month,
        year
    }
}

export default useYearMonth