import DateLabel from "components/labels/DateLabel";
import TimeLabel from "components/labels/time-label";
import React, { useMemo } from "react";
import InvolvedPersonsCell from "./InvolvedPersons/InvolvedPersonsCell";
import TableCell from "components/table/Cells/TableCell";
import Label from "components/labels/Label";


export default function useUserAppointmentColumns(props) {
    const columns = useMemo(() =>
        [
            {
                Header: 'Titel',
                accessor: 'label',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label allowWhiteSpace>{value}</Label>
                        )}
                    />
                ),
                maxWidth: '12em'
            },
            {
                Header: 'Datum',
                accessor: d => new Date(d.date),
                sortType: 'datetime',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
                maxWidth: '7em'
            },
            {
                Header: 'Uhrzeit',
                accessor: d => [d.start_time, d.end_time],
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <TimeLabel startTime={value[0]} endTime={value[1]}></TimeLabel>
                        )}
                    />
                ),
                maxWidth: '6em'
            },
            {
                Header: 'Beschreibung',
                accessor: 'description',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label allowWhiteSpace>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Betroffen',
                accessor: d => d.involved,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => {
                            if (value) {
                                return <InvolvedPersonsCell persons={value} />
                            }
                            return null;
                        }}
                    />
                ),
                maxWidth: '14em'
            },
        ],
        []
    );

    return columns
}