import ListView from "components/ListView/ListView"
import NoteCard from "Notes/NoteListItem";

const OperationNoteList = ({data, onClick, ...props}) => {
    
    return (   
        <ListView 
            {...props}
            data={data} 
            listItem={(note) => (
                <NoteCard
                    note={note}
                    onClick={onClick}
                />
            )} 
        />
    )
}

export default OperationNoteList