import AssignmentModal from "Assignments/AssignmentModal"
import React, { useMemo } from "react"
import useModal, { IModal } from "components/modal/useModal";
import AbsenceModal from "Absences/AbsenceModal";
import VacationRuleModal from "Vacations/Rules/VacationRuleModal";
import useVacationActions from "Vacations/useVacationActions";
import VacationBookingModal from "Vacations/Bookings/VacationBookingModal";
import EventModal from "Events/EventModal";
import VacationRequestModal from "Vacations/Requests/VacationRequestModal";
import BillModal from "Bills/BillModal";
import PhoneListModal from "PhoneNumbers/PhoneListModal";
import PhoneModal from "PhoneNumbers/PhoneModal";
import { useHealthInsuranceActions } from "HealthInsurances/HealthInsuranceContext";
import HealthInsuranceModal from "HealthInsurances/HealthInsuranceModal";
import Event from "Events/Event";
import Person from "Persons/Person";
import PersonModal from "Persons/PersonModal";
import ContactDetails from "ContactDetails/ContactDetails";
import ContactDetailsModal from "ContactDetails/ContactDetailsModal";
import VacationRequest from "Vacations/Requests/VacationRequest";

interface ContextValue {
    assignmentModal?:IModal<any>,
    absenceModal?: IModal<any>,
    billModal?: IModal<any>,
    contactDetailsModal?: IModal<ContactDetails>,
    eventModal?: IModal<Event>,
    healthInsuranceModal?: IModal<any>,
    phoneListModal?: IModal<any>,
    phoneModal?: IModal<any>,
    vacationRuleModal?: IModal<any>,
    vacationBookingModal?: IModal<any>,
    vacationRequestModal?: IModal<any>,
    personModal?:IModal<Person>,
}

const ModalContext = React.createContext<ContextValue>({})

const ModalProvider = ({ children, ...props }) => {

    const assignmentModal = useModal<any>()

    const absenceModal = useModal<any>()

    const billModal = useModal<any>()

    const eventModal = useModal<Event>()

    const contactDetailsModal = useModal<ContactDetails>()

    const healthInsuranceModal = useModal<any>()
    const dispatchHealthInsuranceAction = useHealthInsuranceActions()

    const phoneListModal = useModal<any>(-1)
    const phoneModal = useModal()

    const personModal = useModal<any>()

    const vacationActions = useVacationActions()
    const vacationRuleModal = useModal<any>()
    const vacationBookingModal = useModal<any>()

    const vacationRequestModal = useModal<VacationRequest>()

    const value = useMemo(() => ({
        assignmentModal,
        absenceModal,
        billModal,
        contactDetailsModal,
        eventModal,  
        healthInsuranceModal,
        phoneListModal,
        phoneModal,
        personModal,
        vacationRuleModal,
        vacationBookingModal,
        vacationRequestModal,
    }), [
        assignmentModal,
        absenceModal,
        billModal,
        contactDetailsModal,
        eventModal,
        healthInsuranceModal,
        phoneListModal,
        phoneModal,
        personModal,
        vacationRuleModal,
        vacationBookingModal,
        vacationRequestModal
    ])

    return (
        <ModalContext.Provider value={value} {...props}>
            { children }
            <AssignmentModal {...assignmentModal}/>
            <AbsenceModal {...absenceModal}/>
            <BillModal {...billModal} />
            <ContactDetailsModal {...contactDetailsModal}/>
            <EventModal {...eventModal}/>
            <HealthInsuranceModal {...healthInsuranceModal} dispatchAction={dispatchHealthInsuranceAction.dispatch} />
            <PhoneListModal {...phoneListModal}/>
            <PhoneModal {...phoneModal}/>
            <PersonModal {...personModal}/>
            <VacationRuleModal {...vacationRuleModal} {...vacationActions}/>
            <VacationBookingModal {...vacationBookingModal} {...vacationActions}/>
            <VacationRequestModal { ...vacationRequestModal}/>
        </ModalContext.Provider>
    )
}

export { ModalContext, ModalProvider }