import axios from "axios";

const ameli = axios.create({
    baseURL: "https://www.ameli-mhuh.de/api",
    //baseURL: "http://localhost:3001",
    headers: {
        "Content-type": "application/json"
    }
});

ameli.interceptors.request.use(
    async (config) => {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyMSwiaWF0IjoxNjQ5MzMzOTg5fQ.LdHUe6_WtNx_M3oxa593FdVidnuYtZ2Cgl7z_pEiZL0'
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.log(error)
        return Promise.reject(error);
    }
)

export default ameli