import { useMemo } from "react";
import { BudgetGetParams, useBudgets } from "./BudgetApi";
import Budget from "./Budget";

export type BudgetGroup = {
    id:string,
    budgets: Budget[],
    budget_type_id: number,
    budget_type_label: string,
    budget_decline_date: string,
    declining: boolean,
    declined: boolean,
    budget_amount:number,
    remaining_amount: number
}

export default function useGroupedBudgets(params:BudgetGetParams) {
    const budgets = useBudgets(params)

    const initializeGroup = (id:string, budget:Budget) : BudgetGroup => {
        return {
            id,
            budget_type_id: budget?.budget_type_id,
            budget_type_label: budget?.budget_type_label,
            budget_decline_date: budget?.budget_decline_date,
            declining: budget?.declining,
            declined: budget?.declined,     
            budgets: [],
            budget_amount: budget?.budget_amount,
            remaining_amount: budget?.remaining_amount
        }
    }

    const data = useMemo(() => {
        if(!budgets.data) {
            return []
        }

        var group_fields = ['budget_decline_date', 'budget_type_id']
        var grouped_budgets:BudgetGroup[] = Object.values(budgets.data.reduce((groups, budget) => {
            var group_values = group_fields.map(k => budget[k])
            const key = group_values.join('|');
            (groups[key] ??= initializeGroup(key, budget)).budgets.push(budget);
            return groups;
        }, {}))
        .map((group:BudgetGroup) => ({
            ...group,
            budget_amount: group.budgets.reduce((sum, budget) => sum + budget.budget_amount, 0),
            remaining_amount: group.budgets.reduce((sum, budget) => sum + budget.remaining_amount, 0),
        }))

        return grouped_budgets
    }, [budgets.data])

    return {
        ...budgets,
        data
    }
}