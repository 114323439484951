import { useMemo } from "react";
import useFamilyStatus from "./useFamilyStatus";
import Dropdown from "components/dropdown/dropdown";

export default function FamilyStatusSelect(props) {

    const { data } = useFamilyStatus()

    const options = useMemo(() => {
        return data?.map(familyStatus => ({
            value: familyStatus.family_status_id,
            label: familyStatus.family_status_label
        }))
    }, [data])

    return (
        <Dropdown 
            options={options}
            {...props}>
        </Dropdown>
    );
}
