
import Dropdown from 'components/dropdown/dropdown';

export default function CareLevelFilter({selectedCareLevels, setSelectedCareLevels, ...props}) {


    const options = [
        { value: 0, label: 'Ohne' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ]

    const handleChange = (selection) => {
        setSelectedCareLevels(selection)
    }

    return (
        <Dropdown 
            value={selectedCareLevels}
            options={options} 
            isMulti={true}
            placeholder="Alle Pflegegrade"
            {...props}   
            onChange={handleChange}  
        />
    )
}