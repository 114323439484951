import moment from "moment"
import businessMoment from 'moment-business-days'

export const localizeDateString = (string, fallback = '') => {
    return string ? moment(string).format('DD.MM.YY') : fallback;
}

export const workDaysBetween = (startDate, endDate) => {
    if(!startDate || !endDate) {
        return 0
    }
    startDate = businessMoment(startDate)
    endDate = businessMoment(endDate)
    return endDate.businessDiff(startDate, true) + 1 
}

export const toDate = (value) => {
    if (!value) {
        return null
    }
    return moment(value).format('YYYY-MM-DD')
}

export const toTime = (value) => {
    if (!value) {
        return null
    }
    return moment(value).format('HH:mm')
}

export const toDateTime = (date, time) => {
    if (!date || !time) {
        return null
    }
    var format = 'YYYY-MM-DD HH:mm'
    return moment(`${date} ${time}`, format).format(format)
}

export const minDate = (dateA, dateB) => {
    if (!dateA) {
        return dateB ? moment(dateB) : null
    }
    if (!dateB) {
        return dateA ? moment(dateA) : null
    }
    return moment.min(moment(dateA), moment(dateB))
}

export const maxDate = (dateA, dateB) => {
    if (!dateA) {
        return dateB ? moment(dateB) : null
    }
    if (!dateB) {
        return dateA ? moment(dateA) : null
    }
    return moment.max(moment(dateA), moment(dateB))
}

export const formatTimeString = (string, fallback = "") => {
    return string ? moment(string, "HH:mm").format("HH:mm") : fallback
}

export const localizeDateTimeString = (string, fallback = "") => {
    return string ? moment(string).format("DD.MM.YYYY HH:mm") : fallback
}

export const formatTimePeriod = (from, to, fallback = "") => {
    const fromString = formatTimeString(from, null);
    const toString = formatTimeString(to, null);

    if (fromString != null && toString != null) {
        return `${fromString}-${toString}`
    }
    if (fromString != null) {
        return `Ab ${fromString}`
    }
    if (toString != null) {
        return `Bis ${toString}`
    }
    return fallback
}

export const getMonthName = (monthNumber) => {
    return moment().set('month', monthNumber - 1).format('MMMM');
}

export const formatDateString = (string, fallback = '') => {
    return string ? moment(string).format("YYYY-MM-DD") : fallback
}

const timeToNumber = t => {
    return t ? t.replace(/:/g, '') : 0
}

const dateToNumber = d => {
    return d.replace(/-/g, '')
}

export const compareTimeStrings = (a, b) => {
    return (timeToNumber(a) - timeToNumber(b));
}

export const compareDateStrings = (a, b) => {
    return (dateToNumber(a) - dateToNumber(b));
}


export const formatPeriodString = (start, end, fallback = '', format = 'DD.MM.YY') => {
    if (!start && !end) {
        return fallback
    }

    if (!start) {
        return `Bis ${moment(end).format(format)}`
    }

    if (!end) {
        return `Ab ${moment(start).format(format)}`
    }


    start = moment(start)
    end = moment(end)
    var startFormat = format
    var endFormat = format

    if (start.isSame(end, 'day')) {
        endFormat = endFormat.replaceAll('D', '')
        endFormat = endFormat.replaceAll('d', '')
        endFormat = endFormat.replaceAll('M', '')
        endFormat = endFormat.replaceAll('Y', '')
        endFormat = endFormat.replaceAll('.', '')
        endFormat = endFormat.replaceAll(',', '')
        endFormat = endFormat.trim()
    }

    if (endFormat === '') {
        return start.format(startFormat)
    }

    return `${start.format(startFormat)} - ${end.format(endFormat)}`
}