import ConditionalView from "components/Decorator/ConditionalView"
import { Tag } from "Tags/Tag"
import ListItem from "components/ListView/ListItem"
import Spacer from "components/Spacer/Spacer"
import Label from "components/labels/Label"
import { useMemo } from "react"
import TagContainer from "Tags/TagContainer"
import EventDemandTag from "./EventDemandTag"
import EventUtility from "./EventUtility"
import { usePerson } from "Persons/PersonApi"
import Event from "./Event"


export interface EventListItemProps {
    event:Event,
    periodFormat?:string,
    hideEmployee?:boolean,
    hideCustomer?:boolean
}

const EventListItem = ({ event, hideEmployee = false, hideCustomer = false, periodFormat = 'DD.MM.YYYY HH:mm', ...props }) => {

    const employeeId = useMemo(() => {
        return event?.employee_id || event?.creator_id
    }, [event])

    const { data: customer } = usePerson(event?.customer_id)
    const { data: employee } = usePerson(employeeId)

    const periodString = useMemo(() => {
        return EventUtility.getPeriodLabel(event, periodFormat)
    }, [event, periodFormat])

    return (
        <ListItem displayLine={true} {...props}>
            <Spacer vSmall>
                <TagContainer>
                    <ConditionalView condition={event.confirmed == true}>
                        <Tag success>Bestätigt</Tag>
                    </ConditionalView>
                    <ConditionalView condition={event.confirmed == false}>
                        <Tag>Abgesagt</Tag>
                    </ConditionalView>
                    <ConditionalView condition={!EventUtility.hasEmployee(event)}>
                        <EventDemandTag event={event} />
                    </ConditionalView>
                    <ConditionalView condition={EventUtility.hasEmployee(event)}>
                        <ConditionalView condition={EventUtility.isSubstituted(event)}>
                            <Tag>Vertretung</Tag>
                        </ConditionalView>
                    </ConditionalView>
                </TagContainer>

                <ConditionalView condition={!hideCustomer && customer}>
                    <Label>{`${customer?.first_name} ${customer?.last_name}`}</Label>
                </ConditionalView>
                <ConditionalView condition={!hideEmployee && employee}>
                    <Label>{`${employee?.first_name} ${employee?.last_name}`}</Label>
                </ConditionalView>
                <Label small subtle>{periodString}</Label>
            </Spacer>
        </ListItem>
    )
}

export default EventListItem