import Label from 'components/labels/Label'
import moment from 'moment'
import { useMemo } from 'react'
import styled from 'styled-components'

const SearchArea = styled.div`
`

const Day = styled.div`
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0em;
`

const Weekday = ({ date }) => {

    const [ formattedDate, label] = useMemo(() => {
        if(!date) {
            return ["XX.XX.", "Unbekannt"]
        }
        var dateObject = moment(date)
        return [
            dateObject.format('DD.MM'),
            dateObject.format('dddd')
        ]
    }, [date])

    return (
        <Day>
            <Label subtle>{formattedDate}</Label>
            <Label bold>{label}</Label>
        </Day>
    )
}

const EmployeeScheduleHeader = ({ dates}) => {
    return (
        <>
            <SearchArea></SearchArea>
            {
                dates.map((date, index) => (
                    <Weekday 
                        date={date} 
                        key={index}
                    />
                ))
            }
        </>
    )
}

export default EmployeeScheduleHeader