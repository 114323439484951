import Label from "components/labels/Label";
import TableCell from "components/table/Cells/TableCell";
import Theme from "Theming/Theme";

const { default: CurrencyLabel } = require("components/labels/currency-label");
const { default: DateLabel } = require("components/labels/DateLabel");
const { default: HourLabel } = require("components/labels/hour-label");
const { useMemo } = require("react");
const React = require("react");

export default function useCustomerBudgetColumns(props) {

    const columns = useMemo(() =>
        [
            {
                Header: 'Typ',
                accessor: d => d.budget_type_label,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Betrag',
                accessor: d => d.budget_amount,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value}></CurrencyLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var sum = 0;
                    data.flatRows.forEach(row => {
                        sum += parseFloat(row.original.budget_amount);
                    });
                    return (
                        <CurrencyLabel bold value={sum}></CurrencyLabel>
                    )
                }
            },
            {
                Header: 'Verbleibend',
                accessor: d => d.remaining_amount,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value}></CurrencyLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var remainingSum = 0;
                    data.flatRows.forEach(row => {
                        remainingSum += parseFloat(row.original.remaining_amount);
                    });
                    return (
                        <CurrencyLabel bold value={remainingSum}></CurrencyLabel>
                    )
                }
            },
            {
                Header: 'Stunden',
                accessor: d => parseFloat(d.remaining_amount),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <HourLabel value={value / 35.0}></HourLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var hourSum = 0;
                    data.flatRows.forEach(row => {
                        hourSum += parseFloat(row.original.remaining_amount);
                    });
                    return (
                        <HourLabel bold value={hourSum / 35.0}></HourLabel>
                    )
                }
            },
            {
                Header: 'Gültig ab',
                accessor: d => d.budget_begin_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Verfällt am',
                accessor: d => d,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => {
                            var color = Theme.textColor
                            var bold = false

                            if (value.declined) {
                                color = 'red'
                                bold = true
                            }

                            return (
                                <DateLabel color={color} bold={bold} date={value.budget_decline_date} />
                            )
                        }}
                    />
                ),
            },
            {
                Header: 'Anmerkung',
                accessor: d => d.budget_note,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
        ],
        []
    );

    return { columns };
}