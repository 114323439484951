import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import SubmitButton from "Forms/SubmitButton";
import Spacer from "components/Spacer/Spacer";
import useEventForm from "./useEventForm";
import EventForm from "./EventForm";
import Row from "components/Row";
import Column from "components/Column";
import BudgetSelectionControl from "Budgets/BudgetSelectionControl";
import BudgetCreateButton from "Budgets/BudgetCreateButton";
import FlexFill from "components/FlexFill";
import Label from "components/labels/Label";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import CurrencyLabel from "components/labels/currency-label";
import EventUtility from "./EventUtility";
import useKeyListener from "hooks/use-key-listener";
import useGroupedBudgets, { BudgetGroup } from "Budgets/useGroupedBudgets";
import { useEventCreate, useEventDelete, useEvents, useEventUpdate } from "./EventApi";
import Event from "./Event";

interface Props extends IModalProps<Event> {}

export default function EventModal({
    selected: event,
    visible,
    setVisible,
    ...props
}:Props) {

    const form = useEventForm(event)
    const isUpdate = 'id' in form.data;
    const title = isUpdate ? "Termin bearbeiten" : "Termin erfassen";
    const [deselectedBudgetGroups, setDeselectedBudgetGroups] = useState([])
    const defaultFocus = useRef(null);
    const updateEvent = useEventUpdate()
    const createEvent = useEventCreate()
    const deleteEvent = useEventDelete()

    const budgetParams = useMemo(() => {
        return {
            owner_id: form.data.customer_id ?? -1,
            period_start: form.data.date ?? moment().format('DD-MM-YYYY'),
            period_end: form.data.date ?? moment().format('DD-MM-YYYY')
        }
    }, [form.data.customer_id, form.data.date])

    const budgetGroups = useGroupedBudgets(budgetParams);

    useEffect(() => {
        setDeselectedBudgetGroups([])
    }, [form.data.customer_id, setDeselectedBudgetGroups])

    const deselectedBudgetIds=  useMemo(() => {
        return deselectedBudgetGroups?.reduce((ids, group:BudgetGroup) =>{
            return [
                ...ids,
                ...group.budgets.map(budget => budget.budget_id)
            ]
        }, [])
    }, [deselectedBudgetGroups])

    const handleCreate = () => {
        createEvent.mutate(
            { ...form.data, book_smart: true, deselected_budgets: deselectedBudgetIds }, 
            {
                onSettled: () => {
                    defaultFocus.current.focus()
                    form.setFieldValue('hours', 0)
                    form.setFieldValue('kilometer', 0)
                    budgetGroups.refetch()
                }
            }
        )
    }

    const handleUpdate = () => {
        updateEvent.mutate(
            { ...form.data, book_smart: true, deselected_budgets: deselectedBudgetIds },
            {
                onSettled: () => {
                    setVisible(false)
                    budgetGroups.refetch()
                }
            }
        )
    }

    const handleDelete = () => {
        deleteEvent.mutate(
            form.data.id,
            {
                onSettled: () => {
                    setVisible(false)
                }
            }
        )
    }

    useKeyListener(['Enter', 'NumpadEnter'], () => {
        if(!form.fieldsValid) {
            return
        }
        if (isUpdate) {
            handleUpdate();
        }
        else {
            handleCreate();
        }
    })

    useKeyListener(['Delete'], () => {
        if (isUpdate) {
            handleDelete();
        }
    })

    return (
        <Modal
            selected={event}
            visible={visible}
            setVisible={setVisible}
            title={title}
            {...props}
        >
            <Spacer medium>
                <Row gap='1em'>
                    <Column>
                        <EventForm form={form} defaultFocus={defaultFocus} />
                    </Column>
                    <Column gap='0.5em'>
                        <BudgetSelectionControl
                            groups={budgetGroups.data}
                            deselected={deselectedBudgetGroups}
                            setDeselected={setDeselectedBudgetGroups}
                        />
                        <BudgetCreateButton
                            ownerId={form.data.customer_id}
                            typeId={7}
                            date={form.data.date}
                        >
                            40% Pflegesachleistung nutzen
                        </BudgetCreateButton>
                        <FlexFill/>
                        <BillComponent 
                            customerId={form.data.customer_id} 
                            date={form.data.date} 
                        />
                    </Column>
                </Row>
                <Spacer large></Spacer>
                <ModalFooter>
                    {
                        isUpdate ?
                            <>
                                <SubmitButton
                                    disabled={!form.fieldsValid}
                                    onClick={() => handleUpdate()}
                                >
                                    Übernehmen
                                </SubmitButton>
                                <SubmitButton
                                    onClick={() => handleDelete()}
                                >
                                    Löschen
                                </SubmitButton>
                            </>
                            :
                            <SubmitButton
                                disabled={!form.fieldsValid}
                                onClick={() => handleCreate()}
                            >
                                Erstellen
                            </SubmitButton>
                    }
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

const BillComponent = ({ customerId, date }) => {
    const [billDate, params] = useMemo(() => {
        if (!customerId || !date) {
            return [
                null,
                null
            ]
        }
        return [
            moment(date),
            {
                period_start: moment(date).startOf('month').startOf('day').format('YYYY-MM-DD HH:mm'),
                period_end: moment(date).endOf('month').endOf('day').format('YYYY-MM-DD HH:mm'),
                customer_id: customerId
            }
        ]
    }, [customerId, date])
    const { data: events } = useEvents(params)

    if (!billDate) {
        return null
    }

    return (
        <div>
            <Label large bold>{`Rechnungsbetrag für ${billDate.format('MMMM YYYY')}`}</Label>
            <CurrencyLabel
                value={events?.filter(event => event.confirmed).reduce((sum, event) => sum + EventUtility.getCost(event), 0.0)}
            />
        </div>
    )
}