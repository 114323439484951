import { useQuery } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Nationality from "./Nationality"

const useNationalities = () => {
    return useQuery({
        queryKey: ['nationalities'],
        queryFn: () => ameli.get<Nationality[]>("/nationalities").then(res => res.data)
    })
}

export default useNationalities