import { useQuery } from '@tanstack/react-query';
import ameli from 'Api/ameli';
import Booking from './Booking';

export interface BookingGetParams {
    bill_id: number
}

export default function useBookings(params:BookingGetParams) {

    return useQuery({
        queryKey: ['bookings', params],
        queryFn: () => ameli.get<Booking[]>('/bookings', { params }).then(res => res.data),
        enabled: params != null
    })
}