import Dropdown from "components/dropdown/dropdown";
import { useContext, useMemo } from "react";
import { EmployeeContext } from "./EmployeeContext";

export default function EmployeeSelect({placeholder='-', ...props}) {

    const { active } = useContext(EmployeeContext)

    const options = useMemo(() => {
        var result = active
            .map((employee) => ({
                value: employee.id,
                label: `${employee.last_name}, ${employee.first_name}`
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        return [
            {
                value: undefined,
                label: placeholder
            },
            ...result
        ]
    }, [active, placeholder])

    return (
        <Dropdown 
            placeholder={placeholder}
            options={options}
            {...props}
        />
    )
}