import styled from 'styled-components'
import { Tag } from './Tag'
import Theme from 'Theming/Theme'

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

export type TagProps = {
    text: string,
    color?: string,
    backgroundColor?: string
}

export interface TagContainerProps {
    children?: React.ReactNode,
    tags?: TagProps[]
}

export default function TagContainer({ children, tags }: TagContainerProps) {

    if(!tags || tags.length === 0) {
        return null
    }

    return (
        <Container>
            { children }
            { tags?.map(({ text, color=Theme.whiteColor, backgroundColor=Theme.secondaryColor}, index) => (
                <Tag 
                    color={color} 
                    backgroundColor={backgroundColor}
                    key={index}
                >
                    { text }
                </Tag>
            ))}
        </Container>
    )
}