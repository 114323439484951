import React, { useContext, useMemo } from "react";
import { TabContext } from "./TabContext";
import styled, { ThemeProvider} from 'styled-components';
import Label from "components/labels/Label";

const StyledTabList = styled.div`
    display: flex;
    flex-direction: row;
`

const TabFiller = styled.div`
    flex-grow: 1;
    border-bottom: ${props => props.theme.borderWidth} solid ${props => props.theme.inactiveBorderColor};
`

const StyledTab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: ${props => props.theme.flex};
    padding: ${props => props.theme.padding};
    background-color: ${props => props.active ? props.theme.activeBackgroundColor : props.theme.inactiveBackgroundColor};
    border: 0px solid transparent;
    border-top-width: ${props => props.theme.borderWidth};
    border-bottom-width: ${props => props.theme.borderWidth};
    border-bottom-color: ${ props => props.active ? props.theme.activeBorderColor : props.theme.inactiveBorderColor};
    font-weight: 800;
    transition: 0.25s;

    ${Label} {
        color: ${ props => props.active ? props.theme.activeColor: props.theme.inactiveColor};
        pointer-events: none;
    }

    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.hoverBackgroundColor};
        border-bottom-color: ${props => props.theme.hoverBorderColor};
    }
`

const defaultTheme = {
    borderWidth: '3px',
    padding: '0.5rem 1em',
    flex: 0,

    activeBackgroundColor: 'transparent',
    activeBorderColor: '#ce0069',
    activeColor: '#363636',

    inactiveBackgroundColor: 'transparent',
    inactiveBorderColor: '#ddd',
    inactiveColor: '#bbb',

    hoverBackgroundColor: '#eee',
    hoverBorderColor: '#ce0069'
    
}

const SidebarTheme = {
    borderWidth: '2px',
    padding: '0.5rem 1em 0.75em 1em',
    flex: 1,

    activeBackgroundColor: 'transparent',
    activeBorderColor: '#f7f7f7',
    activeColor: '#f7f7f7',

    inactiveBackgroundColor: 'transparent',
    inactiveBorderColor: '#5E1130',
    inactiveColor: '#5E1130',

    hoverBackgroundColor: 'transparent',
    hoverBorderColor: '#f7f7f7'

}

const Tab = ({ label, id}) => {

    const { navigate, selected } = useContext(TabContext) 
    
    const active = useMemo(() => {
        return id === selected
    }, [id, selected])

    return (
        <StyledTab onClick={() => navigate(id)} active={active}>
            <Label medium>{label}</Label>
        </StyledTab>
    )
}

const TabList = ({theme=defaultTheme}) => {

    const { tabs } = useContext(TabContext)

    return (
        <ThemeProvider theme={theme}>
            <StyledTabList>
                {
                    tabs.map((tab, index) => (
                        <Tab key={tab.id} label={tab.label} id={tab.id}/>
                    ))
                }
                <TabFiller/>
            </StyledTabList>
        </ThemeProvider>
    )
}

const TabPanel = ({active, children}) => {

    if (active) {
        return (
            <>
                { children }
            </>
        )
    }

    return null
}

export { TabList, TabPanel, SidebarTheme }