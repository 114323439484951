import { useSignalEmitter } from './useSignals';
import { useCallback, useContext, useReducer } from 'react';
import { ErrorContext } from '../Errors/ErrorContext';

const reducer = (state, action) => {
    switch (action.type) {
        case 'add_promise':
            return {
                promises: [...state.promises, action.payload],
                isPending: true
            }
        case 'remove_promise':
            var newPromises = state.promises.filter(p => p !== action.payload)
            return {
                promises: newPromises,
                isPending: newPromises.length > 0
            }
        default:
            return state
    }
}

const useSignalingActions = (actions, channel) => {
    const [state, dispatch] = useReducer(reducer, {
        promises: [],
        isPending: false
    })
    const emitSignal = useSignalEmitter()
    const { writeError } = useContext(ErrorContext) || {}

    const handleError = useCallback((error) => {
        if(writeError) {
            writeError(error)
        }
    }, [writeError])

    const dispatchAction = useCallback((key, ...args) => new Promise((resolve, reject) => {
        const getAction = (key) => {
            if (key in actions) {
                return actions[key]
            }
            return Promise.resolve()
        }

        const promise = getAction(key)(...args)
            .then(response => {
                return emitSignal(channel)
                    .then(() => resolve(response))
            })
            .catch(error => {
                handleError(error)
                reject(error)
            })
            .finally(() => {
                dispatch({
                    type: 'remove_promise',
                    payload: promise
                })
            })

        dispatch({
            type: 'add_promise',
            payload: promise
        })

        return promise
    }), [actions, channel, emitSignal, handleError])

    return { isPending: state.isPending, dispatch:dispatchAction }
}

export default useSignalingActions