import { generateDateField, generatePositiveFloatField, generatePositiveIntegerField } from "Forms/formFieldFactory"
import { useMemo } from "react"

const useVacationBookingFields = (booking) => {

    const fields = useMemo(() => {

        return {
            id: {
                initialValue: booking?.id
            },
            employee_id: generatePositiveIntegerField(booking?.employee_id, true),
            absence_id: generatePositiveIntegerField(booking?.absence_id, false),
            charged_year: generatePositiveIntegerField(booking?.charged_year, true),
            period_start: generateDateField(booking?.period_start, true),
            period_end: generateDateField(booking?.period_end, true),
            days: generatePositiveFloatField(booking?.days, true),
            label: {
                initialValue: booking?.label
            }
        }
    }, [booking])

    return fields
}

export default useVacationBookingFields