import { generateFloatField, generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory"
import useForm from "Forms/useForm"
import useMapSearch from "Map/useMapSearch"
import { useEffect, useMemo } from "react"


export default (data) => {
    const fields = useMemo(() => ({
        'id': generatePositiveIntegerField(data?.id, false),
        'street': generateTextField(data?.street, false),
        'postcode': generateTextField(data?.postcode, false),
        'city': generateTextField(data?.city, false),
        'district': generateTextField(data?.district, false),
        'latitude': generateFloatField(data?.latitude, false),
        'longitude': generateFloatField(data?.longitude, false),
    }), [data])

    const form = useForm(fields)

    const mapSearchQuery = useMemo(() => {
        var query = [
            form.data.street,
            form.data.postcode,
            form.data.city
        ].join(' ')

        return query
    }, [form.data])

    const mapSearch = useMapSearch(mapSearchQuery)
    const mapSearchResult = mapSearch.data || null

    useEffect(() => {
        if (!mapSearchResult) {
            return
        }
        form.setFieldValue('district', mapSearchResult?.address?.municipalitySubdivision)
        form.setFieldValue('latitude', mapSearchResult?.position?.lat)
        form.setFieldValue('longitude', mapSearchResult?.position?.lng)
    }, [mapSearchResult])

    return form
}