import styled from 'styled-components'

const Input = styled.input`
    border: none;
    font-size: 1em;
    line-height: 1em;
    transition: 0.3s;
    background-color: transparent;
    border-radius: 4px;
    padding: 0em 0.5em;
    font-family: lato;
    height: 36px;
    flex-grow: 1;
    outline: none;
    color: ${props => 
        props.error ? 'red' : 
        'black'
    }}
`

export default Input