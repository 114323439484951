import ameli from 'Api/ameli';
import React from 'react';
import { useReducer } from 'react';

const AuthContext = React.createContext([]);


const authReducer = (state, action) => {
    switch (action.type) {
        case 'add_error':
            return { ...state, errorMessage: action.payload }
        case 'signin':
            return { errorMessage: '', token: action.payload.token, employeeId: action.payload.employeeId }
        case 'clear_error_message':
            return { ...state, errorMessage: '' }
        case 'signout':
            return { token: null, employeeId:null, errorMessage: '' };
        default:
            return state;
    }
};

const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        authReducer, 
        {
            errorMessage: '',
            token: null,
            employeeId: null,
        }
    )

    const tryLocalSignin = async (callback=()=>{}) => {
        const token = localStorage.getItem('ameli_token');
        const employeeId = localStorage.getItem('ameli_employee_id');
        if (token && employeeId) {
            dispatch({
                type: 'signin',
                payload: { token, employeeId }
            })
        }
        else {
            callback()
        }
    }
    
    
    const signup = async ({ employeeId, email, password }) => {
        try {
            const response = await ameli.post('/signup', { employeeId, email, password });
            await localStorage.setItem('ameli_token', response.data.token);
            await localStorage.setItem('ameli_employee_id', String(response.data.employeeId));
            dispatch({
                type: 'signin',
                payload: {
                    token: response.data.token,
                    employeeId: response.data.employee_id
                }
            })
        }
        catch (err) {
            console.log(err);
            dispatch({
                type: 'add_error',
                payload: 'Something went wrong with sign up'
            })
        }
    }
    
    
    const signin = async ({ email, password }) => {
        try {
            const response = await ameli.post('/signin', { email, password });
            await localStorage.setItem('ameli_token', response.data.token);
            await localStorage.setItem('ameli_employee_id', String(response.data.employeeId));
            dispatch({
                type: 'signin',
                payload: {
                    token: response.data.token,
                    employeeId: response.data.employee_id
                }
            })
        }
        catch (err) {
            console.log(err);
            dispatch({
                type: 'add_error',
                payload: 'Something went wrong with sign in'
            })
        }
    }
    
    
    const signout = async () => {
        await localStorage.removeItem('ameli_token');
        await localStorage.removeItem('ameli_employee_id');
        dispatch({ type: 'signout' })
    }
    
    
    const clearErrorMessage = () => {
        dispatch({
            type: 'clear_error_message',
        })
    }

    return (
        <AuthContext.Provider
            value={{
                state,
                tryLocalSignin,
                signin,
                signout,
                signup,
                clearErrorMessage
            }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthProvider, AuthContext }
