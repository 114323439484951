
import ameli from 'Api/ameli';
import { useQuery } from '@tanstack/react-query';
import Service from './Service';

export interface ServiceGetParams {}

export function useServices(params: ServiceGetParams) {
    return useQuery({
        queryKey: ['services', params],
        queryFn: () => ameli.get<Service[]>(`services`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useService(id) {
    return useQuery({
        queryKey: ['services', id],
        queryFn: () => ameli.get<Service>(`services/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}