import React, { useMemo } from 'react';
import useSignalingActions from 'Signals/useSignalingActions';
import ameli from 'Api/ameli';
import useLookup from 'Collections/useLookup';
import usePersonUtility from 'Persons/usePersonUtility';
import { usePersons } from 'Persons/PersonApi';

const channel = ['Employees']

const actions = {
    'create': data => ameli.post('employees', data),
    'delete': data => ameli.delete(`employees/${data}`),
    'update': data => ameli.put(`employees`, data),
    'updateFinances': data => ameli.put(`employees/finances`, data),
    'updateWorkDetails': data => ameli.put(`employees/work_details_fully`, data)
}

const useEmployeeActions = () => {
    return useSignalingActions(actions, channel)
}

const EmployeeContext = React.createContext([])

const defaultParams = {}

const EmployeeProvider = ({ params=defaultParams, ...props }) => {

    const employees = usePersons(params)
    const idLookup = useLookup('employee_id', employees.data)
    const { isActive } = usePersonUtility()

    const value = useMemo(() => {
        return {
            ...employees,
            idLookup,
            archived: employees?.data?.filter(employee => !isActive(employee)),
            active: employees?.data?.filter(employee => isActive(employee)),
        }
    }, [employees])

    return (
        <EmployeeContext.Provider
            value={value}
            { ...props }
        />
    )
}

export { EmployeeContext, EmployeeProvider, useEmployeeActions }
