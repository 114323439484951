import styled from 'styled-components'

const CentralizedView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
`

export default CentralizedView