import Spacer from 'components/Spacer/Spacer';
import { useInput } from 'hooks/input';
import { FaBirthdayCake, FaClock } from 'react-icons/fa';
import moment from 'moment';
import Row from 'components/Row';
import TextInput from 'components/input/TextInput';
import Button from 'components/Button';
import { useMemo } from 'react';
import BirthdayList from 'Persons/BirthdayList';
import styled from 'styled-components';
import useModal from '../../../components/modal/useModal';
import Table from 'components/table/table';
import UserAppointmentModal from 'UserAppointments/UserAppointmentModal';
import useUserAppointmentColumns from 'UserAppointments/useUserAppointmentColumns';
import useUserAppointments, { useUserAppointmentActions } from 'UserAppointments/useUserAppointments';
import Screen from 'app/Views/Screen';
import Tile from 'components/Tiles/Tile';
import ScrollView from 'components/ScrollView';
import { usePersons } from 'Persons/PersonApi';

const Layout = styled.div`
    overflow: hidden;
    flex: 1;
    display: grid;
    grid-template-columns: 27em auto;
    grid-template-areas: "birthday events";
`

const BirthdayArea = styled.div`
    padding: 1em;
    padding-right: 0.5em;
    overflow: hidden;
    grid-area: birthday;
`

const EventArea = styled.div`
    display: flex;
    padding: 1em;
    overflow: hidden;
    grid-area: events;
`

const BirthdayTile = () => {
    const personParams = useMemo(() => ({
        with_upcoming_birthday: true,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    }), [])

    const { data, isPending } = usePersons(personParams)
    const listData = useMemo(() => {
        return data?.sort((a,b) => {
            return moment(b.birthday).year(0).isBefore(moment(a.birthday).year(0)) ? 1 : -1
        })
    }, [data])

    const title = useMemo(() => {
        const countLabel = data?.length > 0 ? String(data?.length) : "keine"
        return `Es stehen ${countLabel} Geburtstage an`
    }, [data])

    return (
        <Tile
            title={title}
            Icon={FaBirthdayCake}
        >
            <ScrollView>
                <BirthdayList persons={listData} isPending={isPending} />
            </ScrollView>
        </Tile>
    )
}

const UserAppointmentTile = () => {

    const { value: filter, bind: bindFilter } = useInput("");
    const columns = useUserAppointmentColumns()
    const modal = useModal()

    const params = useMemo(() => {
        return { user_id: 2 }
    }, [])

    const { data } = useUserAppointments(params)
    const userAppointmentActions = useUserAppointmentActions()

    return (
        <Tile
            title='Termine'
            Icon={FaClock}
        >
            <UserAppointmentModal {...modal} dispatchAction={userAppointmentActions.dispatch} />
            <Spacer medium vSmall>
                <Row vCentered>
                    <TextInput size={20} label='Filtern' {...bindFilter} />
                    <Button onClick={() => modal.select({ user_id: 2, date: moment().format('YYYY-MM-DD') })}>Termin erstellen</Button>
                </Row>
            </Spacer>
            <Table
                data={data}
                filter={filter}
                columns={columns}
                onClick={modal.select}
            />
        </Tile>
    )
}

export default function HomeView() {

    return (
        <Screen>
            <Layout>
                <BirthdayArea>
                    <BirthdayTile />
                </BirthdayArea>
                <EventArea>
                    <UserAppointmentTile />
                </EventArea>
            </Layout>
        </Screen>
    );
}