import Checkbox from "components/checkbox/Checkbox";
import TextInput from '../components/input/TextInput';
import { FormGroup } from 'components/form/FormComponents';
import IFormProps from "Forms/IFormProps";
import CareLevelSelect from "CareLevel/CareLevelSelect";
import DatePicker from "components/input/DatePicker";
import ConditionalView from "components/Decorator/ConditionalView";

export default function ClinicalPictureForm({ form } : IFormProps ) {


    return (
        <div>
            <FormGroup>
                <CareLevelSelect
                    label='Pflegegrad'
                    value={form.getFieldValue('care_level')}
                    onChange={({value}) => form.setFieldValue('care_level', value)}
                />
                <ConditionalView condition={Boolean(form.data.care_level)}>
                    <DatePicker
                        label='Pflegegrad seit'
                        value={form.getFieldValue('care_level_reference_date')}
                        onChange={(value) => form.setFieldValue('care_level_reference_date', value)}
                    />
                </ConditionalView>
            </FormGroup>

            <FormGroup>
                <div>
                    <Checkbox
                        label='Demenz'
                        value={form.getFieldValue('dementia', false)}
                        onChange={({value}) => form.setFieldValue('dementia', value)}
                    />       
                    <Checkbox
                        label='Eingeschränkte Mobilität'
                        value={form.getFieldValue('mobility', false)}
                        onChange={({ value }) => form.setFieldValue('mobility', value)}
                    />
                    <Checkbox
                        label='Krebserkrankung'
                        value={form.getFieldValue('cancer', false)}
                        onChange={({ value }) => form.setFieldValue('cancer', value)}
                    />
                    <Checkbox
                        label='Herzerkrankung'
                        value={form.getFieldValue('heart_disease', false)}
                        onChange={({ value }) => form.setFieldValue('heart_disease', value)}
                    />
                    <Checkbox
                        label='COPD-Erkrankung'
                        value={form.getFieldValue('copd', false)}
                        onChange={({ value }) => form.setFieldValue('copd', value)}
                    />
                    <Checkbox
                        label='Gleichgewichtsstörungen'
                        value={form.getFieldValue('imbalance', false)}
                        onChange={({ value }) => form.setFieldValue('imbalance', value)}
                    />
                    <Checkbox
                        label='Dialysepatient'
                        value={form.getFieldValue('dialysis', false)}
                        onChange={({ value }) => form.setFieldValue('dialysis', value)}
                    />
                    <Checkbox
                        label='Geistige- bzw. körperliche Behinderung'
                        value={form.getFieldValue('disabled', false)}
                        onChange={({ value }) => form.setFieldValue('disabled', value)}
                    />
                    <Checkbox
                        label='Schmerzpatient'
                        value={form.getFieldValue('pain', false)}
                        onChange={({ value }) => form.setFieldValue('pain', value)}
                    />
                </div>
            </FormGroup>

             <FormGroup>
                <TextInput 
                    label="Anderes"
                    value={form.getFieldValue('other', false)}
                    onChange={(event) => form.setFieldValue('other', event.target.value)}
                />
            </FormGroup>   
        </div>
    )
}