import TextArea from 'components/input/TextArea';
import TextInput from 'components/input/TextInput';
import Spacer from 'components/Spacer/Spacer';

export default function NoteForm({formData, handleChange}) {

    return (
        <div>
            <TextInput label='Titel' onChange={handleChange} name='title' value={formData.title}/>
            <Spacer vSmall/>
            <TextArea label='Details' minRows={8} onChange={handleChange} name='details' value={formData.details}/>
        </div>
    )
}