
import ameli from 'Api/ameli';
import { useQuery } from '@tanstack/react-query';
import CustomerService from './CustomerService';

export interface CustomerServiceGetParams {
    customerId?: number
}

export function useCustomerServices(params: CustomerServiceGetParams) {
    return useQuery({
        queryKey: ['customer_services', params],
        queryFn: () => ameli.get<CustomerService[]>(`customer_services`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useCustomerService(id) {
    return useQuery({
        queryKey: ['customer_services', id],
        queryFn: () => ameli.get<CustomerService>(`customer_services/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}