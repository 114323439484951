
import { IModel } from "../Models/IModel"
import Service from "./Service"
import DataTypes from "Common/Objects/DataTypes"
import { PropertyDefinitions } from "Common/Objects/PropertyDefinition"
import ServiceDetailsModel from "./ServiceDetails/ServiceDetailsModel"


const properties: PropertyDefinitions = {
    id: {
        type: 'single',
        primaryKey: true,
        label: "Id",
        valueType: new DataTypes.Integer({
            unsigned: true,
            allowNull: true
        })
    },
    label: {
        type: 'single',
        label: 'Titel',
        valueType: new DataTypes.Text({
            length: 32
        }),
        required: true
    },
    hourlyRate: {
        type: 'single',
        label: 'Stundensatz',
        valueType: new DataTypes.Float({
            unsigned: true,
            defaultValue: 35.00
        }),
        required: true
    },
    useSquareMeter: {
        type: 'single',
        label: 'Quadratmeter abfragen',
        valueType: new DataTypes.Boolean({
            defaultValue: true
        }),
        required: true
    },
    serviceDetails: {
        type: 'array',
        label: 'Details',
        properties: [],
        elementDefinition: ServiceDetailsModel.objectDefinition,
    },
    createdAt: {
        type: 'single',
        label: 'Erstellt am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
    updatedAt: {
        type: 'single',
        label: 'Bearbeitet am',
        valueType: new DataTypes.Date({
            format: 'DD.MM.YY HH:mm',
            allowNull: true
        })
    },
}

const ServiceModel: IModel<Service> = {
    name: 'service',
    labels: ['Dienstleistung', 'Dienstleistungen'],
    objectDefinition: {
        label: "Dienstleistung",
        type: 'object',
        properties,
    },
    apiPath: 'services'
}

export default ServiceModel
