import moment from "moment";
import Label, { LabelProps } from './Label';
import { useMemo } from "react";

interface Props extends LabelProps {
    date?:string,
    fallback?:string,
    format?:string,
}

export default function DateLabel({ date, fallback='', format = "DD.MM.YYYY", ...props}: Props) {

    const label = useMemo(() => {
        if (!date) {
            return fallback
        } 
        return moment(date).format(format)
    }, [format, date, fallback])

    return (
        <Label {...props}>{label}</Label>
    )
}