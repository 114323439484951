import styled from 'styled-components'

const TabRow = styled.div`
    display: flex;
    flex-direction: row;

    &:after {
        content: "";
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        border-bottom: 3px solid #ddd;
    }
`

export default TabRow