import styled from 'styled-components';

const Navbar = styled.div`
    display: flex;
    flex-direction: row;
    color: #f7f7f7;
    align-items: center;
    background-color: ${props => props.dark ? '#424242' : '#ce0069'};
    box-shadow: ${ props => props.shadow ? '0 1px 4px rgba(0, 0, 0, 0.404)' : 'none'}
`

const NavbarLogo = styled.div`
    margin-left: 1rem;
    box-sizing: border-box;
    display:inline-block;
    background-color: #f7f7f7;
    border-radius: 50%;
    position: relative;
    height:3.8em;
    width:3.8em;
    padding: 5px;
    border: 2px solid lightgray;
    flex-shrink: 0;

    > img {
        max-height: 100%;
        max-width: 100%;
        position: relative;
        top: 3px;
    }
`

export default Navbar
export { NavbarLogo }