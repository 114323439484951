import React, { useEffect, useState } from "react";
import Dropdown from "../components/dropdown/dropdown";
import ameli from "Api/ameli";

export default function WorkfieldSelect(props) {

    const [options, setOptions] = useState([])

    useEffect(() => {
        ameli.get("/work_fields")
            .then(response => {
                const newOptions = response.data.map(workfield => {
                    return { 
                        value: workfield.work_field_id, 
                        label: workfield.work_field_label
                    }
                })
                setOptions([
                    {
                        value: null,
                        label: 'Keine'
                    },
                    ...newOptions
                ])
            })
            .catch(error => setOptions([]))
    }, []);

    return (
        <Dropdown options={options} {...props}></Dropdown>
    );
}
