import CurrencyLabel from "components/labels/currency-label";
import DateLabel from "components/labels/DateLabel";
import { useMemo } from "react";
import Label from 'components/labels/Label';
import TableCell from "components/table/Cells/TableCell";

export function useBookingColumns() {
    const columns = useMemo(() =>
        [
            {
                Header: 'Datum',
                accessor: (data) => data?.event?.date || null,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value}></DateLabel>
                        )}
                    />
                ),
            },
            {
                Header: 'Mitarbeiter',
                accessor: d => d?.event,
                Cell: ({ value, ...props }) => {
                    return (
                        <TableCell
                            {...props}
                            renderLabel={() => (
                                <Label>{`${value?.employee_first_name} ${value?.employee_last_name}`}</Label>
                            )}
                        />
                    )
                },
            },
            {
                Header: 'Anmerkung',
                accessor: d => d.note,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label colored={true}>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Budget',
                accessor: (data) => data.budget_type_label || "Eigenleistung",
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Betrag',
                accessor: (data) => data.amount,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value}></CurrencyLabel>
                        )}
                    />
                ),
                Footer: (data) => {
                    var sum = 0;
                    data.flatRows.forEach(row => {
                        sum += parseFloat(row.original.amount);
                    });
                    return (
                        <CurrencyLabel bold value={sum}></CurrencyLabel>
                    )
                }
            },
        ],
        []);

    return { columns };
}