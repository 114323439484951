import Label from "components/labels/Label";
import ContactDetails from "./ContactDetails";
import { FaAddressBook } from "react-icons/fa";
import IconRow from "components/Icons/IconRow";
import usePhoneNumbers from "PhoneNumbers/usePhoneNumbers";
import PhoneNumberIconRow from "PhoneNumbers/PhoneNumberIconRow";
import EmailRow from "./EmailRow";
import WebsiteRow from "./WebsiteRow";
import ClickableOpacity from "components/Decorator/ClickableOpacity";
import Column from "components/Column";

interface Props {
    contactDetails:ContactDetails,
    isPending:boolean,
    onClick?:()=>any,
    onClickCreate?:()=>any
}

export default function ContactDetailsRows({ contactDetails, isPending, onClick, onClickCreate }:Props) {

    const phoneNumbers = usePhoneNumbers(contactDetails?.id)

    const {
        email=null,
        website=null
    } = contactDetails || {}

    if(!contactDetails) {
        return (
            <IconRow
                onClick={onClickCreate}
                Icon={<FaAddressBook />}
            >
                <Label subtle small>Kontaktdaten hinzufügen</Label>
            </IconRow>
        )
    }

    if(isPending || phoneNumbers.isPending) {
        return null
    }

    if(!email && !website && phoneNumbers.data.length === 0) {
        return (
            <IconRow
                onClick={onClick}
                Icon={<FaAddressBook/>}
            >
                <Label subtle small>Kontaktdaten bearbeiten</Label>
            </IconRow>
        )
    }

    return (
        <ClickableOpacity onClick={onClick}>
            <Column gap='0.25em'>
                <EmailRow email={contactDetails.email}/>
                <WebsiteRow website={contactDetails.website}/>
                <PhoneNumberIconRow phoneNumbers={phoneNumbers.data}/>
            </Column>
        </ClickableOpacity>
    )
}