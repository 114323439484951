import { generatePositiveIntegerField, generateTextField, generateDateField, generateObjectField } from "Forms/formFieldFactory"
import { useMemo } from "react"
import Person from "./Person"
import Customer from "Customer/Customer"
import Employee from "Employees/Employee"

interface PersonData extends Person {   
    customer?: Customer,
    employee?: Employee
}

const usePersonFormFields = (person:PersonData) => {
    const fields = useMemo(() => ({
        id: generatePositiveIntegerField(person?.id, false),
        title_id: generatePositiveIntegerField(person?.title_id, false),
        first_name: generateTextField(person?.first_name, true),
        last_name: generateTextField(person?.last_name, true),
        addresses_id: generatePositiveIntegerField(person?.addresses_id, false),
        contact_details_id: generatePositiveIntegerField(person?.contact_details_id, false),
        family_status_id: generatePositiveIntegerField(person?.family_status_id, false),
        nationality_id: generatePositiveIntegerField(person?.nationality_id, false),
        denomination_id: generatePositiveIntegerField(person?.denomination_id, false),
        health_insurance_id: generatePositiveIntegerField(person?.health_insurance_id, false),
        health_insurance_number: generateTextField(person?.health_insurance_number, false),
        birthday: generateDateField(person?.birthday, false),
        birthplace: generateTextField(person?.birthplace, false),
        entry: generateDateField(person?.entry, false),
        exit_date: generateDateField(person?.exit_date, false),
        cancelation_type_id: generatePositiveIntegerField(person?.cancelation_type_id, false),
        cancelation_details: generateTextField(person?.cancelation_details, false),
        customer: generateObjectField(person?.customer, false),
        employee: generateObjectField(person?.employee, false),
        deleteAt: generateDateField(person?.deleteAt?.format('YYYY-MM-DD'), false)
    }), [person])

    return fields
}

export default usePersonFormFields