import { useCallback, useEffect, useReducer } from "react"
import OperationReport from "./OperationReport"

const useOperationReportEntries = (initialEntries:OperationReport[]) => {

    const parseEntries = (entries) => {
        var combined = {}
        var unassigned = []

        entries?.forEach(entry => {
            if (!entry.date) {
                unassigned.push(entry)
                return
            }
            var dateString = entry.date
            if (dateString in combined) {
                combined[dateString] = combined[dateString].combine(entry)
            }
            else {
                combined[dateString] = entry
            }
        });

        return [
            ...Object.values(combined),
            ...unassigned
        ].sort((a, b) => !a.start ? b : !b.start ? a : a.start.valueOf() - b.start.valueOf())
    }

    const validate = (data) => {
        var valid = true
        data?.forEach((entry) => {
            valid = valid && entry.valid()
        })
        return valid
    }

    const reducer = (state, action) => {
        var data = state.data
        switch(action.type) {
            case 'set_entries':
                data = action.payload ? [...action.payload] : []
                break
            case 'add':
                data = [...data, action.payload]
                break
            case 'remove':
                data = data.filter(entry => entry !== action.payload)
                break
            default:
                break
        }

        return parseState(data)
    }

    const parseState = (entries) => {
        var data = parseEntries(entries) || []

        return {
            data,
            isValid: validate(data),
            hours: data.reduce((sum, entry) => sum + entry.hours, 0.0),
            kilometer: data.reduce((sum, entry) => sum + entry.kilometer, 0.0)
        }
    }

    const [state, dispatch] = useReducer(reducer, parseState(initialEntries))


    useEffect(() => {
        dispatch({
            type: 'set_entries',
            payload: initialEntries
        })
    }, [initialEntries])

    const add = useCallback((entry) => {
        dispatch({
            type: 'add',
            payload: entry
        })
    }, [dispatch])

    const remove = useCallback((entry) => {
        dispatch({
            type: 'remove',
            payload: entry
        })
    }, [dispatch])

    const setData = useCallback((entry) => {
        dispatch({
            type: 'set_entries',
            payload: entry
        })
    }, [dispatch])

    return {
        ...state,
        add,
        remove,
        setData
    }
}

export default useOperationReportEntries