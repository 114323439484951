import React from "react";
import { toYearMonthString } from "utils/string-utility";
import Label from "./Label";


export default function YearMonthLabel({value, ...props}) {

    return (
        <Label {...props}>
            {toYearMonthString(value)}
        </Label>
    );
}