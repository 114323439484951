import styled from 'styled-components';
import Label from './labels/Label';

const SectionContainer = styled.div`
    margin-top: 0.25em;
    display: flex;
    flex-direction: row;
`

const SectionLeft = styled.div`
    ${props => {
        return props.labelWidth ? `width: ${props.labelWidth}` : ''
    }}
`

const SectionRight = styled.div`

`

const CardSection = ({ labelWidth=undefined, label, children }) => {
    return (
        <SectionContainer>
            <SectionLeft labelWidth={labelWidth}>
                <Label small>{label}</Label>
            </SectionLeft>
            <SectionRight>
                {children}
            </SectionRight>
        </SectionContainer>
    )
}


const Card = styled.div`
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: 0.25s;
    flex-grow: 1;

    ${props => props.onClick ? 
        `
            &:hover {    
                cursor: pointer;
                background-color: #f7f7f7;
            }
        `
        :
        ''
    }
`

export default Card
export {CardSection }