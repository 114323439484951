import PersonSidebar from "../../../Persons/PersonSidebar";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from 'react';
import Page from "components/Page";
import Screen from "../Screen";
import ScreenLayout from "../ScreenLayout";
import usePersonUtility from "Persons/usePersonUtility";
import { usePersons } from "Persons/PersonApi";
import PersonOutlet from "Persons/PersonOutlet";
import PersonModal from "Persons/PersonModal";
import useModal from "components/modal/useModal";
import Person from "Persons/Person";
import CentralizedView from "components/CentralizedView";
import Label from "components/labels/Label";

export default function CustomerScreen() {

    const modal = useModal<Person>()
    const location = useLocation()
    const personUtility = usePersonUtility()
    const [searchParams, setSearchParams] = useSearchParams()

    const customerParams = useMemo(() => ({
        is_customer: true
    }), [])
    const customers = usePersons(customerParams)

    const person = useMemo(() => {
        const id = Number(searchParams.get('id'))
        if(!id || !customers.data) {
            return null
        }
        return customers.data.find(c => c.id === id)
    }, [searchParams, customers.data])

    useEffect(() => {
        if (!searchParams.get('id')) {
            const customer = customers.data?.find(c => personUtility.isActive(c))
            if(customer) {
                selectPerson(customer.id)
            }
        }
    }, [setSearchParams, customers, location.pathname, searchParams])

    const mutateOptions = {
        onSuccess: (response) => {
            selectPerson(response.data.insertId)
        }
    }

    const selectPerson = useCallback((id:number) => {
        setSearchParams((params) => ({
            id: String(id),
            ...params
        }))
    }, [setSearchParams])

    return (
        <Screen> 
            <PersonModal {...modal} mutateOptions={mutateOptions}/>
            <ScreenLayout.SideBar>
                <PersonSidebar 
                    selected={person} 
                    select={(person) => selectPerson(person.id)}
                    persons={customers.data}
                    createPerson={() => { modal.select({ customer: {}})}}
                />   
                <Page>
                    {
                        person? (
                            <PersonOutlet person={person} />  
                        )
                        :
                        (
                            <CentralizedView>
                                <Label subtle>Kein Kunde ausgewählt</Label>
                            </CentralizedView>
                        )
                    }       
                </Page>        
            </ScreenLayout.SideBar>
        </Screen>
    );
}