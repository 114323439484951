import ameli from 'Api/ameli';
import { useQuery } from '@tanstack/react-query';
import Bill from './Bill';


export default function useBills(params) {

    const query = useQuery({
        queryKey: ['bills', params],
        queryFn: () => ameli.get<Bill[]>('bills', {params}).then(res => res.data),
        enabled: Boolean(params)
    })

    return query
}