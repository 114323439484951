import ClickableOpacity from "./Decorator/ClickableOpacity"
import Theme from "Theming/Theme"
import Tile from "./Tiles/Tile"
import Column from "./Column"
import { IconContext } from "react-icons"
import { MdAddBox } from "react-icons/md"
import Label from "./labels/Label"
import React from "react"

interface Props {
    text:string,
    onClick?:() => any,
    Icon?: React.ReactNode
}

export default function AddComponentButton({text, onClick, Icon}:Props) {
    return (
        <ClickableOpacity onClick={onClick}>
            <Tile>
                <Column hCentered>
                    <IconContext.Provider value={{ color: Theme.primaryColor, size: '32'}}>
                        {
                            Icon || <MdAddBox/>
                        }
                    </IconContext.Provider>
                </Column>
                <Label centered small bold>{text}</Label>
            </Tile>
        </ClickableOpacity>
    )
}