import NoteList from "Notes/NoteList"
import NoteModal from "OperationNotes/OperationNoteModal"
import Tile from "components/Tiles/Tile"
import useModal from "components/modal/useModal"
import moment from "moment"
import useCustomerNotes, { useCustomerNoteActions } from "./useCustomerNotes"
import { FaPlus } from "react-icons/fa"
import Spacer from "components/Spacer/Spacer"


const CustomerNoteTile = ({ customer }) => {

    const { data } = useCustomerNotes(customer?.person_id)
    const customerNoteActions = useCustomerNoteActions(customer?.person_id)
    const modal = useModal()

    return (
        <Tile
            title='Protokolle'
            actions={[
                {
                    Icon: <FaPlus />,
                    execute:() => modal.select({ customer_id: customer?.person_id })
                }
            ]}
        >
            <NoteModal {...modal} dispatchAction={customerNoteActions.dispatch} />
            <Spacer hMedium>
                <NoteList
                    notes={data.filter(n => n.timestamp === null || moment().diff(moment(n.timeStamp), 'years') <= 0)}
                    placeholder='Keine Einträge vorhanden'
                    onClick={modal.select}
                />
            </Spacer>
        </Tile>
    )
}

export default CustomerNoteTile