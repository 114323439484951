
import { useContext, useEffect } from 'react';
import { SignalContext } from './SignalContext';

const useSignalEmitter = () => {
    const { emitSignal } = useContext(SignalContext)

    return emitSignal
}

const useSignalHandler = (keys, handler) => {

    const { addListener, removeListener } = useContext(SignalContext)

    useEffect(() => {
        addListener(keys, handler)
        return () => {
            removeListener(keys, handler)
        }
    }, [keys, handler, addListener, removeListener])
}

export { useSignalEmitter, useSignalHandler } 