import { useSignalHandler } from '../Signals/useSignals'
import { useContext, useReducer, useCallback, useEffect } from "react"
import { ErrorContext } from '../Errors/ErrorContext'
import ameli from './ameli'


const reducer = (state, action) => {
    switch (action.type) {
        case 'set_is_pending':
            return { ...state, isPending: action.payload }
        case 'set_data':
            return { ...state, data: action.payload, isPending: false }
        default:
            return state
    }
}

const DEFAULT_INITIAL_DATA = []

const useApiData = (path, params, channel, initialData = DEFAULT_INITIAL_DATA) => {
    const { writeError } = useContext(ErrorContext) || {}

    const [state, dispatch] = useReducer(reducer, {
        data: initialData,
        isPending: true
    })

    const handleError = useCallback((error) => {
        console.log(error)
        dispatch({type: 'set_is_pending', payload: false})
        if (writeError) {
            writeError(error)
        }
    }, [writeError])

    const request = useCallback(() => {
        if(params) {
            return ameli.get(path, { params })
        }
        return Promise.resolve(initialData)
    }, [path, params, initialData])

    const reload = useCallback(() => {
        dispatch({type: 'set_is_pending', payload: true})
        return request()
            .then(({ data }) => {
                dispatch({ type: 'set_data', payload: data })
            })
            .catch(handleError)
    }, [dispatch, request, handleError])

    const refresh = useCallback(() => {
        return request()
            .then(({ data }) => {
                dispatch({ type: 'set_data', payload: data })
            })
            .catch(handleError)
    }, [dispatch, request, handleError])

    useSignalHandler(channel, refresh)

    useEffect(() => {
        reload()
    }, [reload])

    return { reload, ...state }
}

export default useApiData