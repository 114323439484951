import styled from 'styled-components'
import Theme from 'Theming/Theme'

const getHorizontalPadding = props => {
    if (props.tiny || props.hTiny) {
        return Theme.hTiny
    }
    if (props.small || props.hSmall) {
        return Theme.hSmall
    }
    if (props.medium || props.hMedium) {
        return Theme.hMedium
    }
    if(props.large || props.hLarge) {
        return Theme.hLarge
    }
    return "0"
}

const getVerticalPadding = props => {
    if (props.tiny || props.vTiny) {
        return Theme.vTiny
    }
    if (props.small || props.vSmall) {
        return Theme.vSmall
    }
    if (props.medium || props.vMedium) {
        return Theme.vMedium
    }
    if (props.large || props.vLarge) {
        return Theme.vLarge
    }
    return "0"
}

const Spacer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => getVerticalPadding(props)} ${props => getHorizontalPadding(props)};
    flex-basis: auto;
    min-height: ${props => props.shrink ? 0 : 'auto'};
`

export default Spacer;
