import { useQuery } from "@tanstack/react-query"
import * as ttapi from '@tomtom-international/web-sdk-services'

export default (query:string) => {
    return useQuery({
        queryKey: ['mapSearch', query],
        queryFn: () => {
            return ttapi.services.geocode({
                key: process.env.REACT_APP_TOM_TOM_API_KEY,
                query,
                bestResult:true
            })
        },
        enabled: query && query.length > 10
    })
}