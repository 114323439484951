import BudgetTypeSelect from "./BudgetTypeSelect";
import DatePicker from "../components/input/DatePicker";
import EuroInput from "../components/input/euro-input";
import Row from "components/Row";
import TextArea from 'components/input/TextArea';
import { FormGroup } from "components/form/FormComponents";
import InputLabel from "components/input/InputLabel";
import { useClinicalPicture } from "ClinicalPictures/ClinicalPicturesApi";
import useBudgetUtility from "./useBudgetUtility";
import IForm from "Forms/IForm";
import { usePerson } from "Persons/PersonApi";

interface Props {
    form: IForm
}

export default function BudgetForm({form}:Props) {

    const budgetUtility = useBudgetUtility()
    const person = usePerson(form.getFieldValue('budget_owner_id'))
    const clinicalPicture = useClinicalPicture(person?.data?.clinical_picture_id)

    const handleTypeChange = (type) => {
        if (type) {
            form.setFieldValue('budget_type_id', type.id)
            form.setFieldValue('budget_amount', budgetUtility.getDefaultAmount(type.id, clinicalPicture.data?.care_level))
        }
    }

    return(
        <div>
            <FormGroup>
                <BudgetTypeSelect 
                    label='Typ'
                    careLevel={clinicalPicture.data?.care_level}
                    name='budget_type_id'
                    value={form.getFieldValue('budget_type_id')}
                    onValueChange={handleTypeChange}
                />
                <EuroInput
                    label='Betrag'
                    type="text"
                    name='budget_amount'
                    value={form.getFieldValue('budget_amount')}
                    onChange={(event) => form.setFieldValue('budget_amount', event.currentTarget.value)}
                />
                <Row>
                    <DatePicker
                        grow 
                        label={'Gültig ab'}
                        type="text"
                        name='budget_begin_date'
                        value={form.getFieldValue('budget_begin_date')}
                        onChange={(value) => form.setFieldValue('budget_begin_date', value)}
                    />
                    <InputLabel>-</InputLabel>
                    <DatePicker 
                        grow
                        label={'Verfällt am'}
                        type="text"
                        name='budget_decline_date'
                        value={form.getFieldValue('budget_decline_date')}
                        onChange={(value) => form.setFieldValue('budget_decline_date', value)}
                    />
                </Row>
                <TextArea 
                    label='Anmerkung'
                    minRows={3}
                    name='budget_note'
                    value={form.getFieldValue('budget_note')}
                    onChange={(event) => form.setFieldValue('budget_note', event.currentTarget.value)}
                />
            </FormGroup>
        </div>
    )
}