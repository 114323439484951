import Customer from "./Customer"
import { useMemo } from "react"
import { generateBooleanField, generatePositiveIntegerField } from "Forms/formFieldFactory"


const useCustomerFormFields = (customer:Customer) => {

    return useMemo(() => ({
        person_id: generatePositiveIntegerField(customer?.person_id, false),
        direct_payer: generateBooleanField(customer?.direct_payer, false),
        has_bl: generateBooleanField(customer?.has_bl, false),
        state: generateBooleanField(customer?.state, false)
    }), [customer])
}

export default useCustomerFormFields