import React, { useState } from "react";
import { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de'
import './DatePicker.css'
import TextInput, { TextInputProps } from "./TextInput";
import { formatDateString } from "Time/timeUtility";

registerLocale('de', de)

interface Props extends TextInputProps {
    onChange?: (string) => void,
    depracatedChangeHandler?: (any) => any
}


const DatePicker = React.forwardRef((props:Props, ref) => {

    const [keyPressed, setKeyPressed] = useState(false);

    const broadcastChange = ( value) => {
        if(props.onChange) {
            props.onChange(value)
        }
    }

    const handleChange = event => {
        if (event.target.value === '') {
            broadcastChange(null)
        }
        else if (event.target.value !== '' || !keyPressed) {
            broadcastChange(event.target.value);
            // Remove later
            if(typeof props.depracatedChangeHandler === 'function') {
                props.depracatedChangeHandler(event)
            }
        }
        setKeyPressed(false);
    }

    const handleKeyDown = () => {
        setKeyPressed(true);
    }

    return (
        <TextInput
            {...props}
            ref={ref}
            onChange={handleChange}
            type='date'
            value={formatDateString(props.value, '')}
            onKeyDown={handleKeyDown}
        >
        </TextInput>
    )
})

DatePicker.displayName = 'DatePicker'

export default DatePicker