import InputWrapper, { InputWrapperProps } from 'components/input/InputWrapper';
import { useCallback, useMemo } from 'react';
import ReactSelect from 'react-select'
import styled from 'styled-components';
import { useState } from 'react';

const Select = styled(ReactSelect)`
    flex-grow: 1;
`

export type DropdownOption = {
    value:any,
    label: string
}

export type DropdownProps = InputWrapperProps & {
    value: any,
    data: any[]
    allowNull?:boolean,
    placeholder?:string
    getLabel: (any) => string
    onChange?:(any) => any,
    getValue?: (any) => any,
}

export default function Dropdown({
    label=undefined, 
    placeholder='-', 
    allowNull=false, 
    onChange,
    data,
    getValue = (value) => value,
    getLabel,
    value,
    ...props
}:DropdownProps
) {

    const [focused, setFocused] = useState(false)

    const simulateChangeEvent = (option:DropdownOption) => {
        if(onChange) {
            onChange(option.value)
        }
    }

    const options:DropdownOption[] = useMemo(() => {
        return data?.map((value) => {
            return {
                value: getValue(value),
                label: getLabel(value)
            }
        })
     
    }, [data, getLabel, getValue])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minWidth: '10em',
            transition: '0.25s',
            flex: 1,
            backgroundColor: 'transparent',
            boxShadow: state.isFocused ? null : null,
            border: 'none',
            cursor: 'pointer'
        }),
        valueContainer: (provided) => ({
            ...provided,
            paddingLeft: '0.5em',
        }),
        singleValue: (provided) => ({
            ...provided,
            marginLeft: '0.0em',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 2
        }),
        option: (provided) => ({
            ...provided,
            minHeight: '2em'
        })
    };


    return (
        <InputWrapper optional={props.optional} focused={focused} grow={props.grow} label={label}>
            <Select 
                styles={customStyles}
                noOptionsMessage={() => 'Keine Option gefunden.'}
                placeholder={placeholder}
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                options={options}
                onChange={event => simulateChangeEvent(event)}
                value={value} 
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: '#ce0069'
                    }
                })}
                >
            </Select>
        </InputWrapper>
    );
}