import { FormGroup } from "components/form/FormComponents";
import TextInput from "components/input/TextInput";
import IForm from "Forms/IForm";


interface Props {
    form:IForm
}

export default function BankInformationForm({form}:Props) {
    return (
        <FormGroup>
            <TextInput
                label='Bank'
                onChange={(event) => form.setFieldValue('bank_name', event.target.value)}
                value={form.data.bank_name}
            />
            <TextInput
                label='IBAN'
                onChange={(event) => form.setFieldValue('iban', event.target.value)}
                value={form.data.iban}
            />
        </FormGroup>
    )
}