import Label from "components/labels/Label";
import { useCallback, useMemo } from "react";
import { FaCheck } from "react-icons/fa";
import styled from 'styled-components'

const Box = styled.div`
    background-color: white;
    color: ${props => props.checked ? '#ce0069' : 'lightgray'};
    transition: 0.2s;
    border: 1px solid lightgray;
    border-color: ${props => props.checked ? '#ce0069' : 'lightgray'};
    border-radius: 4px;
    width: 18px;
    height: 18px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.3em;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.7em;
    cursor: pointer;

    &:hover {
        ${Box} {
            border-color: ${props => props.checked ? '#cf87ac' : '#aaa'};
            color: ${props => props.checked ? '#cf87ac' : '#aaa'};
        }
    }

    > ${Label} {
        margin-bottom: 0.1em;
    }
`

export default function Checkbox({labelProps={}, ...props}) {


    const simulateChangeEvent = useCallback((event) => {
        if (props.onChange) {
            props.onChange({
                name: props.name,
                value: !props.value
            })
        }
    }, [props])


    const checked = useMemo(() => Boolean(props.value), [props.value])

    return (
        <Container checked={checked} onClick={simulateChangeEvent}>
            <Box checked={checked}>
                {
                    checked && 
                        <FaCheck size={14} />
                }         
            </Box>
            {
                props.label && 
                <Label {...labelProps} ignoreCursor>{props.label}</Label>
            }  
            { props.children }       
        </Container>
    )
}