import CurrencyLabel from "components/labels/currency-label";
import HourLabel from "components/labels/hour-label";
import Label from "components/labels/Label";
import LabelRow from "components/labels/LabelRow";
import Row from "components/Row";
import { localizeYearString } from "utils/string-utility";
import { BudgetGroup } from "./useGroupedBudgets";

interface Props {
    group:BudgetGroup
}

export default function BudgetGroupListItem({group}:Props) {

    return (
        <div>
            <Row>   
                <Label bold>{group?.budget_type_label}</Label>                
                <LabelRow>
                    <CurrencyLabel value={group?.remaining_amount} />
                    <Label>/</Label>
                    <CurrencyLabel value={group?.budget_amount} />
                </LabelRow>
                <LabelRow>
                    <HourLabel value={group?.remaining_amount / 35.0}/>
                </LabelRow>
            </Row>
            <Label subtle small>
                {`Verfällt am ${localizeYearString(group?.budget_decline_date)}`}
            </Label>
        </div>
    )
}