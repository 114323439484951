import IconRow from "components/Icons/IconRow"
import Label from "components/labels/Label"
import { FaGlobe } from "react-icons/fa"

interface Props {
    website:String | null,
    onClick?: () => any
}

export default function WebsiteRow({website, onClick}:Props) {
    if (!website) {
        return null
    }

    return (
        <IconRow
            onClick={onClick}
            Icon={<FaGlobe/>}
        >
            <Label small subtle>{website}</Label>
        </IconRow>
    )
}