import { useMemo } from "react";
import Dropdown from "../components/dropdown/dropdown";
import useDenominations from "./useDenominations";

export default function DenominationSelect(props) {

    const { data } = useDenominations()

    const options = useMemo(() => {
        return data?.map(d => ({
            value: d.denomination_id,
            label: d.denomination_label
        }))
    }, [data])

    return (
        <Dropdown 
            options={options}
            {...props}>
        </Dropdown>
    );
}
