import { TitleLabel } from "components/labels/Label"
import styled from 'styled-components'
import Spacer from "components/Spacer/Spacer"
import Row from "components/Row"
import Button from "components/Button"
import { useNavigate, useOutletContext } from 'react-router-dom';
import DefaultNavigationTab from "components/tabs/DefaultNavigationTab"
import TabRow from "components/tabs/TabRow"
import usePersonUtility from "Persons/usePersonUtility"
import { useCreatePersonContactDetails, usePersonDelete } from "Persons/PersonApi"
import Column from "components/Column"
import AddressRow from "Addresses/AddressRow"
import ContactDetailsRows from "ContactDetails/ContactDetailsRows"
import FlexFill from "components/FlexFill"
import PersonAddressModal from "Persons/PersonAddressModal"
import useModal from "components/modal/useModal"
import Address from 'Addresses/Address';
import { PersonOutletContextType } from "Persons/PersonOutlet"
import PersonContactDetailsModal from "Persons/PersonContactDetailsModal"
import ContactDetails from "ContactDetails/ContactDetails"

const Header = styled.div`
    padding-top: 0.5em;
    background-color: white;
    display: flex;
    border-bottom: 1px solid rgb(219, 219, 219);
`

const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
`

const CustomerHeaderBar = () => {

    const { person } = useOutletContext<PersonOutletContextType>()
    const navigate = useNavigate()
    const { isActive } = usePersonUtility()
    const deletePerson = usePersonDelete()

    const title = `${person?.title_label} ${person?.first_name} ${person?.last_name}`
    const archived = !isActive(person)
    const addressModal = useModal<Address>()
    const contactDetailsModal = useModal<ContactDetails>()
    const createContactDetails = useCreatePersonContactDetails(person.id)

    const deleteCustomer = () => {
        deletePerson.mutate(person?.id, {
            onSuccess: () => {
                navigate('../')
            }
        })
    }

    return (
        <>
            <PersonAddressModal {...addressModal} personId={person?.id}/>
            <PersonContactDetailsModal {...contactDetailsModal} personId={person?.id}/>
            <Header>
                <Column>
                    <Spacer medium>
                        <Row vCentered>
                            <TitleLabel>{title}</TitleLabel>
                            <Spacer hMedium>
                                <Row gap="4px">
                                    {
                                        archived ?
                                            <Button secondary onClick={deleteCustomer}>Löschen</Button>
                                            :
                                            null
                                    }
                                </Row>
                            </Spacer>
                        </Row>
                    </Spacer>
                    <FlexFill/>
                    <TabRow>
                        <DefaultNavigationTab to='../Informationen' label='Informationen' />
                        <DefaultNavigationTab to='../Protokolle' label='Protokolle' />
                        <DefaultNavigationTab to='../Budgets' label='Budgets' />
                        <DefaultNavigationTab to='../Einsaetze' label='Einsätze' />
                        <DefaultNavigationTab to='../Rechnungen' label='Rechnungen' />
                    </TabRow>
                </Column>
                <Details>
                    <Spacer medium>
                        <Column gap='0.5em'>
                            <AddressRow 
                                address={person?.address} 
                                isPending={false} 
                                onClick={() => addressModal.select(person?.address)}
                            />
                            <ContactDetailsRows 
                                contactDetails={person?.contactDetails} 
                                isPending={false} 
                                onClick={() => contactDetailsModal.select(person?.contactDetails)}
                                onClickCreate={() => createContactDetails.mutate({})}
                            />
                        </Column>
                    </Spacer>
                </Details>
            </Header>
        </>
    )
}

export default CustomerHeaderBar