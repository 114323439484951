import ameli from "Api/ameli";
import Dropdown from "components/dropdown/dropdown";
import { useEffect, useState } from "react";

export default function OperationTaskTypeSelect(props) {

    const [data, setData] = useState([]);

    useEffect(() => {
        ameli.get(`/operation_tasks/types`)
            .then(response => {
                setData(response.data);
            })
    }, [setData])

    return (
        <Dropdown
            placeholder='Bitte auswählen'
            data={data}
            optionFactory={
                type => {
                    return {
                        value: type.id,
                        label: type.label
                    }
                }
            }
            {...props}
        />
    )
}