import ameli from "Api/ameli";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

const usePhoneNumbers = (contactDetailsId) => {
    
    const params = useMemo(() => ({
        contact_details_id: contactDetailsId
    }), [contactDetailsId])

    return useQuery({
        queryKey: ['phone_numbers', contactDetailsId],
        queryFn: () => ameli.get('phone_numbers', { params}).then(res => res.data),
        enabled: Boolean(contactDetailsId)
    })
}

export default usePhoneNumbers