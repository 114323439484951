import { useMemo } from "react"
import { ClinicalPicture } from './ClinicalPicture';
import { generateBooleanField, generateDateField, generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory";
import useForm from "Forms/useForm";

const useClinicalPictureForm = (clinicalPicture:ClinicalPicture) => {
    const fields = useMemo(() => ({
        id: generatePositiveIntegerField(clinicalPicture?.id, false),
        dementia: generateBooleanField(clinicalPicture?.dementia, false),
        mobility: generateBooleanField(clinicalPicture?.mobility, false),
        cancer: generateBooleanField(clinicalPicture?.cancer, false),
        heart_disease: generateBooleanField(clinicalPicture?.heart_disease, false),
        copd: generateBooleanField(clinicalPicture?.copd, false),
        imbalance: generateBooleanField(clinicalPicture?.imbalance, false),
        dialysis: generateBooleanField(clinicalPicture?.dialysis, false),
        disabled: generateBooleanField(clinicalPicture?.disabled, false),
        pain: generateBooleanField(clinicalPicture?.pain, false),
        other: generateTextField(clinicalPicture?.other, false),
        care_level: generatePositiveIntegerField(clinicalPicture?.care_level, false),
        care_level_reference_date: generateDateField(clinicalPicture?.care_level_reference_date, false)
    }), [clinicalPicture])

    return useForm(fields)
}

export default useClinicalPictureForm