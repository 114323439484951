import { useCallback } from "react";
import { useBudgetTypes } from "./BudgetApi";
import BudgetType from "./BudgetType";
import { Moment } from "moment";


export default function useBudgetUtility() {
    const budgetTypes = useBudgetTypes()

    const getBudgetType = useCallback((budgetTypeId:number) : BudgetType => {
        if(budgetTypes.data) {
            return budgetTypes.data.find(type => type.id === budgetTypeId) || null
        }
        return null
    }, [budgetTypes.data])

    const getCareLevelAmount = useCallback((careLevel: number): number => {
        switch (careLevel) {
            case 3:
                return 289.60
            case 4:
                return 545.20
            case 5:
                return 677.20
            case 6:
                return 838.00
            default:
                return 0.0
        }
    }, [])

    const getDefaultAmount = useCallback((typeId:number, careLevel:number) : number => {
        const type = getBudgetType(typeId)
        if(type) {
            if (type.id === 7) {
                return getCareLevelAmount(careLevel)
            }
            return type.default_amount
        }
        return 0.0
    }, [getBudgetType, getCareLevelAmount])

    const suggestPeriodStart = useCallback((typeId:number, date:Moment) : Moment => {
        const type = getBudgetType(typeId)
        if (type?.steps === 'month') {
            return date.startOf('month').startOf('day')
        }
        return date.startOf('year').startOf('day')
    },[getBudgetType])

    const suggestPeriodEnd = useCallback((typeId: number, date: Moment): Moment => {
        const type = getBudgetType(typeId)
        if (type?.id === 7) {
            return date.endOf('month').endOf('day')
        }
        return date.endOf('year').endOf('day')
    }, [getBudgetType])

    return {
        getDefaultAmount,
        suggestPeriodStart,
        suggestPeriodEnd
    }
}