import Row from 'components/Row'
import styled from 'styled-components'

const Label = styled.span`
    transition: 0.2s;
    font-size: 0.8em;
    line-height: 0.0em;
    font-weight: 600;
    margin-top: 0.9em;
    padding: 0em 0.25em;
    color: #555;
    user-select: none;
`

const Title = styled(Label)`
    margin-left: 0.4em;
`

const Optional = styled(Label)`
    color: #aaa;
    font-size: 0.7em;
    line-height: 0.4em;
`

const Wrapper = styled.div`
    background-color: white;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.focused ? '#ce0069' : '#ccc'};
    margin-right: 0.2em;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    flex-grow: ${props => props.grow ? 1 : 0};
    transition: 0.2s;

    ${Label} {
        color: ${props => props.focused ? '#ce0069' : Label.color};
    }

    &:hover {
        border-color: ${props => props.focused ? '#ce0069' : '#bbb'};

        ${Title} {
            color: ${props => props.focused ? '#ce0069' : '#222'};
        }
    }
`

const Content = styled.div`
    min-height: 2.4em;
    display: flex;
    flex-direction: row;
`

export interface InputWrapperProps {
    focused?:boolean,
    grow?:boolean,
    children?:React.ReactNode,
    optional?:boolean,
    setFocused?:(value:boolean) => any,
    label?:string,
    onPress?:() => any,
    name?:string
}

const InputWrapper = ({
    focused=false, 
    grow=false, 
    children, 
    optional=false, 
    setFocused=(focused)=>{}, 
    label=null,
    onPress,
    ...props
}:InputWrapperProps) => {

    const handleClick = () => {
        setFocused(true)
        if (onPress) {
            onPress()
        }
    }

    return (
        <Wrapper focused={focused} grow={grow} onClick={handleClick}>
            <Row>
                {
                    label &&
                        <Title>{label}</Title>
                }
                {
                    optional &&
                        <Optional subtle>Optional</Optional>
                }
            </Row>
                    
            <Content>
                { children }
            </Content>
        </Wrapper>
    )
}

export default InputWrapper