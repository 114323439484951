import styled from 'styled-components'

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: ${props => props.grow ? '1' : '0'};
    min-width: ${props => props.minWidth || 'initial'};
    max-width: ${props => props.maxWidth || 'initial'};
    width: ${props => props.width || 'initial'};
    justify-content: ${props => props.vCentered ? 'center' : 'initial'};
    align-self: ${props => props.vCentered ? 'stretch' : 'initial'};
    align-items: ${props => props.hCentered ? 'center' : 'initial'};
    gap: ${props => props.gap || '0'};
`

export default Column