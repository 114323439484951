import Label from 'components/labels/Label'
import Row from 'components/Row'
import Spacer from 'components/Spacer/Spacer'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import styled from 'styled-components'
import { ColumnFilter } from './filter/ColumnFilter'

const THead = styled.thead`
    text-align: left;
`

const TRow = styled.tr`

`

const TH = styled.th`
    width: ${props => props.width};
    min-width: ${props => props.minWidth};
    max-width: ${props => props.maxWidth};
    vertical-align: top;
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: white;


    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        top: -2px;
        border-top: 2px solid #b3b3b3;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        bottom: -2px;
        border-top: 2px solid #b3b3b3;     
    }
`

const TableHead = ({headerGroups}) => {
    return (
        <THead>
            {headerGroups.map((headerGroup, i) => (
                <TRow key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => {
                        return (
                            <TH 
                                key={i}
                                {...column.getHeaderProps()}  
                                {...column.getSortByToggleProps()}
                                width={column.width}
                                maxWidth={column.maxWidth}
                                minWidth={column.minWidth}
                                >
                                <Spacer medium>
                                    <Row>
                                        <Label bold>
                                            {column.render('Header')}
                                        </Label>
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? <FaCaretDown /> : <FaCaretUp />) : null}
                                        </span>
                                    </Row>
                                    <ColumnFilter column={column}></ColumnFilter>
                                </Spacer>                    
                            </TH>
                        )
                    })}
                </TRow>
            ))}
        </THead>
    )
}

export default TableHead
