import { useMemo } from "react";
import Bill from "./Bill";
import { generateDateField, generatePositiveFloatField, generatePositiveIntegerField } from "Forms/formFieldFactory";
import useForm from "Forms/useForm";


export default function useBillForm(bill:Bill) {
    const fields = useMemo(() => ({
        bill_id: generatePositiveIntegerField(bill?.bill_id, false),
        bill_collection_amount: generatePositiveFloatField(bill?.bill_collection_amount, false),
        request_amount: generatePositiveFloatField(bill?.request_amount, false),
        booked_amount: generatePositiveFloatField(bill?.booked_amount, false),
        bill_collection_date: generateDateField(bill?.bill_collection_date, false),
        bill_request_date: generateDateField(bill?.bill_request_date, false)
    }), [bill])

    return useForm(fields)
}