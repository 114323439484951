import moment, { Moment } from "moment"
import { useCallback, useReducer } from "react"

type CalendarState = {
    selectedDate: Moment,
    monthStart: Moment,
    monthEnd: Moment
}

type SetYearMonthAction = {
    type: "set_year_month",
    year: number,
    month: number
}

type SetDateAction ={
    type: "set_date",
    payload: Moment
}

type CalendarAction = SetYearMonthAction | SetDateAction

const reducer = (state: CalendarState, action: CalendarAction) : CalendarState => {
    let selectedDate = state.selectedDate
    let monthStart = null
    let monthEnd = null

    switch (action.type) {
        case 'set_year_month':
            return {
                monthStart: moment().year(action.year).month(action.month).startOf('month').startOf('day'),
                monthEnd: moment().year(action.year).month(action.month).endOf('month').endOf('day'),
                selectedDate: moment(state.selectedDate).year(action.year).month(action.month)
            }
        case 'set_date':
            selectedDate = action.payload
            monthStart = selectedDate.clone().startOf('month').startOf('day')
            monthEnd = selectedDate.clone().endOf('month').endOf('day')

            return {
                selectedDate,
                monthStart,
                monthEnd,
            }
        default:
            return state
    }
}

const useCalendar = (initialDate:Moment=moment()) => {
    const [state, dispatch] = useReducer(
        reducer,
        {
            selectedDate: moment(initialDate),
            monthStart: moment(initialDate).startOf('month').startOf('day'),
            monthEnd: moment(initialDate).endOf('month').endOf('day'),
        }
    )


    const setDate = useCallback((date:Moment) => {
        dispatch({
            type: 'set_date',
            payload: date
        })
    }, [dispatch])

    const setYearMonth = useCallback((year:number, month:number) => {
        dispatch({
            type: 'set_year_month',
            year,
            month
        })
    }, [dispatch])

    return {
        ...state,
        setDate,
        setYearMonth
    }
}

export default useCalendar