import { useMemo } from "react";
import ContactDetails from "./ContactDetails";
import useForm from "Forms/useForm";
import { generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory";

export default function useContactDetailsForm(contactDetails:ContactDetails) {
    const fields = useMemo(() => ({
        'id': generatePositiveIntegerField(contactDetails?.id, false),
        'email': generateTextField(contactDetails?.email, false),
        'website': generateTextField(contactDetails?.website, false),
    }), [contactDetails])

    return useForm(fields)
}