import { Tag } from "Tags/Tag"


const EventDemandTag = ({event}) => {

    if (!event) {
        return null
    }

    var demand = event.demand

    if (demand === null) {
        return (
            <Tag danger>Vertretungsbedarf unklar</Tag>
        )
    }

    if (demand <= 0) {
        return (
            <Tag success>Kein Vertretungsbedarf</Tag>
        )
    }

    if (demand <= 50) {
        return (
            <Tag warning>Vertretung erwünscht</Tag>
        )
    }

    return (
        <Tag danger>Vertretungsbedarf</Tag>
    )
}

export default EventDemandTag