import { StyleSheet, Text, View, Image, Font } from '@react-pdf/renderer'
import logo from './../assets/logo.png';
import { useMemo } from 'react';
import moment from 'moment';
import { formatFloat } from 'utils/string-utility';

Font.register({
    family: 'Lato',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk6XweuBCY.ttf'
        },
        {
            src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf',
            fontWeight: 'bold'
        }
    ]
})

const styles = StyleSheet.create({
    page: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: 64,
        paddingVertical: 48,
        fontFamily: 'Lato'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 32,
        marginBottom: 16
    },
    logoArea: {
        marginLeft: 8,
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    logo: {
        width: 80,
        height: 80,
        marginTop: -12,
        marginLeft: 16
    },
    contactDetails: {

    },
    inputArea: {
        marginRight: 8,
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    tableArea: {
        minHeight:64,
        marginBottom: 4
    },
    alertArea: {
        marginBottom: 8,
        color: '#666',
        flexGrow: 1
    },
    footer: {
        display: 'flex',
        flexDirection: 'row'
    },
    employeeSignatureArea: {
        marginRight: 8,
        flex: 1
    },
    companySignatureArea: {
        marginLeft: 8,
        flex: 1
    },
    text: {
        fontSize: 12
    },
    smallText: {
        fontSize: 8
    },
    smallBoldText: {
        fontSize: 8,
        fontWeight: 'bold'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        marginBottom: 12,
    },
    inputLeft: {
        width: 80,
        display: 'flex',
        alignItems: 'flex-end',
        marginRight: 8,
    },
    inputRight: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    inputField: {
        borderBottomWidth: 1,
        borderBottomColor: 'grey',
        height: 16,
    },
    inputSubLabel: {
        marginTop: 2,
        fontSize: 8
    },
    table: {
        borderColor: '#666',
        borderTopWidth: 1
    },
    tableHeader: {
        borderBottomWidth: 1,
        borderColor: '#666',
        height: 32,
        display: 'flex',
        flexDirection: 'row',
        borderLeftWidth: 1,
        fontWeight: 800,
    },
    tableFooter: {
        borderBottomWidth: 1,
        borderColor: '#666',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        borderLeftWidth: 1,
        fontWeight: 800,
    },
    tableRow: {
        borderBottomWidth: 1,
        borderColor: '#666',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        borderLeftWidth: 1
    },
    tableColumn: {
        paddingLeft: 4,
        height: '100%',
        flex: 1,
        borderRightWidth: 1,
        borderColor: '#888',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
})

const InputField = ({label=undefined, subLabel, value=""}) => {
    return (
        <View style={styles.inputContainer}>
            {
                label && 
                    <View style={styles.inputLeft}>
                        <Text style={styles.text}>{label}</Text>
                    </View>
            } 
            <View style={styles.inputRight}>
                <View style={styles.inputField}>
                    <Text style={styles.text}>{value}</Text>
                </View>
                <Text style={styles.inputSubLabel}>{subLabel}</Text>
            </View>
        </View>
    )
}

const TableHeader = () => {
    return (
        <View style={styles.tableHeader}>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>Datum</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>Beginn</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>Ende</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>Kilometer</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>Stunden</Text>
            </View>
        </View>
    )
}

const TableRow = ({ entry, ...props}) => {

    const [date, startTime, endTime, kilometer, hours] = useMemo(() => {
        if(!entry) {
            return [
                "",
                "",
                "",
                "",
                "",
                ""
            ]
        }


        return [
            moment(entry.date).format('DD.MM'),
            entry.startTime,
            entry.endTime,
            formatFloat(entry.kilometer),
            formatFloat(entry.hours)
        ]
    },[entry])

    return (
        <View style={styles.tableRow} {...props}>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{date}</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{startTime}</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{endTime}</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{kilometer}</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{hours}</Text>
            </View>
        </View>
    )
}

const TableFooter = ({ report }) => {

    return (
        <View style={styles.tableFooter}>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>Summe</Text>
            </View>
            <View style={styles.tableColumn}>
            </View>
            <View style={styles.tableColumn}>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{formatFloat(report.kilometer)}</Text>
            </View>
            <View style={styles.tableColumn}>
                <Text style={styles.text}>{formatFloat(report.hours)}</Text>
            </View>
        </View>
    )
}


const OperationReportDocument = ({ report }) => {

    const [employeeName, yearMonth] = useMemo(() => {
        if(!report) {
            return [
                "",
                ""
            ]
        }
        
        return [
            `${report?.employeeFirstName} ${report?.employeeLastName}`,
            moment(report.yearMonth).format('MMM/YYYY')
        ]
    }, [report])

    return (
        <View style={styles.page}>
            <View style={styles.header}>
                <View style={styles.inputArea}>
                    <InputField label="Arbeitszeit von" subLabel={"(Vorname und Nachname Mitarbeiter)"} value={employeeName}/>
                    <InputField label="Vom 1. bis" subLabel={"(Monat/Jahr)"} value={yearMonth}/>
                </View>
                <View style={styles.logoArea}>
                    <Image style={styles.logo} src={logo}/>
                    <View style={styles.contactDetails}>
                        <Text style={styles.smallBoldText}>MIT HERZ und HAND</Text>
                        <Text style={styles.smallText}>Haushalts- und Seniorendienst</Text>
                        <Text style={styles.smallBoldText}>Sandra Umlauf</Text>
                        <Text style={styles.smallText}>Heidackerstr. 63</Text>
                        <Text style={styles.smallText}>44793 Bochum</Text>
                        <Text style={styles.smallText}>Tel.: 0234-97888048</Text>
                        <Text style={styles.smallText}>info@mitherzundhand.net</Text>
                    </View>
                </View>
            </View>
            <View style={styles.tableArea}>
                <View style={styles.table}>
                    <TableHeader/>
                    {
                        [...Array(24)].map((e, index) => {
                            var entry = report && report.entries.length > index ? report.entries[index]:  null
                            return (
                                <TableRow key={index} entry={entry}/>
                            )
                        })
                    }
                    <TableFooter report={report}/>
                </View>
            </View>
            <View style={styles.alertArea}>
                <Text style={styles.text}>
                    Achtung: Hinweise zum Kunden (Name, Anschrift etc.) dürfen aus 
                    datenschutzrechtlichen Gründen nicht mehr aufgeführt werden.
                </Text>
            </View>
            <View style={styles.footer}>
                <View style={styles.employeeSignatureArea}>
                    <InputField subLabel={"Datum/Unterschrift Arbeitnehmer"} />
                </View>
                <View style={styles.companySignatureArea}>
                    <InputField subLabel={"Datum/Unterschrift Arbeitgeber"} />
                </View>
            </View>
        </View>
    )
}

export default OperationReportDocument