import React, { useEffect, useState } from "react";
import Dropdown from "../components/dropdown/dropdown";
import ameli from "Api/ameli";

export default function RelationshipSelect(props) {

    const [options, setOptions] = useState([])

    useEffect(() => {
        ameli.get("/relationships")
            .then(response => {
                const newOptions = response.data.map(relationship => {
                    return { value: relationship.id, label: relationship.label }
                })
                setOptions(newOptions)
            })
            .catch(error => setOptions([]))
    }, []);

    return (
        <Dropdown options={options} {...props}></Dropdown>
    )
}