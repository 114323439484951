import { useQuery } from '@tanstack/react-query';
import EmployeeService from './EmployeeService';
import { useApiContext } from 'Common/Api/ApiContext';

export interface EmployeeServiceGetParams {
    employeeId?: number
}

export function useEmployeeServices(params: EmployeeServiceGetParams) {
    const { axiosInstance } = useApiContext()

    return useQuery({
        queryKey: ['employee_services', params],
        queryFn: () => axiosInstance.get<EmployeeService[]>(`employee_services`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useEmployeeService(id) {
    const { axiosInstance } = useApiContext()

    return useQuery({
        queryKey: ['employee_services', id],
        queryFn: () => axiosInstance.get<EmployeeService>(`employee_services/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}