import ClickableOpacity from 'components/Decorator/ClickableOpacity'
import { IconContext } from 'react-icons'
import styled from 'styled-components'

const Wrapper = styled.div`
    transition: 0.3s;
    padding: 0.15em;
    display: inline-flex;
    justify-content: center;
    width: 1.2em;
`

interface Props {
    children: React.ReactNode,
    size?: string,
    color?: string,
    onClick?:() => any
}

const IconWrapper = ({ 
    size = '20', 
    color = '#b2b2b2', 
    onClick, 
    children 
}:Props) => {

    return (
        <Wrapper>
            <ClickableOpacity onClick={onClick}>
                <IconContext.Provider value={{ color, size}}>
                    { children }
                </IconContext.Provider>
            </ClickableOpacity>
        </Wrapper>
    )
}

export default IconWrapper