import ListView from "components/ListView/ListView";
import NoteCard from "./NoteListItem";
import Spacer from "components/Spacer/Spacer";



export default function NoteList({notes, ...props}) {

    const handleClick = (note) => {
        if(typeof props.onClick === 'function') {
            props.onClick(note);
        }
    }

    return (
        <ListView
            placeholder="Keine Anmerkungen vorhanden"
            data={notes}
            Separator={() => (<Spacer vTiny/>)}
            listItem={(note) => (
                <NoteCard 
                    note={note}
                    onClick={handleClick}
                />
            )}
        />
    )
}