import useApiData from "hooks/useApiData"
import { useCallback } from "react"
import OperationReport from "./OperationReport"
import ameli from "Api/ameli"

const channel = ['OperationReports']

const useOperationReports = (params) => {
    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get('operation_reports', { params })
            .then(response => {
                resolve(response.data
                    .map(OperationReport.fromDatabase)
                )
            })
    }), [params])

    const state = useApiData(loadHandler, channel)

    return state
}

export default useOperationReports