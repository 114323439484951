import { useForm } from "components/form/form";
import Modal, { ModalFooter } from "components/modal/Modal";
import BillForm from "./BillForm";
import { useBillTitle } from "./BillTitle";
import SubmitButton from '../Forms/SubmitButton';
import useBillActions from "./useBillActions";
import Spacer from "components/Spacer/Spacer";
import useBillForm from "./useBillForm";

export default function BillModal({
    visible,
    setVisible,
    selected
}) {
    
    const { title, subTitle } = useBillTitle(selected);
    const form = useBillForm(selected)

    const isUpdate = 'bill_id' in form.data;
    const { update } = useBillActions(() => {
        setVisible(false)
    })

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            title={title}
            subTitle={subTitle}>
            <Spacer medium>
                <BillForm form={form}/>
                <ModalFooter>
                    <SubmitButton
                        visible={isUpdate}
                        disabled={!form.fieldsValid || !form.fieldsChanged}
                        onClick={() => update.mutate(form.data)}>
                        Übernehmen
                    </SubmitButton>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}