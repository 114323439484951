import React, { ReactNode } from "react"
import { IModal } from "./useModal"

const ModalInternalContext = React.createContext<IModal<any>>({
    selected: null,
    visible: false,
    setVisible: () => {},
    select: () => {}
})

interface Props extends IModal<any> {
    children?: ReactNode
}

const ModalInternalProvider = ({ setVisible, visible, select, selected, ...props } : Props) => {

    return (
        <ModalInternalContext.Provider value={{
            setVisible,
            visible,
            select,
            selected
        }} {...props}/>
    )
}

export { ModalInternalContext, ModalInternalProvider }