import { useCallback, useEffect, useMemo, useReducer } from "react"
import { getObjectProperty, setObjectProperty as setObjectProperty } from "./ObjectUtility"
import { instantiateProperty, ObjectPropertyInstance, PropertyInstance, PropertyInstances } from "./PropertyInstance"
import { ObjectPropertyDefinition } from "./PropertyDefinition"

type SetPropertiesAction = {
    type: 'setProperties',
    payload: Object
}

type SetPropertyAction = {
    type: 'setProperty',
    path: string
    payload: any
}

type EnablePropertyAction = {
    type: 'enableProperty',
    path: string
    payload: boolean
}

type Action = SetPropertiesAction | SetPropertyAction | EnablePropertyAction


export type ObjectData = {
    values: Object,
    enabled: Object
}

type State = ObjectData

const reducer = (state:State, action:Action) => {
    const { ...values } = state?.values || {}
    const { ...enabled } = state?.enabled || {}

    switch(action.type) {
        case 'setProperties':
            return {
                values: {...action.payload },
                enabled
            }
        case 'setProperty': 
            return {
                ...state, 
                values: setObjectProperty(values, action.path, action.payload),
            }
        case 'enableProperty':
            enabled[action.path] = action.payload
            return {
                values,
                enabled: {...enabled}
            }
        default:
            return state
    }
}

export interface IObject extends ObjectPropertyInstance {
    properties: PropertyInstances,
    getProperty: (path:string) => PropertyInstance,
    setProperty: (path:string, value:any) => void,
    isUpdate: boolean,
    primaryKeyProperty: PropertyInstance
}

export default function useObject(definition:ObjectPropertyDefinition, initialValues:Object) : IObject {
    const [state, dispatch] = useReducer(reducer, {
        values: {},
        enabled: {}
    })

    useEffect(() => {
        dispatch({
            type: "setProperties",
            payload: initialValues
        })
    }, [initialValues, dispatch])

    
    const setPropertyValue = useCallback((path:string, value:any) => {
        dispatch({
            type: 'setProperty',
            path,
            payload: value
        })
    }, [dispatch])

    const setPropertyEnabled = useCallback((path: string, value: boolean) => {
        dispatch({
            type: 'enableProperty',
            path,
            payload: value
        })
    }, [dispatch])


    const instance:ObjectPropertyInstance = useMemo(() => {
        return instantiateProperty(
            definition,
            {
                values: state?.values,
                enabled: state?.enabled,
                setValue: (path, value) => setPropertyValue(path, value),
                setEnabled: (path, value) => setPropertyEnabled(path, value)
            },
        ) as ObjectPropertyInstance
    }, [definition, state, dispatch])

    const getProperty = useCallback((path: string) : PropertyInstance => {
        path = `properties/${path.replaceAll('/', '/properties/') }`
        return getObjectProperty(instance, path)
    }, [instance])

    const setProperty = useCallback((path: string, value:any) => {
        getProperty(path)?.setValue(value)
    }, [instance])

    const primaryKeyProperty = useMemo(() => {
        return Object.values(instance.properties).find(property => {
            return property.primaryKey
        })
    }, [instance])

    const isUpdate = useMemo(() => {
        if (!primaryKeyProperty) {
            return false
        }
        return primaryKeyProperty.valid && (primaryKeyProperty.value !== null && primaryKeyProperty.value !== undefined)
    }, [primaryKeyProperty])

    return {
        ...instance,
        getProperty,
        setProperty,
        isUpdate,
        primaryKeyProperty
    }
}