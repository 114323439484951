import { useMemo } from "react";
import Dropdown from "../components/dropdown/dropdown";
import useNationalities from "./useNationalities";

export default function NationalitySelect(props) {

    const { data } = useNationalities()

    const options = useMemo(() => {
        return data?.map(d => ({ value: d.nationality_id, label: d.nationality_label }))
    }, [data])

    return (
        <Dropdown options={options} {...props}></Dropdown>
    );
}