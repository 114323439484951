import Dropdown from "components/dropdown/dropdown";
import { useMemo } from 'react';
import { useBudgetTypes } from "./BudgetApi";
import BudgetType from "./BudgetType";

export default function BudgetTypeSelect({careLevel, onValueChange=(value:BudgetType) => {}, ...props}) {

    const params = useMemo(() => ({
        care_level: careLevel
    }), [careLevel])

    const { data: budgetTypes } = useBudgetTypes(params)

    const relevantTypes = useMemo(() => {
        return budgetTypes?.filter(type => {
            if(!careLevel) {
                return type.required_care_level == null
            }
            if(type.required_care_level == null) {
                return true
            }
            return type.required_care_level <= careLevel
        })
    }, [careLevel, budgetTypes])

    const handleChange = (event) => {
        var id = event.target.value;
        if(props.onChange) {
            props.onChange(event)
        }
        onValueChange(budgetTypes.find(type => type.id === id))
    }

    return (
        <Dropdown 
            data={relevantTypes}
            optionFactory={
                budgetType => {
                    return {
                        value: budgetType.id,
                        label: budgetType.label
                    }
                }
            }
            {...props}
            onChange={handleChange}
            >
        </Dropdown>
    )
}