import Dropdown from "components/dropdown/dropdown"

const OPTIONS = [
    {
        value: null,
        label: 'Nicht bestätigt'
    },
    {
        value: false,
        label: 'Abgesagt'
    },
    {
        value: true,
        label: 'Bestätigt'
    },
]

const EventConfirmationSelect = (props) => {
    return (
        <Dropdown
            options={OPTIONS}
            isClearable
            placeholder='Nicht bestätigt'
            {...props}
        />
    )
}

export default EventConfirmationSelect