
const AddressUtility = {
    getGeopositionLabel: ({ latitude, longitude }) => {
        if (latitude == null || longitude == null) {
            return "Unbekannt"
        }
        const decimalPoints = 4
        latitude = parseFloat(latitude).toFixed(decimalPoints)
        longitude = parseFloat(longitude).toFixed(decimalPoints)
        return `[${latitude}, ${longitude}]`
    }
}

export default AddressUtility