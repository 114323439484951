import { useMemo } from "react";
import { CalendarEntries } from "./Calendar";
import Event from "Events/Event";

interface IProps {
    events?:Event[]
}

const useCalendarEntries = ({
    events=[]
}:IProps) : CalendarEntries => {
    const entries = useMemo(() => {
        var entries = {}
        events.forEach(event => {
            entries[event.date] = {
                className: 'event'
            }
        })
        return entries
    }, [events])

    return entries
}

export default useCalendarEntries