import BudgetGroupListItem from './BudgetGroupListItem';
import Checkbox from 'components/checkbox/Checkbox';
import Row from 'components/Row';
import { useMemo } from 'react';
import ListView from 'components/ListView/ListView';
import Spacer from 'components/Spacer/Spacer';
import { BudgetGroup } from './useGroupedBudgets';
import Tile from 'components/Tiles/Tile';

interface Props {
    groups:BudgetGroup[],
    deselected: BudgetGroup[],
    setDeselected: (value:BudgetGroup[]) => any
}

export default function BudgetSelectionControl({ groups, deselected, setDeselected }:Props) {

    const updateSelection = (group:BudgetGroup, isSelected) => {
        var newSelection = deselected?.filter(g => g.id !== group?.id)
        if (!isSelected) {
            newSelection.push(group)
        }
        setDeselected(newSelection)
    }

    const groupSelected = (group:BudgetGroup) : boolean => {
        return !deselected?.find(g => g.id === group.id)
    }

    const displayedGroups = useMemo(() => {
        return groups
    }, [groups])

    if (!displayedGroups || displayedGroups.length === 0) {
        return null
    }

    return (
        <Tile>
            <ListView
                data={displayedGroups}
                listItem={(group:BudgetGroup) => (
                    <Spacer medium>
                        <Row grow gap='0.5em'>
                            <Checkbox
                                value={groupSelected(group)}
                                onChange={(event) => updateSelection(group, event.value)}
                            />
                            <BudgetGroupListItem group={group}/>
                        </Row>
                    </Spacer>
                )}
            />
        </Tile>
    );
}