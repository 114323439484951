import { useMemo } from "react";
import useNationalities from "./useNationalities";
import Label from "components/labels/Label";

interface Props {
    nationalityId: number
}

export default function NationalityLabel({ nationalityId}:Props) {

    const { data } = useNationalities()

    const label = useMemo(() => {
        var nationality = data?.find(d => d.nationality_id === nationalityId)
        return nationality?.nationality_label || "-"
    }, [data, nationalityId])

    return (
        <Label>{label}</Label>
    );
}