import ScrollView from 'components/ScrollView';
import Row from "components/Row";
import Column from "components/Column";
import Spacer from "components/Spacer/Spacer";
import CustomerBudgetSection from "../../../Customer/CustomerBudgetTile";
import CustomerOperationNoteTile from "../../../Customer/CustomerOperationNoteTile";
import CustomerOperationTaskTile from "../../../Customer/CustomerOperationTaskTile";
import Tile from "components/Tiles/Tile";
import { useOutletContext } from "react-router-dom";
import CustomerHeaderBar from "Customer/CustomerHeaderBar";
import { PersonOutletContextType } from "Persons/PersonOutlet";
import ClinicalPictureTile from "../../../ClinicalPictures/ClinicalPictureTile";
import { useClinicalPicture } from "ClinicalPictures/ClinicalPicturesApi";
import { useCustomer } from "Customer/CustomerApi";
import PersonCalendar from "Persons/PersonCalendar";
import CustomerNoteTile from "Customer/CustomerNoteTile";

export default function CustomerProtocolView() {

    const { person } = useOutletContext<PersonOutletContextType>()
    const customer = useCustomer(person?.id)
    const clinicalPicture = useClinicalPicture(person?.clinical_picture_id)
    
    return (
        <>
            <CustomerHeaderBar/>
            <ScrollView>
                <Spacer medium>
                    <Row gap="1em">
                        <Column grow>
                            <CustomerNoteTile customer={customer.data} />
                        </Column>
                        <Column minWidth='45em' gap='0.75em'>
                            <CustomerBudgetSection customer={customer.data} />
                            <ClinicalPictureTile clinicalPicture={clinicalPicture.data} />
                            <CustomerOperationTaskTile customerId={person.id} />
                            <CustomerOperationNoteTile customerId={person.id} />
                            <Tile>
                                <PersonCalendar person={person} />
                            </Tile>
                        </Column>
                    </Row>
                </Spacer>    
            </ScrollView>
        </>
    )
}