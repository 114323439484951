import { useSignalHandler } from "Signals/useSignals"
import { useState, useReducer, useCallback, useEffect } from "react"


const reducer = (state, action) => {
    switch (action.type) {
        case 'set_is_pending':
            return { ...state, isPending: true }
        case 'set_data':
            return { ...state, data: action.payload, isPending: false }
        default:
            return state
    }
}

const useApiData = (loadFunction, channel) => {
    const [isPending, setIsPending] = useState(false)

    const [state, dispatch] = useReducer(reducer, {
        data: [],
        isPending: true
    })


    const reload = useCallback(() => new Promise((resolve, reject) => {
        setIsPending(true)
        loadFunction()
            .then((data) => {
                dispatch({ type: 'set_data', payload: data })
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    }), [dispatch, loadFunction, setIsPending])

    useSignalHandler(channel, reload)


    useEffect(() => {
        reload()
    }, [reload])

    return { isPending, ...state }
}

export default useApiData