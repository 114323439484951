import DropdownInput from "components/dropdown/dropdown-input";
import { FormGroup } from "components/form/FormComponents";
import TextArea from "components/input/TextArea";
import React, { useMemo } from "react";
import OperationTaskTypeSelect from "./OperationTaskTypeSelect";
import useOperationTaskTitles from './useOperationTaskTitltes';
import useOperationTaskSubtitles from './useOperationTaskSubtitles';

export default function OperationTaskForm({formData, handleFormChange}) {

    const { data: titles } = useOperationTaskTitles()
    const { data: subtitles } = useOperationTaskSubtitles()

    const titleOptions = useMemo(() => {
        return titles
                .filter(t => t.type_id === formData.type_id)
                .map((type) => {
                    return {
                        value: type.label,
                        label: type.label
                    }
                })
    }, [formData.type_id, titles])

    const subtitleOptions = useMemo(() => {
        const fittingSubtitles = subtitles.filter(s => s.title_label === formData.title)
        if(fittingSubtitles.length === 0) {
            return [
                { label: "Jede Woche", value: "Jede Woche" },
                { label: "Alle 14 Tage", value: "Alle 14 Tage" },
                { label: "Einmal monatlich", value: "Einmal monatlich" },
                { label: "Vierteljährlich", value: "Vierteljährlich" },
                { label: "Halbjährlich", value: "Halbjährlich" },
                { label: "Einmal jährlich", value: "Einmal jährlich" },
            ]
        }
        else {
            return fittingSubtitles.map((type) => {
                return {
                    value: type.label,
                    label: type.label
                }
            })
        }
    }, [formData.title, subtitles])
   

    return (
        <div>
            <FormGroup>
                <OperationTaskTypeSelect
                    label='Aufgabenbereich'
                    name='type_id'
                    value={formData.type_id}
                    onChange={handleFormChange}
                />
                <DropdownInput
                    label='Titel' 
                    options={titleOptions}
                    name='title'
                    value={formData.title ?? ""}
                    onChange={handleFormChange}
                />
                <DropdownInput
                    label='Details'
                    isMulti
                    options={subtitleOptions}
                    name='subtitle'
                    value={formData.subtitle}
                    onChange={handleFormChange}
                />
                <TextArea
                    label='Beschreibung'
                    minRows={6}
                    name='details'
                    value={formData.details ?? ""}
                    onChange={handleFormChange}
                />
            </FormGroup>
        </div>
    );
}