import { useMemo } from "react";
import { generateDateField, generatePositiveFloatField, generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory";


export default function useBudgetCreateFormFields(data) {
    return useMemo(() => ({
        owner_id: generatePositiveIntegerField(data?.owner_id, true),
        budget_type_id: generatePositiveIntegerField(data?.budget_type_id, true),
        amount: generatePositiveFloatField(data?.amount, true),
        period_begin: generateDateField(data?.period_begin, true),
        period_end: generateDateField(data?.period_end, true),
        note: generateTextField(data?.note, false),
        decline_code: generateTextField(data?.decline_code, false),
        steps: generateTextField(data?.steps, false),
    }), [data])
}