import CurrencyLabel from "components/labels/currency-label";
import DateLabel from "components/labels/DateLabel";
import YearMonthLabel from "components/labels/year-month-label";
import React, { useMemo } from "react";
import { compareStrings } from "utils/string-utility";
import Label from "components/labels/Label";
import TableCell from "components/table/Cells/TableCell";

export function useUnpaidTableColumns(props) {

    const columns = useMemo(() =>
        [
            {
                Header: 'Kunde',
                accessor: d => `${d.first_name} ${d.last_name}`,
                sortType: (rowA, rowB) => {
                    const comparison = compareStrings(rowA.original.last_name, rowB.original.last_name);
                    if(comparison === 0) {
                        return compareStrings(rowA.original.first_name, rowB.original.first_name);
                    }
                    return comparison;
                },
                Cell: ({value, ...props}) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Vers.-Nr.',
                accessor: 'health_insurance_number',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Krankenkasse',
                accessor: 'health_insurance_name',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <Label>{value}</Label>
                        )}
                    />
                ),
            },
            {
                Header: 'Monat',
                accessor: 'bill_date',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <YearMonthLabel value={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Gefordert am',
                accessor: data => data.bill_request_date,
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Forderung',
                accessor: data => parseFloat(data.request_amount),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value} />
                        )}
                    />
                ),
                Footer: (data) => {
                    var requestSum = 0;
                    data.flatRows.forEach(row => {
                        requestSum += parseFloat(row.values.Forderung);
                    });
                    return (
                        <CurrencyLabel bold value={requestSum}/>
                    )
                }
            },
            {
                Header: 'Geldeingang am',
                accessor: 'bill_collection_date',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <DateLabel date={value} />
                        )}
                    />
                ),
            },
            {
                Header: 'Gezahlt',
                accessor: 'bill_collection_amount',
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel value={value} />
                        )}
                    />
                ),
                Footer: (data) => {
                    var collectionSum = 0;
                    data.flatRows.forEach(row => {
                        collectionSum += parseFloat(row.values.bill_collection_amount);
                    });
                    return (
                        <CurrencyLabel bold value={collectionSum}/>
                    )
                }
            },
            {
                Header: 'Differenz',
                accessor: data => parseFloat(data.bill_collection_amount) - parseFloat(data.request_amount),
                Cell: ({ value, ...props }) => (
                    <TableCell
                        {...props}
                        renderLabel={() => (
                            <CurrencyLabel colored={true} value={value} />
                        )}
                    />
                ),
                Footer: (data) => {
                    var differenceSum = 0;
                    data.flatRows.forEach(row => {
                        differenceSum += parseFloat(row.values.Differenz);
                    });
                    return (
                        <CurrencyLabel bold colored={true} value={differenceSum}/>
                    )
                }
            },
        ],
        []
    );

    return { columns };
}