import { useMemo } from "react";
import Dropdown from "../components/dropdown/dropdown";
import useTitles from "./useTitles";

export default function TitleSelect(props) {

    const titles = useTitles()

    const options = useMemo(() => {
        if(!titles.data) {
            return []
        }
        return titles.data?.map((title) => ({
            value: title.title_id,
            label: title.title_label
        }))
    }, [titles.data])

    return (
        <Dropdown options={options} {...props}></Dropdown>      
    )
}
