import { useCallback, useEffect, useReducer } from "react";
import tt from '@tomtom-international/web-sdk-maps';
import useOptions from "hooks/useOptions";

const defaultOptions = {
    accessor: data => [data.longitude, data.latitude],
    markerClass: 'marker',
    onClick: data => {}
}

export default function useMarkers(data, map, options) {

    const appliedOptions = useOptions(options, defaultOptions);

    const reducer = (state, action) => {
        switch(action.type) {
            case 'clear-markers': {
                state.forEach(marker => {
                    marker.remove();
                })
                return state;
            }
            case 'assign': {
                return action.value;
            }
            default: 
                break;
        }
        return state;
    }

    const [, dispatch] = useReducer(reducer, []);

    const generatePopupContent = useCallback((popup, data) => {
        if (typeof appliedOptions.popupContent === 'function') {
            popup.setHTML(appliedOptions.popupContent(data));
        }
    }, [appliedOptions])


    const createPopup = useCallback((data) => {
        let popup = new tt.Popup({
            offset: {
                top: [0, 0],
                bottom: [0, -40],
                'bottom-right': [0, -70],
                'bottom-left': [0, -70],
                left: [25, -35],
                right: [-25, -35]
            },
            closeButton: false,
            closeOnClick: false,
        });
        generatePopupContent(popup, data);
        return popup;
    }, [generatePopupContent])


    const clearMarkers = useCallback(() => {
        dispatch({
            type: 'clear-markers'
        })
    }, [dispatch])

    const createMarker = useCallback((map, data) => {
        let element = document.createElement('div');
        element.className = `marker ${appliedOptions.markerClass ?? ""}`;

        let marker = new tt.Marker(element)
            .setLngLat(appliedOptions.accessor(data))
          
        if(map) {
            marker.addTo(map)
        }

        marker.getElement().addEventListener("click", (event) => {
            appliedOptions.onClick(data, marker);
        });

        if ('popupContent' in appliedOptions) {
            const popup = createPopup(data);
            marker.setPopup(popup);
            marker.togglePopup();
        }

        return marker;
    }, [createPopup, appliedOptions]);


    const createMarkers = useCallback((map, data) => {
        let newMarkers = [];

        clearMarkers();
        data.forEach(dataEntry => {
            newMarkers.push(createMarker(map, dataEntry));
        })
        dispatch({
            type:'assign',
            value: newMarkers
        })
    }, [createMarker, dispatch, clearMarkers]);

    useEffect(() => {
        if(Array.isArray(data)) {
            createMarkers(map, data);
        }
        else if(data) {
            createMarkers(map, [data]);
        }
        else {
            clearMarkers();
        }
    }, [data, map, createMarkers, clearMarkers])   
}