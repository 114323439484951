import { useMemo } from "react";
import Dropdown from "components/dropdown/BetterDropdown";
import { useServices } from "Common/Services/ServiceApi";

export default function ServiceSelect(props) {

    const params = useMemo(() => ({}), [])

    const { data } = useServices(params)

    return (
        <Dropdown 
            data={data}
            getLabel={(value => value.label)}
            {...props}>
        </Dropdown>
    );
}
