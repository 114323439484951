import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import InputWrapper from './InputWrapper';
import { useState } from 'react';
import useKeyListener from 'hooks/use-key-listener';

const InputField = styled(TextareaAutosize)`
    border: none;
    font-size: 1em;
    background-color: transparent;
    padding: 0.5em 0.5em;
    transition: 0.3s;
    font-family: lato;
    width: 96%;
    resize: vertical;

    &:hover {
        border-color: #aaa;
    }

    &:focus {
        border-color: #ce0069;
        outline:none;
`

const TextArea = ({label=undefined, optional=true, ...props}) => {

    const [focused, setFocused] = useState(false)

    useKeyListener(['Enter'], () => {
        if(props.onChange) {
            props.onChange({
                target: {
                    value: [props.value, '\n'].filter(e => e).join('')
                }
            })
        }
    })

    return (
        <InputWrapper focused={focused} label={label} optional={optional}>
            <InputField 
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                {...props}
            />
        </InputWrapper>
    )
}

export default TextArea
