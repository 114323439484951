import ModelListTile from "Models/ModelTile"
import { useMemo } from "react"
import { IModelInstanceModalOptions } from "Models/ModelInstanceModal"
import { TagProps } from "Tags/TagContainer"
import { formatFloat } from "utils/string-utility"
import Theme from "Theming/Theme"
import ListItem from "components/ListView/BetterListItem"
import CustomerService from "Common/CustomerServices/CustomerService"
import { useCustomerServices } from "Common/CustomerServices/CustomerServiceApi"
import CustomerServiceModel from "Common/CustomerServices/CustomerServiceModel"

type CustomerServiceTileProps = {
    customerId: number
}

const modalOptions:IModelInstanceModalOptions = {
    formOptions: {
        hidden: ['id', 'updatedAt', 'createdAt', 'serviceId']
    }
}

export default function CustomerServiceTile({
    customerId
}:CustomerServiceTileProps) {


    const initialData = useMemo(() => {
        return {
            customerId
        }
    }, [customerId])

    const serviceParams = useMemo(() => {
        return {
            customerId
        }
    }, [customerId])

    const services = useCustomerServices(serviceParams)

    return (
        <ModelListTile<CustomerService>
            model={CustomerServiceModel}
            data={services.data}
            initialData={initialData}
            modalOptions={modalOptions}
            listItem={(service, options) => {
                const tags:TagProps[] = []

                if (service.squareMeter) {
                    tags.push({
                        text: formatFloat(service.squareMeter, 'qm')
                    })
                }
                if (service.details) {
                    tags.push({
                        text: 'Details',
                        backgroundColor: Theme.subtleColor
                    })
                }
                return (
                    <ListItem
                        {...options}
                        title={service.service?.label}
                        tags={tags}
                    />
                )
            }}
        />
    )
}