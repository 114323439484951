import Label from "components/labels/Label"
import { useMemo } from 'react';
import { localizeDateString } from '../utils/timeStringUtility';

const CareLevelLabel = ({careLevel, referenceDate, ...props}) => {

    const careLevelText = useMemo(() => {
        if(!careLevel || careLevel <= 0) {
            return "Kein Pflegegrad"
        }
        return `Pflegegrad ${String(careLevel)}`
    }, [careLevel])

    const referenceText = useMemo(() => {
        if(!referenceDate) {
            return ""
        }
        return `(seit ${localizeDateString(referenceDate)})`
    }, [referenceDate])

    return (
        <Label {...props}>
            {`${careLevelText} ${referenceText}`}
        </Label>
    )
}

export default CareLevelLabel