import ameli from "Api/ameli";
import Dropdown from "components/dropdown/dropdown";
import React, { useEffect, useState } from "react";

export default function PhoneTypeSelect({...props }) {
    const [types, setTypes] = useState([]);

    useEffect(() => {
        ameli.get(`/phone_numbers/types`)
            .then(response => {
                setTypes(response.data);
            })
            .catch(error => {
                setTypes([]);
            })
    }, [setTypes])

    return (
        <Dropdown
            data={types}
            optionFactory={
                type => {
                    return {
                        value: type.id,
                        label: type.label
                    };
                } }
            {...props}>
        </Dropdown>
    )
}