import Modal, { ModalFooter } from "components/modal/Modal";
import useVacationRuleFields from "./useVacationRuleFields";
import useForm from "components/form/useForm";
import Spacer from "components/Spacer/Spacer";
import VacationRuleForm from "./VacationRuleForm";
import SubmitButton from "Forms/SubmitButton";

export default function VacationRuleModal({
    selected: rule,
    visible,
    setVisible,
    dispatch
}) {

    const fields = useVacationRuleFields(rule)
    const form = useForm(fields)
    const isUpdate = 'id' in form.data

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            title={isUpdate ? "Urlaubsregel bearbeiten" : "Urlaubsregel erfassen"}>
            <Spacer medium>
                <VacationRuleForm 
                    {...form} 
                    operationMonths={rule.operationMonths}
                    operationDays={rule.operationDays}
                    usedVacationDays={rule.usedVacationDays}
                    entryMultiplier={rule.entryMultiplier}
                />
            </Spacer>
            <ModalFooter>
                {
                    isUpdate ?
                        <>
                            <SubmitButton
                                disabled={!form.fieldsChanged || !form.fieldsValid}
                                onClick={() => dispatch('update_rule', form.data).then(() => setVisible(false))}
                            >
                                Übernehmen
                            </SubmitButton>
                            <SubmitButton
                                onClick={() => dispatch('delete_rule', form.data.id).then(() => setVisible(false))}
                            >
                                Löschen
                            </SubmitButton>
                        </>
                        :
                        <SubmitButton
                            disabled={!form.fieldsValid}
                            onClick={() => dispatch('create_rule', form.data).then(()=> setVisible(false))}
                        >
                            Erstellen
                        </SubmitButton>
                }

            </ModalFooter>
        </Modal>
    )
}