import { useEffect, useState } from "react";
import Dropdown from "../components/dropdown/dropdown";
import ameli from "Api/ameli";

export default function CancelationTypeSelect(props) {

    const [options, setOptions] = useState([])

    useEffect(() => {
        ameli.get("/cancelation_types")
            .then(response => {
                const newOptions = response.data.map(careLevel => {
                    return {
                        value: careLevel.id,
                        label: careLevel.label
                    }
                })
                setOptions([{ value: null, label: "-"}, ...newOptions])
            })
            .catch(error => setOptions([]))
    }, []);

    return (
        <Dropdown 
            options={options}
            {...props}>             
        </Dropdown>
    )
}