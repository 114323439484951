import { useMemo } from "react";
import Budget from "./Budget";
import { generateDateField, generatePositiveFloatField, generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory";


export default function useBudgetFormFields(data:Budget) {
    return useMemo(() => ({
        budget_id: generatePositiveIntegerField(data?.budget_id, false),
        budget_owner_id: generatePositiveIntegerField(data?.budget_owner_id, true),
        budget_type_id: generatePositiveIntegerField(data?.budget_type_id, true),
        budget_amount: generatePositiveFloatField(data?.budget_amount, true),
        budget_begin_date: generateDateField(data?.budget_begin_date, true),
        budget_decline_date: generateDateField(data?.budget_decline_date, true),
        budget_note: generateTextField(data?.budget_note, false)
    }), [data])
}