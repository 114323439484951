import { FormGroup } from "components/form/FormComponents";
import IForm from "Forms/IForm";
import Checkbox from "components/checkbox/Checkbox";
import CustomerStateSelect from "./CustomerStateSelect";

interface Props {
    form: IForm
}


export default function CustomerForm({form}:Props) { 

    return (
        <div>
            <FormGroup>
                <CustomerStateSelect
                    label='Status'
                    onChange={({ value }) => form.setFieldValue('state', value)}
                    value={form.data.state}
                />
                <Checkbox
                    optional
                    label='Selbstzahler'
                    value={form.getFieldValue('direct_payer')}
                    onChange={({ value }) => form.setFieldValue('direct_payer', value)}
                    name='direct_payer'
                />
                <Checkbox
                    optional
                    label='Betreuungsleistung erneuern'
                    value={form.getFieldValue('has_bl')}
                    onChange={({ value }) => form.setFieldValue('has_bl', value)}
                    name='has_bl'
                />
            </FormGroup>     
        </div>
    )
}