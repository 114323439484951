import Action, { ActionOptions } from "../Actions/Action";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Theme from '../../Theming/Theme';
import { PartialObject } from "lodash";
import { IObject } from "../Objects/useObject";
import { useCallback, useMemo } from "react";
import { useApiContext } from "./ApiContext";

export default function usePatch<T>(apiPath:string, queryKeys:string[], object:IObject) : Action {
    const queryClient = useQueryClient()
    const { axiosInstance } = useApiContext()

    const mutation = useMutation({
        mutationKey: [apiPath, 'patch'],
        mutationFn: (data: PartialObject<T>) => axiosInstance.patch(apiPath, data),
    })

    const execute = useCallback((options?:ActionOptions) => {
        return mutation.mutate(object.value, {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [apiPath]
                })
                queryKeys?.forEach(key => {
                    queryClient.invalidateQueries({
                        queryKey: [key]
                    })
                })
                options?.onSuccess()
            },
            onError: () => {
                options?.onError()
            },
            onSettled: () => {
                options?.onSettled()
            }
        })
    }, [mutation, queryClient, object, apiPath, queryKeys])

    const disabled = useMemo(() => {
        return !(object.valid && object.isUpdate)
    }, [object])

    const visible = useMemo(() => {
        return object.isUpdate
    }, [object])

    return {
        label: 'Speichern',
        error: mutation.error,
        color:Theme.whiteColor,
        backgroundColor: Theme.primaryColor,
        isPending: mutation.isPending,
        execute,
        disabled,
        visible
    }
}