function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

export function toGeoLocation(latitude, longitude) {
    return { latitude, longitude }
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
    if(lat1==undefined || lat2==undefined || lon1 == undefined || lon2 == undefined) {
        return null
    }
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

export const clearSourceAndLayer = (map, id) => {
    if (map && map.isStyleLoaded() && map.getLayer(id)) {
        map.removeLayer(id);
        map.removeSource(id);
    }
}

export const drawRoute = (id, geoJson, map, color = '#ce0069') => {
    if (map.getLayer(id)) {
        map.removeLayer(id);
        map.removeSource(id);
    }

    map.addLayer({
        id: id,
        type: 'line',
        source: {
            type: 'geojson',
            data: geoJson
        },
        paint: {
            'line-color': color,
            'line-width': 6
        }
    }) 
}

export const drawCircle = (map, layerId, latitude, longitude, zoom, radius) => {
    if (map && map.isStyleLoaded()) {
        radius = radius ? parseFloat(radius) : 0;

        const geoJson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude]
                    },
                }
            ]
        };

        if (map.getSource(layerId)) {
            map.removeLayer(layerId);
            map.removeSource(layerId);
        }

        map.addSource(layerId, {
            type: 'geojson',
            data: geoJson,
            cluster: true,
            clusterRadius: 10000
        });
        map.addLayer({
            'id': layerId,
            'type': 'circle',
            'source': layerId,
            'paint': {
                'circle-color': '#004B7F',
                'circle-radius': radius * 3000 * (Math.pow(2.0, zoom) / 156543),
                'circle-opacity': 0.5
            }
        });
    }
}