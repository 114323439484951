import { generateDateField, generatePositiveFloatField, generatePositiveIntegerField } from "Forms/formFieldFactory"
import { useMemo } from "react"

const useAssignmentFields = (assignment) => {

    const fields = useMemo(() => {

        return {
            id: generatePositiveIntegerField(assignment?.id, false),
            customer_id: generatePositiveIntegerField(assignment?.customer_id, true),
            employee_id: generatePositiveIntegerField(assignment?.employee_id, false),
            start_date: generateDateField(assignment?.start_date, false),
            end_date: generateDateField(assignment?.end_date, false),
            monthly_hours: generatePositiveFloatField(assignment?.monthly_hours || 4.0, true),
            advertise: generatePositiveFloatField(assignment?.advertise, false)
        }
    }, [assignment])

    return fields
}

export default useAssignmentFields