import moment from 'moment';

interface DataTypeOptions {
    allowNull?:boolean,
    defaultValue?:any
}


export class DataType {

    label:string
    defaultValue:any
    allowNull: boolean

    constructor(label:string, options:DataTypeOptions) {
        const defaultOptions = {
            allowNull: false,
            defaultValue: undefined
        }
        const appliedOptions = Object.assign({}, defaultOptions, options)
        this.label = label
        this.allowNull = appliedOptions.allowNull
        this.defaultValue = appliedOptions.defaultValue
    }

    validate(value: any) {
        return true
    }
    toString() {
        return this.label
    }
    stringify(value:any) :string {
        return String(value)
    }
}

interface FloatOptions extends DataTypeOptions {
    unsigned?: boolean
}

class Float extends DataType {

    unsigned:boolean

    constructor(options:FloatOptions={}) {
        const defaultOptions = {
            unsigned: false
        }
        const appliedOptions = Object.assign({}, defaultOptions, options)
        super('Float', appliedOptions)
        this.unsigned = appliedOptions.unsigned
    }

    validate(value: any) {
        if(value === null && this.allowNull) {
            return true
        }
        if(Number.isNaN(value)) {
            return false
        }
        
        if (this.unsigned && value < 0) {
            return false
        }
        return true
    }

    toString() {
        return `${this.label} (${this.unsigned})`
    }
}

interface IntegerOptions extends DataTypeOptions {
    unsigned?: boolean
}

class Integer extends DataType {

    unsigned: boolean

    constructor(options:IntegerOptions={}) {
        const defaultOptions = {
            unsigned: false
        }
        const appliedOptions = Object.assign({}, defaultOptions, options)
        super('Integer', appliedOptions)
        this.unsigned = appliedOptions.unsigned || false
    }

    validate(value: any) {
        if (value === null && this.allowNull) {
            return true
        }
        if (!Number.isInteger(value)) {
            return false
        }

        if (this.unsigned && value < 0) {
            return false
        }
        return true
    }

    toString() {
        return `${this.label} (${this.unsigned})`
    }
}


class Boolean extends DataType {

    constructor(options: IntegerOptions = {}) {
        const defaultOptions = {
        }
        const appliedOptions = Object.assign({}, defaultOptions, options)
        super('Boolean', appliedOptions)
    }

    validate(value: boolean) {
        if (value === null && this.allowNull) {
            return true
        }
        return true
    }

    toString() {
        return `${this.label})`
    }

    stringify(value: any): string {
        if(value === false) {
            return "Nein"
        }
        if(value === true) {
            return "Ja"
        }
        if(value === null) {
            return ""
        }
        return '?'
    }
}


interface TextOptions extends DataTypeOptions {
    length?: number
}

class Text extends DataType {

    length: number

    constructor(options:TextOptions={}) {
        const defaultOptions = {}
        const appliedOptions = Object.assign({}, defaultOptions, options)
        super('Text', appliedOptions)
        this.length = appliedOptions.length
    }

    validate(value: any) {
        if (value === null && this.allowNull) {
            return true
        }
        if (!(typeof value === 'string' || value instanceof String)) {
            return false
        }
        if(this.length && value.length > this.length) {
            return false
        }
        return true
    }

    toString() {
        return `${this.label} (${this.length})`
    }
}

class TextArea extends Text {
    constructor(options: TextOptions = {}) {
        const defaultOptions = {}
        const appliedOptions = Object.assign({}, defaultOptions, options)
        super(appliedOptions)
        this.label = "TextArea"
    }
}


interface DateOptions extends DataTypeOptions {
    format?: string
}

class Date extends DataType {

    format: string

    constructor(options: DateOptions = {}) {
        const defaultOptions = {}
        const appliedOptions = Object.assign({}, defaultOptions, options)
        super('Date', appliedOptions)
        this.format = appliedOptions.format
    }

    validate(value: any) {
        if (value === null && this.allowNull) {
            return true
        }
        if(value === null || value === undefined) {
            return false
        }

        return moment(value).isValid()
    }

    toString() {
        return `${this.label} (${this.format})`
    }

    stringify(value: any): string {
        if (this.validate(value)) {
            return moment(value).format(this.format)
        }
        return '?'
    }
}

const DataTypes = {
    Integer,
    Float,
    Text,
    Boolean,
    Date,
    TextArea
}

export default DataTypes