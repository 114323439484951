import { useForm } from "components/form/form";
import Modal, { ModalFooter } from "components/modal/Modal";
import NoteForm from "../Notes/NoteForm";
import Spacer from "components/Spacer/Spacer";
import SubmitButton from 'Forms/SubmitButton';

export default function NoteModal({
    selected:data, 
    visible, 
    setVisible,
    dispatchAction
}) {

    const title = data && 'id' in data ? 'Notiz bearbeiten' : 'Notiz erstellen';
    const isUpdate = data && 'id' in data;

    const {
        formData,
        handleFormChange,
        formDataChanged,
        formDataValid
    } = useForm(data);

    return (
        <Modal
            minWidth={'30em'}
            visible={visible}
            setVisible={setVisible}
            title={title}>
            { formData && 
                <Spacer medium>
                    <NoteForm 
                        formData={formData} 
                        handleChange={handleFormChange}
                    />
                    <ModalFooter>
                        <SubmitButton
                            visible={!isUpdate}
                            disabled={!formDataValid || !formDataChanged}
                            onClick={() => dispatchAction('create', formData).then(() => setVisible(false))}>
                            Erfassen
                        </SubmitButton>
                        <SubmitButton
                            visible={isUpdate}
                            disabled={!formDataValid || !formDataChanged}
                            onClick={() => dispatchAction('update', formData).then(() => setVisible(false))}>
                            Übernehmen
                        </SubmitButton>
                        <SubmitButton
                            visible={isUpdate}
                            onClick={() => dispatchAction('delete', formData.id).then(() => setVisible(false))}>
                            Löschen
                        </SubmitButton>
                    </ModalFooter>
                </Spacer>
            }
        </Modal>
    )
}