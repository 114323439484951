import moment, { Moment } from "moment"
import OperationReportEntry from "./Entries/OperationReportEntry"

class OperationReport {
    id?:number
    employeeId?:number
    employeeFirstName?:string
    employeeLastName?:string
    year?:number
    month?: number
    yearMonth?: Moment
    hours?:number
    kilometer?:number
    entries?: OperationReportEntry[]

    constructor(employeeId, year, month, entries) {
        this.employeeId = employeeId
        this.year = year
        this.month = month
        this.yearMonth = moment().year(year).month(month-1)
        this.setEntries(entries)
    }

    setEntries = (entries) =>{
        this.entries = this.combineEntries(entries)
        this.hours = this.entries.reduce((sum, entry) => sum + entry.hours, 0.0)
        this.kilometer = this.entries.reduce((sum, entry) => sum + entry.kilometer, 0.0)
    }

    combineEntries = (entries) => {
        var combined = {}
        var unassigned = []

        entries.forEach(entry => {
            if(!entry.start) {
                unassigned.push(entry)
                return
            }
            var dateString = entry.start.format('YYYY-MM-DD')
            if (dateString in combined) {
                combined[dateString] = combined[dateString].combine(entry)
            }
            else {
                combined[dateString] = entry
            }
        });

        return [
            ...Object.values(combined),
            ...unassigned
        ].sort((a, b) => !a.start ? b : !b.start ? a : a.start.valueOf() - b.start.valueOf())
    }

    static fromDatabase = (data) => {

        var report = new OperationReport(
            data.employee_id,
            data.year,
            data.month,
            data.entries.map(OperationReportEntry.fromDatabase)
        )
        report.id = data.id
        report.employeeFirstName = data.employee_first_name
        report.employeeLastName = data.employee_last_name

        return report
    }

    static toDatabase = (report) => {
        return {
            id: report.id,
            employeeId: report.employee.employee_id,
            year: report.year,
            month: report.month,
            entries: report.entries.map(OperationReportEntry.toDatabase)
        }
    }

    addEntry = (entry) => {
        if(!entry) {
            return
        }
        this.setEntries([...this.entries, entry])
    }

    removeEntry = (entry) => {
        this.setEntries(this.entries.filter(e => e !== entry))
    }

    valid = () => {
        var entriesValid = true
        this.entries.forEach((entry) => {
            entriesValid = entriesValid && entry.valid()
        })

        return entriesValid && Boolean(this.employeeId) && Boolean(this.year) && Boolean(this.month)
    }
}

export default OperationReport