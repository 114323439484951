import { calculateDistance, clearSourceAndLayer, drawRoute } from "components/map/map-utility";
import { useEffect, useState } from "react";
import * as ttapi from '@tomtom-international/web-sdk-services'

const walkColor = '#00aa00';
const driveColor = '#ce0069';
const walkId = 'walk_route';
const driveId = 'drive_route';

export default function useRouteData(start, end, map) {

    const [routeData, setRouteData] = useState({
        air_distance: 0,
        drive_distance: 0,
        walk_distance: 0,
        drive_duration: 0,
        walk_duration: 0,
    });

    useEffect(() => {
        if (start && end) {
            let promises = [];
            let newRouteData = {
                air_distance: calculateDistance(start.latitude, start.longitude, end.latitude, end.longitude).toFixed(2)
            };

            promises.push(ttapi.services.calculateRoute({
                key: process.env.REACT_APP_TOM_TOM_API_KEY,
                locations: [ start, end],
                routeType: 'fastest',
                traffic: true,
                travelMode: 'car'
            })
                .then(response => {
                    let summary = response.routes[0].summary;
                    newRouteData.drive_geo_json = summary ? response.toGeoJson() : null;
                    newRouteData.drive_distance = summary ? summary.lengthInMeters / 1000.0 : null;
                    newRouteData.drive_duration = summary ? summary.travelTimeInSeconds : null;
                })
                .catch(error => console.log(error)));


            promises.push(ttapi.services.calculateRoute({
                key: process.env.REACT_APP_TOM_TOM_API_KEY,
                locations: [start, end],
                routeType: 'fastest',
                traffic: true,
                travelMode: 'pedestrian'
            })
                .then(response => {
                    let summary = response.routes[0].summary;
                    newRouteData.walk_geo_json = summary ? response.toGeoJson() : null;
                    newRouteData.walk_distance = summary ? summary.lengthInMeters / 1000.0 : null;
                    newRouteData.walk_duration = summary ? summary.travelTimeInSeconds : null;
                })
                .catch(error => console.log(error)));

            Promise.all(promises)
                .then(() => {
                    setRouteData(newRouteData);
                    if (newRouteData.walk_geo_json) drawRoute(walkId, newRouteData.walk_geo_json, map, walkColor);
                    if (newRouteData.drive_geo_json) drawRoute(driveId, newRouteData.drive_geo_json, map, driveColor);
                })
        }
        else {
            setRouteData({
                air_distance: 0,
                drive_distance: 0,
                walk_distance: 0,
                drive_duration: 0,
                walk_duration: 0,
            })
            clearSourceAndLayer(map, walkId);
            clearSourceAndLayer(map, driveId);
        }
    }, [start, end, map])

    return routeData;
}