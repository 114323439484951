import Table from "components/table/table";
import DatePicker from "components/input/DatePicker";
import EuroInput from "components/input/euro-input";
import useBookings from '../Bookings/useBookings';
import {useBookingColumns} from "Bookings/useBookingColumns";
import styled from 'styled-components';
import InputLabel from "components/input/InputLabel";
import { useMemo } from "react";
import Column from "components/Column";
import IForm from "Forms/IForm";

const Container = styled.div`
    max-height: 30em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`

const TableContainer = styled.div`
    flex-shrink 1;
    min-height: 0px;
    overflow: auto;
    display: flex;
`

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

interface Props {
    form: IForm
}

export default function BillForm({form}:Props) {

    const bookingParams = useMemo(() => {
        return form.data.bill_id ? { 
            bill_id: form.data.bill_id 
        } : null
    }, [form.data.bill_id])

    const { columns } = useBookingColumns()
    const { data: bookings } = useBookings(bookingParams)

    return (
        <Container>
            {
                bookings?.length > 0 ?
                    <TableContainer>
                        <Table data={bookings} columns={columns} />
                    </TableContainer>
                    :
                    null
            }
            <Column gap='0.25em'>
                <InputRow>
                    <InputLabel>Forderung</InputLabel>
                    <DatePicker
                        label='Datum'
                        value={form.getFieldValue('bill_request_date')}
                        onChange={(value) => form.setFieldValue('bill_request_date', value)} 
                    />
                    <EuroInput
                        label='Betrag'
                        value={form.getFieldValue('request_amount')}
                        onValueChange={({ value }) => form.setFieldValue('request_amount', value)} 
                    />
                </InputRow>
                <InputRow>
                    <InputLabel>Geldeingang</InputLabel>
                    <DatePicker
                        label="Datum"
                        value={form.getFieldValue('bill_collection_date')}
                        onChange={(value) => form.setFieldValue('bill_collection_date', value)} 
                    />
                    <EuroInput
                        label="Betrag"
                        value={form.getFieldValue('bill_collection_amount')}
                        onValueChange={({value}) => form.setFieldValue('bill_collection_amount', value)} 
                    />
                </InputRow>
            </Column>
        </Container>
    )
}