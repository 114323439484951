import { FormGroup } from "components/form/FormComponents";
import EmployeeSelect from "Employees/EmployeeSelect";
import BetterNumberFormat from "components/input/better-number-format";
import YearSelect from "components/Time/YearSelect";
import TextInput from "components/input/TextInput";
import Row from "components/Row";
import DatePicker from "components/input/DatePicker";
import InputLabel from "components/input/InputLabel";

export default function VacationBookingForm({ 
    data, 
    setFieldValue
}) {

    return (
        <div>
            <FormGroup>
                <EmployeeSelect
                    label='Mitarbeiter'
                    name='employee_id'
                    onChange={({ value }) => setFieldValue('employee_id', value)}
                    value={data.employee_id}>
                </EmployeeSelect>
                <YearSelect
                    label='Belastetes Jahr'
                    onChange={({value}) => setFieldValue('charged_year', value)}
                    value={data.charged_year}
                />
                <Row>
                    <DatePicker
                        grow
                        label='Beginn'
                        name='period_start'
                        onChange={(value) => setFieldValue('period_start', value)}
                        value={data.period_start}
                    />
                    <InputLabel>-</InputLabel>
                    <DatePicker
                        grow
                        label='Ende'
                        name='period_end'
                        onChange={(value) => setFieldValue('period_end', value)}
                        value={data.period_end}
                    />
                </Row>  
                <BetterNumberFormat
                    label='Tage'
                    onChange={(event) => setFieldValue('days', parseFloat(event.currentTarget.value))}
                    allowedDecimalSeparators={[",", "."]}
                    value={data.days}
                />
                <TextInput
                    label='Label'
                    onChange={(event) => setFieldValue('label', event.target.value)}
                    value={data.label}
                />
            </FormGroup>    
        </div>
    )
}