import { periodToString } from "Time/periodUtility"
import { Collapsable, CollapseChevron } from "components/Collapsible"
import ClickableOpacity from "components/Decorator/ClickableOpacity"
import FlexFill from "components/FlexFill"
import ListView from "components/ListView/ListView"
import Row from "components/Row"
import Spacer from "components/Spacer/Spacer"
import Tile from "components/Tiles/Tile"
import YearSelect from "components/Time/YearSelect"
import Label from "components/labels/Label"
import { ModalContext } from "components/modal/ModalContext"
import { useContext, useMemo, useState } from "react"
import styled from 'styled-components'
import { formatFloat } from 'utils/string-utility';
import useVacationDetails from "./useVacationDetails"
import moment from "moment"
import Employee from "Employees/Employee"
import Column from "components/Column"
import { FaUmbrellaBeach } from "react-icons/fa"

const Layout = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 9em auto;
    grid-template-areas: 
        "current-info list-info"
`

const CurrentVacationLabel = styled.span`
    font-size: 3em;
    line-height: 0.9em;
    font-weight: bold;
    text-align: center;
`

const CurrentArea = styled.div`
    grid-area: current-info;
    border-right: 1px solid lightgray;
    height: 100%;
`

const ListArea = styled.div`
    grid-area: list-info;
`

const BookingArea = styled.div`
    display: grid;
    grid-template-columns: 0.8em 3em auto 0.5em 9em;
    grid-template-areas:
        "booking-sign booking-days booking-label . booking-period"
`

const BookingLabel = styled(Label)`
    grid-area: booking-label;
    overflow: hidden;
    text-overflow: ellipsis;
`

const BookingPeriod = styled(Label)`
    grid-area: booking-period;
    overflow: hidden;
    text-overflow: ellipsis;

`

const BookingSign = styled(Label)`
    grid-area: booking-sign;
    ${props => 
        parseFloat(props.days) >= 0 ? 
        `
            &:after {
                content: "+"
            }
        `
        : 
        `
             &:after {
                content: "-"
            }
        `
    }
`

const BookingDays = styled(Label)`
    grid-area: booking-days;
`

const VacationRuleRow = ({ yearInfo, onClick }) => {
    return (
        <ClickableOpacity onClick={onClick}>
            <BookingArea>
                <BookingLabel>Urlaubsanspruch</BookingLabel>
                <BookingSign days={yearInfo.vacationDays} />
                <BookingDays>{formatFloat(yearInfo.vacationDays)}</BookingDays>
            </BookingArea>
        </ClickableOpacity>
    )
}

const ExpiredBookingRow = ({ expired }) => {
    if(expired > 0) {
        return (
            <BookingArea>
                <BookingLabel>Abgelaufen</BookingLabel>
                <BookingSign days={-expired} />
                <BookingDays>{formatFloat(expired)}</BookingDays>
            </BookingArea>
        )
    }
    return null
}

const BookingRow = ({ booking, onClick}) => {

    return (
        <ClickableOpacity onClick={onClick}>
            <BookingArea>
                <BookingLabel>{booking.label}</BookingLabel>
                <BookingPeriod subtle>{periodToString([booking.period_start, booking.period_end])}</BookingPeriod>
                <BookingSign days={-booking.days} />
                <BookingDays>{booking.days}</BookingDays>
            </BookingArea>
        </ClickableOpacity>
    )
}

interface Props {
    employee:Employee
}

const VacationTile = ({employee}:Props) => {

    const [startYear, setStartYear] = useState(moment().year()-1)
    const [endYear, setEndYear] = useState(moment().year())

    const vacationParams = useMemo(() => ({
        employee_id: employee?.person_id || -1,
        start_year: startYear,
        end_year: endYear
    }), [employee, startYear, endYear])

    const { data: vacationDetails } = useVacationDetails(vacationParams)
    const { vacationRuleModal, vacationBookingModal } = useContext(ModalContext)

    const showRuleModal = (yearInfo) => {
        vacationRuleModal.select({
            ...yearInfo.rule,
            operationDays: yearInfo.operationDays,
            operationMonths: yearInfo.operationMonths,
            usedVacationDays: yearInfo.used,
            entryMultiplier: yearInfo.entryMultiplier
        })
    }

    const showCreateBookingModal = (yearInfo) => {
        vacationBookingModal.select({ 
            charged_year: parseInt(yearInfo.year),
            employee_id: employee?.person_id
        })
    }

    if(!vacationDetails) {
        return null
    }
    return (
        <Tile
            title='Urlaubskonto'
            Icon={<FaUmbrellaBeach/>}
        >
            <Column gap='0.5em'>
                <Spacer hMedium bottomMedium>
                    <Row vCentered>
                        <YearSelect
                            value={startYear}
                            onChange={({ value }) => setStartYear(value)}
                        />
                        <Label>-</Label>
                        <YearSelect
                            value={endYear}
                            onChange={({ value }) => setEndYear(value)}
                        />
                    </Row>
                </Spacer>
                <Layout>
                    <CurrentArea>
                        <Spacer medium>
                            <CurrentVacationLabel centered bold>{vacationDetails.usableVacationDays}</CurrentVacationLabel>
                            <Label centered subtle>Urlaubstage</Label>
                        </Spacer>
                    </CurrentArea>
                    <ListArea>
                        <ListView
                            data={vacationDetails ? vacationDetails.years?.sort((a, b) => b.year - a.year) : []}
                            listItem={yearInfo => {
                                return (
                                    <Collapsable
                                        header={(
                                            <Spacer medium>
                                                <Row>
                                                    <Label bold>{yearInfo.year}</Label>
                                                    <Label subtle>{`${formatFloat(yearInfo.usableVacationDays)} Tage`}</Label>
                                                    <FlexFill />
                                                    <CollapseChevron />
                                                </Row>
                                            </Spacer>
                                        )}
                                        content={(
                                            <>
                                                <Spacer hLarge>
                                                    <VacationRuleRow yearInfo={yearInfo} onClick={() => showRuleModal(yearInfo)} />
                                                    <ListView
                                                        Separator={() => null}
                                                        data={yearInfo.bookings}
                                                        listItem={(booking) => (
                                                            <BookingRow 
                                                                booking={booking} 
                                                                onClick={() => vacationBookingModal.select(booking)}
                                                            />
                                                        )}
                                                    />
                                                    <ExpiredBookingRow expired={yearInfo.expiredVacationDays}/>
                                                    <Spacer vSmall>
                                                        <Label onClick={() => showCreateBookingModal(yearInfo)} primary>+ Buchung</Label>
                                                    </Spacer>
                                                </Spacer>
                                                <Spacer vSmall />
                                            </>
                                        )}
                                    />
                                )
                            }}
                        />
                    </ListArea>
                </Layout>     
            </Column>
        </Tile>
    )
}

export default VacationTile