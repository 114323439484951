import { useMemo } from "react";
import BankInformation from "./BankInformation";
import { generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory";
import useForm from "Forms/useForm";


export default function useBankInformationForm(bankInformation:BankInformation) {
    const fields = useMemo(() => ({
        bank_information_id: generatePositiveIntegerField(bankInformation?.bank_information_id, false),
        bank_name: generateTextField(bankInformation?.bank_name, false),
        iban: generateTextField(bankInformation?.iban, false)
    }), [bankInformation])

    return useForm(fields)
}