import { useInput } from "hooks/input";
import moment from "moment";
import { useMemo } from 'react';
import TextInput from "components/input/TextInput";
import Row from "components/Row";
import Spacer from "components/Spacer/Spacer";
import MonthSelect from "components/Time/MonthSelect";
import YearSelect from "components/Time/YearSelect";
import useModal from "components/modal/useModal";
import Table from "components/table/table";
import Screen from "app/Views/Screen";
import Tile from "components/Tiles/Tile";
import useEventColumns from "Events/useEventColumns";
import EventModal from "Events/EventModal";
import EventUtility from "Events/EventUtility";
import { useEvents } from "Events/EventApi";

const OperationView = () => {
    
    const columns = useEventColumns();
    const { value: year, bind: bindYear } = useInput(moment().year());
    const { value: month, bind: bindMonth } = useInput(moment().month()+1);
    const { value: filter, bind: bindFilter } = useInput("");
    const modal = useModal()

    const getPeriod = (year, month) => {
        if (year && month) {
            return ([
                moment().year(year).month(month - 1).startOf('month').startOf('day').format('YYYY-MM-DD'),
                moment().year(year).month(month - 1).endOf('month').endOf('day').format('YYYY-MM-DD')
            ])
        }
        else if (year) {
            return ([
                moment().year(year).startOf('year').startOf('day').format('YYYY-MM-DD'),
                moment().year(year).endOf('year').endOf('day').format('YYYY-MM-DD')
            ])
        }
        return [undefined, undefined]
    }

    const params = useMemo(() => {
        const [periodStart, periodEnd] = getPeriod(year, month)

        return {
            period_start: periodStart,
            period_end: periodEnd
        }
    }, [year, month])
    const { data } = useEvents(params)

    const validEvents = useMemo(() => {
        return data?.filter(event => {
            return EventUtility.confirmationRequired(event) || (event.confirmed && EventUtility.getCost(event) > event.booked_amount)
        })
    }, [data])

    return (
        <Screen>
            <EventModal {...modal}/>
            <Spacer medium shrink>
                <Tile title='Ungebuchte Einsätze'>
                    <Spacer hMedium vSmall>
                        <Row>
                            <TextInput size={20} label='Suchen' type='text' {...bindFilter} />
                            <MonthSelect label='Monat' value={month} {...bindMonth}></MonthSelect>
                            <YearSelect label='Jahr' value={year} {...bindYear}></YearSelect>
                        </Row>
                    </Spacer>
                    <Table
                        data={validEvents}
                        columns={columns}
                        filter={filter}
                        onClick={modal.select}
                    />
                </Tile>
            </Spacer>
        </Screen>
    )
}

export default OperationView