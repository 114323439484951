
import React from 'react';
import { useEvents } from './EventApi';

const EventContext = React.createContext([]);

const EventProvider = ({ params, children }) => {
    const events = useEvents(params)

    return (
        <EventContext.Provider value={events}>
            {children}
        </EventContext.Provider>
    )
}

export { EventContext, EventProvider }