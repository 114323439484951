import { useCallback, useState } from "react"

const useTabs = (initial) => {
    const [selected, setSelected] = useState(initial)

    const isSelected = useCallback((id) => {
        return selected === id
    }, [selected])

    return {
        isSelected,
        selected,
        select: setSelected
    }
}

export default useTabs