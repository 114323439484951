import { useMemo } from "react";
import Label from "components/labels/Label";
import useDenominations from "./useDenominations";

interface Props {
    denominationId: number
}

export default function DenominationLabel({ denominationId}:Props) {

    const { data } = useDenominations()

    const label = useMemo(() => {
        var denomination = data?.find(d => d.denomination_id === denominationId)
        return denomination?.denomination_label || "-"
    }, [data, denominationId])

    return (
        <Label>{label}</Label>
    );
}