import { FormGroup } from "components/form/FormComponents";
import TextInput from "components/input/TextInput";
import Row from "components/Row";

export default function HealthInsuranceForm({data, handleChange}) {
   
    if (!data) {
        return null
    }

    return (
        <FormGroup>
            <TextInput label='Name' value={data.name} name='name' onChange={handleChange}/>
            <TextInput label='Sachbearbeiter' value={data.contact_name} name='contact_name' onChange={handleChange} />
            <Row>
                <TextInput grow label='Straße' value={data.street} name='street' onChange={handleChange} />
                <TextInput grow label='PLZ' value={data.postcode} name='postcode' onChange={handleChange} />
                <TextInput grow label='Stadt' value={data.city} name='city' onChange={handleChange} />
            </Row>
            <TextInput label='Telefon' value={data.phone} name='phone' onChange={handleChange} />
            <TextInput label='Email' value={data.email} name='email' onChange={handleChange} />
            <TextInput label='Website' value={data.website} name='website' onChange={handleChange} />
        </FormGroup>
    );
}