
export function getObjectProperty(object:Object, path:string, fallback=undefined) {
    if(!object) {
        return fallback
    }
    if (path === '') {
        return object
    }
    const pathElements = path.split('/')
    const currentKey = pathElements[0]
    if(pathElements.length === 1) {
        var value = object[currentKey]
        return value === undefined ? fallback : value
    }
    const subPath:string = pathElements.slice(1).join('/')
    return getObjectProperty(object[currentKey], subPath, fallback)
}

export function setObjectProperty(object:Object, path:string, value:any) : Object {
    if(!object) {
        return object
    }
    if(path === '') {
        object = value
        return object
    }
    const pathElements = path.split('/').filter(e => e !== '')
    const currentKey = pathElements[0]
    if (pathElements.length === 1) {
        object[currentKey] = value
    }
    else {
        const subPath: string = pathElements.slice(1).join('/')
        setObjectProperty(object[currentKey], subPath, value)
    }
    return object
}



export function excludeObjectProperties<T>(object:T, keys: string[]):  any {
    const result = {}
    var includedProperties = []
    var starExpressions = keys.filter(key => key.startsWith('*'))

    if(starExpressions.length > 0) {
        includedProperties = starExpressions.reduce((included, key) => {
            var includedKeys = key.slice(2, key.length - 1).replaceAll(/\s/g, '').split(',')
            return [...included, ...includedKeys]
        }, [])
    }
    else {
        includedProperties = Object.keys(object)
    }

    includedProperties = includedProperties.filter(prop => !keys.includes(prop))

    for(const prop in object) {
        if(includedProperties.includes(prop)) {
            const nestedKeys = keys.filter(key => key !== prop && key.startsWith(prop)).map(key => key.replace(prop, '').replace('/', ''))
            var value = object[String(prop)]
            if (nestedKeys.length > 0) {
                value = excludeObjectProperties(object[prop], nestedKeys)
            }
            result[String(prop)] = value
        }
    }
    return result
}