import React from "react";
import { formatFloat } from "utils/string-utility";
import Label from "./Label";


export default function HourLabel({value, ...props}) {

    return (
        <Label {...props}>
            {`${formatFloat(value)} Std.`}
        </Label>
    )
}