import SubmitButton from "Forms/SubmitButton";
import { ActionOptions, Actions } from "../../Common/Actions/Action";


interface Props {
    actions: Actions,
    options?: ActionOptions
}

export default function ActionButtons<T>({ actions, options }: Props) {

    return (
        <>
            {Object.values(actions).map((action, index) => (
                <SubmitButton
                    key={index}
                    disabled={action.disabled}
                    isPending={action.isPending}
                    visible={action.visible}
                    onClick={() => action.execute(options)}
                    color={action.color}
                    backgroundColor={action.backgroundColor}
                    confirmMessage={action.confirmMessage}
                >
                    {action.label}
                </SubmitButton>
            ))}
        </>
    )
}