import { useMutation, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"

const mutationFilter = { queryKey: ['bills'] }

export default (onSuccess=()=>{}) => {

    const queryClient = useQueryClient()

    const update = useMutation({
        mutationFn: (data) => ameli.put('bills', data),
        onSuccess: (response) => {
            queryClient.invalidateQueries(mutationFilter)
            onSuccess()
        }
    })

    return {
        update
    }
}