import { IModal } from "components/modal/useModal"
import Customer from "./Customer"
import Modal, { ModalFooter } from "components/modal/Modal"
import useCustomerForm from "./useCustomerForm"
import CustomerForm from "./CustomerForm"
import Spacer from "components/Spacer/Spacer"
import FormUpdateButton from "Forms/FormUpdateButton"
import FormCreateButton from "Forms/FormCreateButton"
import { useNavigate } from "react-router-dom"
import { useCustomerCreate, useCustomerUpdate } from "./CustomerApi"

interface Props extends IModal<Customer> {
    isUpdate?:boolean
}

const CustomerModal = ({ selected:customer, isUpdate=false, ...props }:Props) => {

    const form = useCustomerForm(customer)
    const title = isUpdate ? "Kunden bearbeiten" : "Kunden erfassen"
    const navigate = useNavigate()
    const updateCustomer = useCustomerUpdate()
    const createCustomer = useCustomerCreate()

    const successCallback = () => {
        props.setVisible(false)
    }

    const update = () => {
        updateCustomer.mutate(form.data, {
            onSuccess: successCallback
        })
    }

    const create = () => {
        createCustomer.mutate(form.data, {
            onSuccess: ({ data }) => {
                successCallback()
                navigate(`/customers/${data?.id}`)
            }
        })
    }

    return (
        <Modal title={title} selected={customer} {...props}>
            <Spacer medium>
                <CustomerForm form={form}/>
                <ModalFooter>
                    <FormUpdateButton form={form} visible={isUpdate} onClick={update}/>
                    <FormCreateButton form={form} visible={!isUpdate} onClick={create} />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

export default CustomerModal