import Column from "components/Column"
import Label from "components/labels/Label"
import EventUtility from "./EventUtility"
import Theme from "Theming/Theme"
import { useMemo } from "react"
import CurrencyLabel from "components/labels/currency-label"
import Row from "components/Row"


const EventStatus = ({event}) => {

    const [color, statusLabel, cost, bookedAmount] = useMemo(() => {
        const cost = EventUtility.getCost(event)
        const difference = parseFloat(event?.booked_amount || 0) - cost

        // Verbucht
        if(difference == 0) {
            return [Theme.successColor, "Verbucht", cost, event.booked_amount]
        }
        // Nicht komplett verbucht
        if (difference < 0) {
            return [Theme.dangerColor, "Nicht verbucht", cost, event.booked_amount]
        }
        // Überbucht
        return [Theme.dangerColor, "Überbucht", cost, event.booked_amount]
    }, [event])

    if(!event) {
        return <Label subtle>?</Label>
    }

    if (event.absent) {
        return <Label color={Theme.dangerColor}>Abwesend</Label>
    }

    if (event.confirmed == null) {
        return <Label color={Theme.subtleColor}>Nicht bestätigt</Label>
    }

    if (event.confirmed == false) {
        return <Label color={Theme.subtleColor}>Abgesagt</Label>
    }

    if(cost == 0) {
        return <Label subtle>Keine Kosten</Label>
    }

    return (
        <Column>
            <Label bold color={color}>{statusLabel}</Label>
            <Row>
                <CurrencyLabel small color={color} value={bookedAmount} />
                <Label small color={color}>/</Label>
                <CurrencyLabel small color={color} value={cost} />
            </Row>
        </Column>
    )
}

export default EventStatus