
import ameli from 'Api/ameli';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Budget from './Budget';
import BudgetType from './BudgetType';

export interface BudgetGetParams {
    period_start?:string,
    period_end?:string,
    owner_id?:number
}



export function useBudgets(params: BudgetGetParams) {
    return useQuery({
        queryKey: ['budgets', params],
        queryFn: () => ameli.get<Budget[]>(`budgets`, { params }).then(res => res.data),
        enabled: params != null
    })
}


export interface CollectiveBudgetGetParams {
    date?: string,
    owner_id?: number,
    year?: number
}

export interface BudgetTypeGetParams {
    care_level?:number
}


export function useBudgetTypes(params:BudgetTypeGetParams={}) {

    return useQuery({
        queryKey: ['budget_types'],
        queryFn: () => ameli.get<BudgetType[]>(`/budgets/types`, { params}).then(res => res.data),
    })
}

export function useBudgetMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['budgets'] })
        }
    })
}

export const useBudgetUpdate = () => {
    return useBudgetMutation<Budget>(
        (data: Budget) => ameli.put('budgets', data)
    )
}

export const useBudgetCreate = () => {
    return useBudgetMutation<any>(
        (data) => ameli.post('budgets', data)
    )
}

export const useBudgetDelete = () => {
    return useBudgetMutation<number>(
        (budgetId: number) => ameli.delete(`budgets/${budgetId}`)
    )
}
