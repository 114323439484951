
const parseLocalizedFloat = (string, fallback=0.0) => {
    if(string === '') {
        return fallback
    }
    string = typeof string === 'string' ? string.replace(',', '.') : string
    return parseFloat(string)
} 

export {
    parseLocalizedFloat
}