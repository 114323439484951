import Dropdown from "components/dropdown/dropdown"

const OPTIONS = [
    {
        value: null,
        label: 'Ungeklärt'
    },
    {
        value: 0,
        label: 'Nicht notwendig'
    },
    {
        value: 50,
        label: 'Erwünscht'
    },
    {
        value: 100,
        label: 'Notwendig'
    },
]

const EventDemandSelect = (props) => {
    return (
        <Dropdown
            options={OPTIONS}
            isClearable
            placeholder='Ungeklärt'
            {...props}
        />
    )
}

export default EventDemandSelect