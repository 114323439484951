import useForm from "Forms/useForm"
import useAssignmentFields from "./useAssignmentFields"
import { useCallback } from "react"

const useAssignmentForm = (initialData) => {
    const fields = useAssignmentFields(initialData)

    const validator = useCallback((data) => {
        return true
    }, [])

    return useForm(fields, validator)
}

export default useAssignmentForm