import IconWrapper from 'components/Icons/IconWrapper'
import styled from 'styled-components'

const IconAnchor = styled.div`
    position: relative;
`

const IconContainer = styled.div`
    position: absolute;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;

    ${IconContainer} {
        opacity: 0;
        transition: 0.3s;
    }

    &:hover {
        ${IconContainer} {
            opacity: 1;
        }
    }
`

const HoverIcons = ({icons, ...props}) => {
    return (
        <Container {...props}>
            <div>
                { props.children }
            </div>
            <IconAnchor>
                <IconContainer>
                    { icons.map((icon, index) => (
                        <IconWrapper key={index} onClick={() => icon.onClick()}>
                            { icon.icon()}
                        </IconWrapper>
                    ))}
                </IconContainer>
            </IconAnchor>
        </Container>
    )
}

export default HoverIcons
