import styled from 'styled-components'
import Theme from 'Theming/Theme'

export interface LabelProps {
    large?:boolean,
    small?:boolean,
    bold?:boolean,
    color?:string,
    primary?:boolean,
    danger?:boolean,
    warning?:boolean,
    success?:boolean,
    subtle?:boolean,
    disabled?:boolean,
    allowWhiteSpace?:boolean,
    ignoreCursor?:boolean,
    centered?:boolean,
    onClick?:() => any,
    width?:string,
    size?: string,
    lineHeight?: string,
    children?: React.ReactNode,
    text?: string
}

const getFontSize = (props:LabelProps) => {
    if(props.size) {
        return props.size
    }
    if(props.large) {
        return "1.1em"
    }
    if(props.small) {
        return "0.8em"
    }
    return "1em"
}

const getLineHeight = (props:LabelProps) => {
    if(props.lineHeight) {
        return props.lineHeight
    }
    if (props.large) {
        return "1.2em"
    }
    if (props.small) {
        return "1.2em"
    }
    return "1.2em"
}

const getFontWeight = (props:LabelProps) => {
    if(props.bold) {
        return "bold"
    }
    return "regular"
}

const getColor = props => {
    if(props.color) {
        return props.color
    }
    if(props.subtle) {
        return Theme.subtleTextColor
    }
    if (props.danger) {
        return "#b90000"
    }
    if (props.warning) {
        return '#d37500'
    }
    if(props.success) {
        return 'green'
    }
    if(props.primary) {
        return '#ce0069'
    }
    return Theme.textColor;
}

const Label = styled.span`
    opacity: ${props => props.disabled ? 0.6 : 1.0};
    overflow-x: hidden;
    white-space: ${props => props.allowWhiteSpace ? 'break-spaces' : 'nowrap'};
    margin-right: 0.3rem;
    display: block;
    transition: 0.3s;
    font-size: ${props => getFontSize(props)};
    line-height: ${props => getLineHeight(props)};
    font-weight: ${props => getFontWeight(props)};
    color: ${props => getColor(props)};
    pointer-events: ${props => props.ignoreCursor ? 'none' : 'initial'};
    user-select: ${props => props.ignoreCursor ? 'none' : 'initial'};
    text-align: ${props => props.centered ? 'center' : 'left'};

    ${ props => props.onClick ? 
        `
            cursor: pointer;
            &:hover {
                color: #ce0069;
                text-decoration: underline;
            }
        `
        : ''
    }

    ${ props => props.width ?
        `
            width: ${props.width};
            text-overflow: ellipsis;
            overflow: hidden;
        `
        : ''
    }
`

export function TitleLabel(props:LabelProps) {
    return (
        <Label 
            size='20px'
            bold
            {...props}
        />
    )
}

export function SectionLabel(props:LabelProps) {
    return (
        <Label
            size='18px'
            bold
            {...props}
        />
    )
}

export default Label;