import { useQuery } from "@tanstack/react-query"
import ameli from "Api/ameli"
import FamilyStatus from "./FamilyStatus"

const useFamilyStatus = () => {
    return useQuery({
        queryKey: ['family_status'],
        queryFn: () => ameli.get<FamilyStatus[]>('/family_status').then(res => res.data)
    })
}

export default useFamilyStatus