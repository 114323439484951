import React, { useEffect, useState } from "react";
import Dropdown from "../components/dropdown/dropdown";
import ameli from "Api/ameli";

export default function TaxBracketSelect(props) {

    const [options, setOptions] = useState([])

    useEffect(() => {
        ameli.get("/tax_brackets")
            .then(response => {
                const newOptions = response.data.map(taxBracket => {
                    return { value: taxBracket.tax_bracket_id, label: taxBracket.tax_bracket_label }
                })
                setOptions(newOptions)
            })
            .catch(error => setOptions([]))
    }, []);

    return (
        <Dropdown options={options} {...props}></Dropdown>
    );
}
