import ListView from "components/ListView/ListView"
import ListItem from "components/ListView/BetterListItem"
import { TagProps } from "Tags/TagContainer"
import Theme from "Theming/Theme"
import { useState } from "react"
import ModelInstanceModal from "Models/ModelInstanceModal"
import Column from "components/Column"
import { FaPen, FaPlus } from "react-icons/fa"
import Tile from "components/Tiles/Tile"
import Button from "components/Button"
import Spacer from "components/Spacer/Spacer"
import Row from "components/Row"
import ScrollView from "components/ScrollView"
import styled from 'styled-components';
import { useServices } from "Common/Services/ServiceApi"
import useModelInstance from "Common/Models/useModelInstance"
import Service from "Common/Services/Service"
import ServiceDetailsModel from "Common/Services/ServiceDetails/ServiceDetailsModel"
import ServiceModel from "Common/Services/ServiceModel"

const Container = styled(Column)`
    gap: 1em;
    overflow-y: hidden;
`

const params = {}

const initialData = {
    hourlyRate: 35.0,
}

const initialDetailsData = {
}

export default function ServiceView() {
    
    const services = useServices(params)
    const [modalVisible, setModalVisible] = useState(false)
    const [detailsModalVisible, setDetailsModalVisible] = useState(false)
    const instance = useModelInstance(ServiceModel, initialData)
    const detailsInstance = useModelInstance(ServiceDetailsModel, initialDetailsData)

    return (
        <Container>
            <Tile>
                <Spacer hMedium>
                    <Row>
                        <Button
                            onClick={() => {
                                instance.setValue(initialData)
                                setModalVisible(true)
                            }}
                        >
                            Neue Dienstleistung
                        </Button>
                    </Row>
                </Spacer>
            </Tile>
            <ModelInstanceModal
                instance={instance}
                visible={modalVisible}
                setVisible={setModalVisible}
                formOptions={{
                    hidden: ['id', 'createdAt', 'updatedAt']
                }}
            />
            <ModelInstanceModal
                instance={detailsInstance}
                visible={detailsModalVisible}
                setVisible={setDetailsModalVisible}
                formOptions={{
                    hidden: ['id', 'serviceId', 'createdAt', 'updatedAt']
                }}
            />
            <ScrollView>        
                <ListView 
                        data={services.data} 
                        Separator={() => (<Spacer vSmall/>)}
                        listItem={(service:Service) => {
                            const tags:TagProps[] = [
                                {
                                    text: `${service.hourlyRate}€ / Std.`,
                                    backgroundColor: Theme.successColor
                                }
                            ]

                            if(service.useSquareMeter) {
                                tags.push({
                                    text: 'Quadratmeter',
                                    backgroundColor: Theme.subtleColor
                                })
                            }

                            return (
                                <ListItem 
                                    size="medium"
                                    title={service.label} 
                                    tags={tags}
                                    actions={([
                                        {
                                            Icon: <FaPen/>,
                                            execute: () => {
                                                instance.setValue(service)
                                                setModalVisible(true)
                                            },
                                        },
                                        {
                                            Icon: <FaPlus />,
                                            execute: () => {
                                                detailsInstance.setValue({ serviceId: service.id})
                                                setDetailsModalVisible(true)
                                            },
                                        }
                                    ])}
                                >
                                    <Spacer vMedium>
                                        <ListView
                                            Separator={() => (<Spacer vTiny/>)}
                                            data={service.serviceDetails}
                                            listItem={(details) => (
                                                <ListItem
                                                    bannerColor={Theme.subtleColor}
                                                    onClick={() => {
                                                        detailsInstance.setValue(details)
                                                        setDetailsModalVisible(true)
                                                    }}
                                                    title={details.content}
                                                />
                                            )}
                                        />
                                    </Spacer>
                                </ListItem>
                            )
                        }}        
                />
            </ScrollView>
        </Container>
    )
}