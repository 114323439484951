import ListView from "components/ListView/ListView"
import Tile from "components/Tiles/Tile"
import { ModalContext } from "components/modal/ModalContext"
import { useCallback, useContext, useMemo } from "react"
import { useAssignments } from "Assignments/AssignmentApi"
import moment from 'moment';
import AssignmentListItem from "./AssignmentListItem"
import { FaPlus } from "react-icons/fa"
import Spacer from "components/Spacer/Spacer"


interface Props {
    customerId?:number,
    employeeId?:number,
    title:string,
    modalParams?: any,
    showCustomerName?:boolean,
    showEmployeeName?:boolean
}


const AssignmentTile = ({ 
    customerId,
    employeeId,
    title,
    modalParams={},
    showCustomerName=true,
    showEmployeeName=true
 }:Props) => {

    const { assignmentModal } = useContext(ModalContext)

    const params = useMemo(() => ({
        customer_id: customerId,
        employee_id: employeeId,
        start:moment().format('YYYY-MM-DD'),
    }), [customerId, employeeId])

    const { data:assignments } = useAssignments(params)

    const listData = useMemo(() => {
        if(!assignments) {
            return []
        }
        if(showEmployeeName) {
            return assignments.sort((a, b) => a?.employee_last_name?.localeCompare(b?.employee_last_name))
        }
        if (showCustomerName) {
            return assignments.sort((a, b) => a?.customer_last_name?.localeCompare(b?.customer_last_name))
        }
        return assignments
    }, [assignments, showEmployeeName, showCustomerName])

    return (
        <Tile
            title={`${title} (${listData?.length})`}
            actions={[
                {
                    Icon: <FaPlus />,
                    execute:() => assignmentModal.select(modalParams)
                }
            ]}
        >
            <Spacer hMedium>
                <ListView
                    data={listData}  
                    Separator={() =>(<Spacer vTiny/>)}
                    listItem={(assignment) => (
                        <AssignmentListItem
                            assignment={assignment} 
                            onClick={assignmentModal.select}
                            showCustomerName={showCustomerName}
                            showEmployeeName={showEmployeeName}
                        />
                    )}  
                />
            </Spacer>
        </Tile>
    )
}

export default AssignmentTile