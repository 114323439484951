import { generateBooleanField, generateDateField, generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory"
import useForm from "Forms/useForm"
import { isValidPeriod } from "Time/periodUtility"
import { useCallback, useMemo } from "react"

export default (vacationRequest) => {

    const fields = useMemo(() => {

        return {
            id: generatePositiveIntegerField(vacationRequest?.id),
            employee_id: generatePositiveIntegerField(vacationRequest?.employee_id, true),
            request_date: generateDateField(vacationRequest?.request_date, true),
            start_date: generateDateField(vacationRequest?.start_date, true),
            end_date: generateDateField(vacationRequest?.end_date, true),
            paid: generateBooleanField(vacationRequest?.paid),
            details: generateTextField(vacationRequest?.details),
            rejected: generateBooleanField(vacationRequest?.rejected),
            rejection_reason: generateTextField(vacationRequest?.rejection_reason)
        }
    }, [vacationRequest])

    const validator = useCallback((data) => {
        return isValidPeriod(data.start_date, data.end_date)
    }, [])

    return useForm(fields, validator)
}