import { useCallback } from "react";
import Event from 'Events/Event';

export default function useEventUtility() {
    const eventIsConfirmed = useCallback((event:Event) => {
        return event && !event.absent && event.confirmed === 1
    }, [])

    return {
        eventIsConfirmed
    }
}