import styled from 'styled-components'
import { NavLink } from 'react-router-dom';

const Content = styled.div`
    flex: 1;
`

const Marker = styled.div`
    width: 8px;
    background-color: transparent;
`

const Container = styled(NavLink)`
    text-decoration: none;
    display: flex;
    color: black;

    &:hover {
        background-color: #f7f7f7;
    }

    &.active {
        font-weight: bold;
        background-color: #eee;

        ${Marker} {
            background-color: #ce0069;
        }
    }
`


const SidebarNavLink = ({children, ...props}) => {
    
    return (
        <Container {...props}>
            <Content>
                { children }
            </Content>
            <Marker/>
        </Container>
    )
}

export default SidebarNavLink