
import React, { useCallback, useReducer } from 'react';
import _ from 'lodash'

const SignalContext = React.createContext({})

const reducer = (state, action) => {
    var newState = state

    switch (action.type) {
        case 'add_listener':
            action.keys.forEach(key => {
                let currentListeners = key in state ? state[key] : []
                newState[key] = [...currentListeners, action.listener]
            })
            return newState
        case 'remove_listener':
            action.keys.forEach(key => {
                let currentListeners = key in state ? state[key] : []
                let remainingListeners = currentListeners.filter(l => l !== action.listener)
                if (remainingListeners.length === 0) {
                    delete newState[key]
                }
                else {
                    newState[key] = remainingListeners
                }
            })
            return newState
        default:
            return state
    }
}

const SignalProvider = ({ children }) => {

    const [listener, dispatch] = useReducer(reducer, {
        'Test': [
            () => console.log(listener)
        ]
    })

    const addListener = useCallback((keys, listener) => {
        dispatch({
            type: 'add_listener',
            keys,
            listener
        })
    }, [dispatch])

    const removeListener = useCallback((keys, listener) => {
        dispatch({
            type: 'remove_listener',
            keys,
            listener
        })
    }, [dispatch])

    const emitSignal = useCallback((keys) => {
        keys = keys || Object.keys(listener)

        var listeners = []
        keys.forEach(key => {
            let currentListeners = key in listener ? listener[key] : []
            listeners = [...listeners, ...currentListeners]
        });
        listeners = _.uniq(listeners)

        var promises = []
        listeners.forEach(l => {
            promises.push(l())
        });
        return Promise.all(promises)
    }, [listener])

    return (
        <SignalContext.Provider
            value={{
                addListener,
                removeListener,
                emitSignal
            }}
        >
            {children}
        </SignalContext.Provider>
    )
}

export { SignalContext, SignalProvider }