import { FormGroup } from "components/form/FormComponents";
import EmployeeSelect from "Employees/EmployeeSelect";
import BetterNumberFormat from "components/input/better-number-format";
import Row from "components/Row";
import Column from "components/Column";
import Label from "components/labels/Label";
import { useMemo } from "react";
import styled from 'styled-components'
import { formatFloat } from 'utils/string-utility';

const CalculationRow = styled.div`
    display: flex;
    flex-direction: row;
`

const CalculationSign = styled(Label)`
    width: 0.5em;
`

const CalculationLine = styled.div`
    width: 14em;
    height: 1px;
    margin: 0.5em 0px;
    background: #444;
`

export default function VacationRuleForm({ 
    data, 
    setFieldValue,
    operationMonths =undefined, 
    operationDays=undefined,
    usedVacationDays=undefined,
    entryMultiplier=undefined
}) {

    const [averageOperationDays, variableVacationDays, vacationDays, weeks, days, remainingVacationDays] = useMemo(() => {
        var weeks = parseInt(data.weeks) ? parseInt(data.weeks) : 0
        var days = parseInt(data.days) ? parseInt(data.days) : 0
        var averageOperationDays = operationDays / operationMonths / 4
        var variableVacationDays = Math.round(averageOperationDays * weeks * entryMultiplier)
        var vacationDays = variableVacationDays + days
        var remainingVacationDays = vacationDays - usedVacationDays
        return [
            averageOperationDays,
            variableVacationDays,
            vacationDays,
            weeks,
            days,
            remainingVacationDays
        ]
    }, 
        [operationDays, operationMonths, data.weeks, data.days, usedVacationDays, entryMultiplier]
    )

    return (
        <Row gap='0.5em'>
            <Column grow>
                <FormGroup>
                    <EmployeeSelect
                        label='Mitarbeiter'
                        name='employee_id'
                        onChange={({ value }) => setFieldValue('employee_id', value)}
                        value={data.employee_id}>
                    </EmployeeSelect>
                    <BetterNumberFormat
                        label='Start Jahr'
                        onChange={(event) => setFieldValue('start_year', event.currentTarget.value)}
                        value={data.start_year}
                    />
                    <BetterNumberFormat
                        label='Wochen pro ET'
                        onChange={(event) => setFieldValue('weeks', event.currentTarget.value)}
                        value={data.weeks}
                    />
                    <BetterNumberFormat
                        label='Feste Tage'
                        onChange={(event) => setFieldValue('days', event.currentTarget.value)}
                        value={data.days}
                    />
                </FormGroup>    
            </Column>
            {
                (operationMonths && operationDays) ? 
                    <Column width={'28em'} gap='0.5em'>
                        <Label bold>Berechnung der Urlaubstage</Label>
                        <div>
                            <Label medium>{`${operationDays} Einsatztage / ${operationMonths} volle Arbeitsmonate / 4 Wochen = ${formatFloat(averageOperationDays)}`}</Label>
                            <Label medium>{`Eintrittsmultiplikator = ${formatFloat(entryMultiplier)}`}</Label>
                            <Label medium>{`${formatFloat(averageOperationDays)} * ${weeks} Urlaubswochen * ${formatFloat(entryMultiplier)} = ${variableVacationDays} flexible Urlaubstage`}</Label>
                        </div>
                        <div>
                            <CalculationRow>
                                <CalculationSign />
                                <Label>{`${variableVacationDays} flexible Urlaubstage`}</Label>
                            </CalculationRow>
                            <CalculationRow>
                                <CalculationSign>+</CalculationSign>
                                <Label>{`${days} feste Urlaubstage`}</Label>
                            </CalculationRow>
                            <CalculationLine />
                            <CalculationRow>
                                <CalculationSign></CalculationSign>
                                <Label>{`${vacationDays} Urlaubstage`}</Label>
                            </CalculationRow>
                            <CalculationRow>
                                <CalculationSign>-</CalculationSign>
                                <Label>{`${formatFloat(usedVacationDays)} Urlaubstage verbraucht`}</Label>
                            </CalculationRow>
                            <CalculationLine />
                            <CalculationRow>
                                <CalculationSign></CalculationSign>
                                <Label>{`${formatFloat(remainingVacationDays)} Urlaubstage verbleibend`}</Label>
                            </CalculationRow>
                        </div>
                    </Column>
                : 
                null
            }       
        </Row>
    )
}