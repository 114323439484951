import Label from "components/labels/Label";
import Address from "./Address";
import { useMemo } from "react";
import { MdAddLocation, MdLocationOn } from "react-icons/md"
import IconRow from "components/Icons/IconRow";

interface Props {
    address:Address,
    isPending:boolean,
    onClick?: () => any
}

export default function AddressRow({ address, isPending, onClick }:Props) {


    const { label, Icon } = useMemo(() => {
        if (isPending) {
            return {
                label:"",
                Icon: null
            }
        }
        if (!address) {
            return {
                label: "Anschrift hinzufügen", 
                Icon: <MdAddLocation />
            }
        }
        
        return {
            label: `${address.street || ''}, ${address.postcode || ''} ${address.city || ''} ${address.district || ''}`,
            Icon: <MdLocationOn/>
        }

    }, [address, isPending])

    return (
        <IconRow onClick={onClick} Icon={Icon}>
            <Label small subtle>{label}</Label>
        </IconRow>
    )
}