import React, { useState } from "react";
import RNDatePicker, { registerLocale } from 'react-datepicker'
import InputWrapper from "./InputWrapper";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de'
import './DatePicker.css'

registerLocale('de', de)

const WeekPicker = ({value, onChange, ...props}) => {

    const [focused, setFocused] = useState(false)

    const handleChange = event => {
        if (onChange) {
            onChange({
                name: props.name,
                value: event
            })
        }
    }

    return (
        <InputWrapper focused={focused} {...props}>
            <RNDatePicker
                locale='de'
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={handleChange}
                dateFormat="I/R"
                selected={value}
                className='date-picker'
                isClearable={'isClearable' in props ? props.isClearable : true}
                showWeekNumbers
                showWeekPicker
            />
        </InputWrapper>
    )
}

export default WeekPicker