import Label from "components/labels/Label"
import { useMemo } from 'react';
import { useContext } from 'react';
import { HealthInsuranceContext } from "./HealthInsuranceContext";

const HealthInsuranceLabel = ({ id }) => {

    const { data: healthInsurances } = useContext(HealthInsuranceContext)

    const healthInsurance = useMemo(() => {
        return healthInsurances.find(insurance => insurance.id === id)
    }, [id, healthInsurances])

    const label = useMemo(() => {
        if (!healthInsurance) { 
            return '-'
        }
        return healthInsurance.name
    }, [healthInsurance])

    return ( 
        <Label>{label}</Label>   
    )
}

export default HealthInsuranceLabel