import Dropdown, { DropdownProps } from "components/dropdown/dropdown";
import { useMemo } from "react";
import Person from "./Person";

interface Props extends DropdownProps {
    persons:Person[]
}

export default function PersonSelect({persons, ...props}:Props) {

    const options = useMemo(() => {
        return persons
            ?.map((person) => ({
                value: person.id,
                label: `${person.last_name}, ${person.first_name}`
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
    }, [persons])

    return (
        <Dropdown 
            options={options}
            {...props}
        />
    )
}