import useOptions from "hooks/useOptions";
import { useCallback, useEffect, useState } from "react";
import _ from 'lodash'

const { useReducer } = require("react");

const defaultOptions = {
    defaultData: {}
}

function setValue(obj, path, value) {
    var a = path.split('.')
    var o = obj
    while (a.length - 1) {
        var n = a.shift()
        if (!(n in o)) o[n] = {}
        o = o[n]
    }
    o[a[0]] = value
}


export const useForm = (dataSource={}, optionArray={}) => {
    const [formDataChanged, setFormDataChanged] = useState(false);
    const [formDataValid, setFormDataValid] = useState(false);
    const options = useOptions(optionArray, defaultOptions);

    const validateData = useCallback((data) => {
        if (typeof (options.validate) === 'function') {
            return options.validate(data);
        }
        else {
            return true;
        }
    }, [options])

    const handleChange = useCallback((data) => {
        if(typeof(options.onChange) === 'function') {
            options.onChange(data)
        }
    }, [options])
    
    const formReducer = (state, event) => {
        switch (event.type) {
            case 'assign': {
                const newData = event.data;
                setFormDataValid(validateData(newData));
                setFormDataChanged(event.triggerDataChanged ?? false);
                handleChange(newData)
                return newData;
            }
            case 'field': {
                var newState = _.cloneDeep(state);
                setValue(newState, event.name, event.value)
                setFormDataValid(validateData(newState));
                if(!('triggerDataChanged' in event) || event.triggerDataChanged) {
                    setFormDataChanged(true);
                }
                handleChange(newState)
                return newState;
            }
            default:
                break;
        }
        return state;
    }

    const [formData, dispatch] = useReducer(formReducer, {});

    const setFormData = useCallback(
        (data, setDataChanged = false) => {
            dispatch({
                type: 'assign',
                data: data,
                triggerDataChanged: setDataChanged
            });
        },
        []
    );

    useEffect(() => {
        setFormData(dataSource);
    }, [setFormData, dataSource])

    const extractValue = useCallback((event) => {
        if ('target' in event) {
            return event.target.value;
        }
        else if ('currentTarget' in event) {
            return event.currentTarget.value;
        }
        else if ('value' in event) {
            return event.value;
        }
    }, []);

    const extractName = useCallback((event) => {
        if ('target' in event) {
            return event.target.name;
        }
        else if ('currentTarget' in event) {
            return event.currentTarget.name;
        }
        else if ('name' in event) {
            return event.name;
        }
    }, [])


    const setFormField = useCallback((name, value, triggerDataChanged = false) => {
        dispatch({
            type: 'field',
            name: name,
            value: value,
            triggerDataChanged: triggerDataChanged
        });
    }, [dispatch])

    const handleFormChange = useCallback(event => {
        setFormField(extractName(event), extractValue(event), true)
    }, [extractName, extractValue, setFormField]);

    return { 
        formData, 
        handleFormChange, 
        formDataChanged, 
        formDataValid, 
        setFormField,
        setFormData,
        setFormDataChanged,
        defaultData: options.defaultData
    }
}