import styled from 'styled-components'

const TextButton = styled.span`
    color: #ce0069;
    transition: 0.3s;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        color: #7a143d;
    }
`

export default TextButton