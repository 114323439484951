import Customer from "./Customer"
import useForm from "Forms/useForm"
import useCustomerFormFields from "./useCustomerFormFields"


const useCustomerForm = (customer:Customer) => {
    const fields = useCustomerFormFields(customer)
    return useForm(fields)
}

export default useCustomerForm