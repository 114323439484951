import Tile from "components/Tiles/Tile"
import ModelInstanceModal, { IModelInstanceModalOptions } from "./ModelInstanceModal"
import { useMemo, useState } from "react"
import { FaPlus } from "react-icons/fa"
import ListView from "components/ListView/ListView"
import Spacer from "components/Spacer/Spacer"
import { IListItemOptions } from "components/ListView/BetterListItem"
import { PartialObject } from "lodash"
import Action from "Common/Actions/Action"
import Theme from "Theming/Theme"
import useModelInstance from "Common/Models/useModelInstance"
import { IModel } from "Common/Models/IModel"

type ModelListTileProps<T> = {
    model:IModel<T>,
    data: T[],
    initialData: PartialObject<T>,
    listItem: (value:T, options:IListItemOptions) => React.ReactElement,
    modalOptions?: IModelInstanceModalOptions
}

export default function ModelListTile<T>({ 
    model, 
    initialData, 
    modalOptions, 
    data,
    listItem
} : ModelListTileProps<T>) {

    const [modalVisible, setModalVisible] = useState(false)
    const instance = useModelInstance<T>(model, initialData)

    const title = useMemo(() => {
        const count = data?.length || 0
        return `${model.labels[1]} (${count})`
    }, [data, model])

    const actions: Action[] = useMemo(() => ([
        {
            Icon: <FaPlus />,
            execute: () => {
                instance.setValue(initialData)
                setModalVisible(true)
            },
            isPending: false,
            label: 'Hinzufügen',
            color: Theme.whiteColor,
            backgroundColor: Theme.primaryColor,
            error: null,
            enabled: false,
            visible: true
        }
    ]), [initialData, setModalVisible])

    return (
        <>
            <ModelInstanceModal<T>
                instance={instance}
                visible={modalVisible}
                setVisible={setModalVisible}
                {...modalOptions}
            />
            <Tile
                title={title}
                actions={actions}
            >
                <Spacer hMedium>
                    <ListView
                        data={data}
                        Separator={() => (<Spacer vTiny/>)}
                        listItem={(value) => listItem(value, { 
                            onClick: () => {
                                instance.setValue(value)
                                setModalVisible(true)
                            }
                        })}
                    />
                </Spacer>
            </Tile>
        </>
    )
}