import { formatPeriodString } from "Time/timeUtility"
import ListItem from "components/ListView/ListItem"
import Spacer from "components/Spacer/Spacer"
import Label from "components/labels/Label"


const AbsenceListItem = ({absence, ...props}) => {
    return (
        <ListItem displayLine={true} {...props}>
            <Spacer vSmall>
                <Label>{absence.type_label}</Label>
                <Label small subtle>{formatPeriodString(absence.begin_date, absence.end_date)}</Label>
            </Spacer>
        </ListItem>
    )
}

export default AbsenceListItem