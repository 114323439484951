import styled from 'styled-components'


const Container = styled.div`
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-bottom-color: ${props => props.active ? '#ce0069' : '#ddd'};
    cursor: pointer;

    &:hover {
        background-color: #f7f7f7;
    }
`


const Tab = ({ active=false, children, ...props }) => {

    return (
        <Container active={active} {...props}>
            {
                (typeof children === 'function') ?
                    children({active})
                    :
                    children         
            }
        </Container>
    )
}

export default Tab