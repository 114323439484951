import Assignment from "./Assignment"
import { TagProps } from "Tags/TagContainer"
import ListItem from "components/ListView/BetterListItem"
import { useMemo } from "react"
import Theme from "Theming/Theme"
import { formatPeriodString } from "utils/timeStringUtility"

export interface AssignmentListItemProps {
    assignment:Assignment,
    showCustomerName?:boolean,
    showEmployeeName?:boolean,
    onClick?:(assignment:Assignment) => any
}

const AssignmentListItem = ({ 
    assignment, 
    showCustomerName=true, 
    showEmployeeName=true,
    onClick=(assignment) => {}
}: AssignmentListItemProps) => {

    const getCustomerName = (assignment:Assignment, fallback:string) => {
        if(assignment?.customer_id) {
            return `${assignment?.customer_last_name}, ${assignment?.customer_first_name}`
        }
        return fallback
    }

    const getEmployeeName = (assignment: Assignment, fallback: string) => {
        if (assignment?.employee_id) {
            return `${assignment?.employee_last_name}, ${assignment?.employee_first_name}`
        }
        return fallback
    }

    const title = useMemo(() => {
        const names = []
        if(showEmployeeName) {
            names.push(getEmployeeName(assignment, 'Offen'))
        }
        if(showCustomerName) {
            names.push(getCustomerName(assignment, 'Offen'))
        }
        return names.join(' - ')
    }, [assignment, showCustomerName, showEmployeeName])

    const tags= useMemo(() => {
        const tags:TagProps[] = []

        if (assignment?.advertise) {
            tags.push({
                text: `Anfrage in Kundenbörse`,
                backgroundColor: Theme.secondaryColor
            })
        }
        if(assignment?.monthly_hours) {
            tags.push({
                text: `${assignment.monthly_hours} Stunden`,
                backgroundColor: Theme.subtleColor
            })
        }

        return tags
    }, [assignment])

    return (
        <ListItem
            title={title}
            subtitle={formatPeriodString(assignment?.start_date, assignment?.end_date, null)}
            onClick={() => onClick(assignment)}
            tags={tags}
        />
    )
}

export default AssignmentListItem