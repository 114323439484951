import { FaCircleNotch } from 'react-icons/fa';
import styled from 'styled-components'

const TBody = styled.tbody`
    max-height: 200px;
    maxWidth: 100%;
    position: relative;
    vertical-align: top;
`

const TRow = styled.tr`
    border-bottom: 1px solid #ebebeb;

    &.interactable {
        transition: 0.25s;
        &:hover {
            background-color: #f8c5e0;
            cursor: pointer;
        }
    }

    &:nth-child(even) {
        background-color: white;
    }

    &:nth-child(odd) {          
        background-color: #f7f7f7;
    }
`

const TD = styled.td`
    width: ${props => props.width};
    min-width: ${props => props.minWidth};
    max-width: ${props => props.maxWidth};
`

const LoadingMask = styled.span`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f3f3f3a6;
`

const Spinner = styled(FaCircleNotch)`
    color: #a9a9a9;
    animation: spin 1s linear infinite;
    width: 2em;
    height: 2em;
    position: sticky;
    top: 0;
    bottom: 0;
`

const TableBody = ({isLoading=false, prepareRow, onClick=null, rows, ...props}) => {

    const rowPropGetter = (row) => {
        if(onClick) {
            return {
                onClick: () => {
                    onClick(row.original);
                },
                className: "interactable"
            }
        }
    }

    return (
        <TBody {...props}>
            {
                rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <TRow key={i} {...row.getRowProps(rowPropGetter(row))}>
                            {row.cells.map((cell, i) => {
                                return (
                                    <TD 
                                        key={i}
                                        {...cell.getCellProps()}
                                        width={cell.column.width}
                                        maxWidth={cell.column.maxWidth}
                                        minWidth={cell.column.minWidth}
                                    >
                                        {cell.render("Cell")}
                                    </TD>
                                )
                            })}
                        </TRow>
                    )
                })
            }
            {isLoading && (
                <LoadingMask colSpan={100} className='table__load-icon'>
                    <Spinner/>
                </LoadingMask>
            )}
        </TBody>
    )
}

export default TableBody