import { useMemo } from "react"
import NumberUtility from "utils/NumberUtlity"


const useVacationRuleFields = (rule) => {

    const fields = useMemo(() => {

        return {
            id: {
                initialValue: rule?.id
            },
            employee_id: {
                initialValue: rule?.employee_id,
                validate: NumberUtility.isPositiveInteger
            },
            start_year: {
                initialValue: rule?.start_year,
                validate: value => NumberUtility.isPositiveInteger(parseInt(value))
            },
            weeks: {
                initialValue: rule?.weeks
            },
            days: {
                initialValue: rule?.days
            }
        }
    }, [rule])

    return fields
}

export default useVacationRuleFields