import Tile from "components/Tiles/Tile"
import Person from "./Person"
import Spacer from "components/Spacer/Spacer"
import { useContext, useMemo } from "react"
import { ModalContext } from "components/modal/ModalContext"
import { FaPen } from "react-icons/fa"
import PersonTileSections from "./PersonTileSections"

interface Props {
    person: Person
}

const PersonTile = ({ person }: Props) => {

    const { personModal } = useContext(ModalContext)

    const deleteMessage = useMemo(() => {
        if (!person?.deleteAt) {
            return undefined
        }
        return `Wird am ${person?.deleteAt.format('DD.MM.YY')} gelöscht`
    }, [person])

    return (
        <Tile 
            title="Personendaten"
            subtitle={ deleteMessage}
            actions={[
                {
                    Icon: <FaPen/>,
                    execute: () => personModal.select(person)
                }
            ]}
        > 
            <Spacer hMedium>
                <PersonTileSections person={person}/>       
            </Spacer>
        </Tile>
    )
}

export default PersonTile