import { getObjectProperty, setObjectProperty } from "./ObjectUtility"
import { ArrayProperty, ObjectProperty, Properties, Property, SingleProperty } from "./Property"
import { ArrayPropertyDefinition, ObjectPropertyDefinition, PropertyDefinition, SinglePropertyDefinition } from "./PropertyDefinition"
import { ObjectData } from "./useObject"

type Instance = {
    primaryKey: boolean,
    path: string,
    label: string,
    value: any,
    enabled: boolean,
    required: boolean,
    setValue: (value: any) => any,
    setEnabled: (value: any) => any,
    valid: boolean,
    renderControl?: (property: Property<Instance>) => React.ReactNode
}

export type SinglePropertyInstance = SingleProperty<Instance>


export type ObjectPropertyInstance = ObjectProperty<Instance>


export type ArrayPropertyInstance = ArrayProperty<Instance>

export type PropertyInstance = SinglePropertyInstance | ObjectPropertyInstance | ArrayPropertyInstance
export type PropertyInstances = Properties<Instance>


export type PropertyStateProps = ObjectData & {
    setValue: (path:string, value:any) => any,
    setEnabled: (path:string, value:boolean) => any
}

function finalizeDefinition<T>(definition:T, props:PropertyStateProps, path:string) : T{
    const {
        enabled,
    } = props


    const defaultDefinition = {
        required: false,
        enabled: true,
        primaryKey: false
    }

    const stateValues = {
        enabled: getObjectProperty(enabled, path)
    }

    return Object.assign(defaultDefinition, definition, stateValues)
}

function isEmptyAndNotRequired(definition:PropertyDefinition, value:any) : boolean {
    if((value === null || value === undefined)) {
        return !definition.required
    }
    return false
}

export function instantiateProperty(definition: PropertyDefinition, props:PropertyStateProps, path:string='') {
    if(definition.adjustData) {
        var adjustedData = {
            values: getObjectProperty(props.values, path), 
            enabled: getObjectProperty(props.enabled, path)
        }
        adjustedData = definition.adjustData(adjustedData)
        props.values = setObjectProperty(props.values, path, adjustedData.values)
        props.enabled = setObjectProperty(props.enabled, path, adjustedData.enabled)
    }

    if (definition.type === 'object') {
        return instantiateObjectProperty(definition, props, path)
    }
    else if (definition.type === 'single') {
        return instantiateSingleProperty(definition, props, path)
    }
    else if (definition.type === 'array') {
        return instantiateArrayProperty(definition, props, path)
    }
    return null
}

function instantiateObjectProperty(definition:ObjectPropertyDefinition, props: PropertyStateProps, path:string='') : ObjectPropertyInstance {
    const {
        setValue,
        setEnabled
    } = props

    definition = finalizeDefinition(definition, props, path)

    var properties: PropertyInstances = Object.keys(definition.properties).reduce((result, key) => {
        var childDefinition = definition.properties[key]
        var childPath = [path, key].filter(e => e !== '').join('/')
        var childProperty = instantiateProperty(childDefinition, props, childPath)
        if(childProperty) {
            return {
                ...result,
                [key]: childProperty
            }
        }
        return result
    }, {})

    const propertiesValid = Object.values(properties).reduce((allValid, property) => allValid && property.valid, true)
    const propertyValues = Object.keys(properties).reduce((result, key) => {
        var value = properties[key].value
        if(value !== undefined) {
            return {
                ...result,
                [key]: value
            }
        }
        return result
    }, {})

    const enabled = path === '' ? true : getObjectProperty(props.enabled, path, true)
    const value = enabled ? Object.keys(propertyValues).length === 0 ? null : propertyValues : undefined
    const valid = isEmptyAndNotRequired(definition, value) || propertiesValid


    return {
        type: 'object',
        required: definition.required,
        label: definition.label,
        primaryKey: definition.primaryKey,
        renderControl: definition.renderControl,
        enabled: enabled,
        value,
        path,
        valid,
        properties,
        setEnabled: (value: boolean) => setEnabled(path, value),
        setValue: (value:any) => setValue(path, value),
    }
}


function instantiateSingleProperty(definition:SinglePropertyDefinition, props: PropertyStateProps, path:string=''): SinglePropertyInstance {
    const {
        setValue,
        setEnabled
    } = props

    definition = finalizeDefinition(definition, props, path)
    const enabled = getObjectProperty(props.enabled, path, true)
    const value = enabled ? getObjectProperty(props.values, path) : null
    const valid = isEmptyAndNotRequired(definition, value) || definition.valueType.validate(value)

    return {
        type: 'single',
        primaryKey: definition.primaryKey,
        required: definition.required,
        label: definition.label,
        valueType: definition.valueType,
        renderControl: definition.renderControl,
        enabled: enabled,
        value,
        path,
        valid,
        setEnabled: (value: boolean) => setEnabled(path, value),
        setValue: (value: any) => setValue(path, value),
    }
}



function instantiateArrayProperty(definition: ArrayPropertyDefinition, props: PropertyStateProps, path: string = ''): ArrayPropertyInstance {
    const {
        setValue,
        setEnabled
    } = props

    definition = finalizeDefinition(definition, props, path)

    var properties:PropertyInstance[] = []

    if(definition.elementDefinition) {
        var values = getObjectProperty(props.values, path, [])
        if(Array.isArray(values)) {
            properties = values.map((value, index) => {
                var childPath = [path, index].filter(e => e !== '').join('/')
                return instantiateProperty(definition.elementDefinition, props, childPath)
            })
        }
    }

    const enabled = getObjectProperty(props.enabled, path, true)
    const propertiesValid = properties.reduce((allValid, property) => allValid && property.valid, true)
    const propertyValues = properties.map((childProperty) => childProperty.value).filter(value => value !== undefined)
    const value = enabled ? propertyValues : undefined
    const valid = isEmptyAndNotRequired(definition, value) || propertiesValid


    return {
        type: 'array',
        required: definition.required,
        label: definition.label,
        primaryKey: definition.primaryKey,
        renderControl: definition.renderControl,
        enabled,
        value,
        path,
        valid,
        properties,
        setEnabled: (value: boolean) => setEnabled(path, value),
        setValue: (value: any) => setValue(path, value),
    }
}