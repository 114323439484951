import useVacationBookingRequest from "Vacations/useVacationBookingRequest";
import Column from "components/Column"
import ConditionalView from "components/Decorator/ConditionalView";
import ListView from "components/ListView/ListView"
import Label from "components/labels/Label"
import { useEffect, useMemo } from "react";
import styled from 'styled-components';

const BookingLabel = styled(Label)`
    :before {
        content:"• ";
        color: #474747;
    }
`

const VacationBookingPreview = ({ 
    employeeId, 
    start,
    end, 
    requestDate,
    setBookingPreview = (value) => {}
}) => {

    const bookingParams = useMemo(() => ({
        employee_id: employeeId,
        start,
        end,
        request_date: requestDate
    }), [employeeId, start, end, requestDate])

    const { data: bookingResponse } = useVacationBookingRequest(bookingParams)

    useEffect(() => {
        setBookingPreview(bookingResponse)
    }, [setBookingPreview, bookingResponse])


    return (
        <Column gap='1em' width='18em'>
            <Label bold>Buchungsvorschau</Label>
            {
                bookingResponse?.bookings?.length > 0 ? (
                    <Column gap='0.25em'>
                        <ListView
                            data={bookingResponse.bookings}
                            listItem={(booking) => <BookingLabel small>{`${booking.days} Tage für ${booking.charged_year}`}</BookingLabel>}
                            Separator={() => null}
                        />
                        <ConditionalView condition={bookingResponse.unbookedDays > 0}>
                            <Label small danger>{`${bookingResponse.unbookedDays} Tage können nicht verbucht werden`}</Label>
                        </ConditionalView>
                    </Column>
                ) : (
                    <Label small subtle>Es werden keine Buchungen vorgenommen</Label>
                )
            }
        </Column>
    )
}

export default VacationBookingPreview