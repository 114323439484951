import ameli from "Api/ameli";
import Label from "components/labels/Label";
import { useEffect, useMemo, useState } from "react";

export default function TaxBracketLabel({id, ...props}) {

    const [taxBrackets, setTaxBrackets] = useState([])

    useEffect(() => {
        ameli.get("/tax_brackets")
            .then(response => {
                setTaxBrackets(response.data)
            })
            .catch(error => setTaxBrackets([]))
    }, []);

    const taxBracket = useMemo(() => {
        return taxBrackets.find(b => b.tax_bracket_id === id)
    }, [id, taxBrackets])

    return (
        <Label {...props}>{taxBracket ? taxBracket.tax_bracket_label : '-'}</Label>
    );
}
