import Dropdown from "components/dropdown/dropdown"
import { useMemo } from "react"

export default function CustomerStateSelect(props) {

    const options = useMemo(() => ([
        { label: 'Interessent', value: null },
        { label: 'Bestandskunde', value: true }
    ]), [])

    return (
        <Dropdown
            options={options}
            {...props}
            allowNull={false}
            placeholder="Interessent"
        >
        </Dropdown>
    )
}