import Modal, { ModalFooter } from "components/modal/Modal"
import Spacer from "components/Spacer/Spacer"
import usePhoneNumbers from "./usePhoneNumbers"
import PhoneListControl from "./PhoneListControl"
import { useContext } from "react"
import { ModalContext } from "components/modal/ModalContext"

const PhoneListModal = ({selected:contactDetailsId, visible, setVisible}) => {

    const { data: phoneNumbers } = usePhoneNumbers(contactDetailsId)
    const { phoneModal } = useContext(ModalContext)

    return (
        <Modal visible={visible} setVisible={setVisible} title="Telefonnummern bearbeiten">
            <Spacer hMedium vLarge>
                <PhoneListControl
                    phoneNumbers={phoneNumbers}
                    onClick={phoneModal.select}
                />
            </Spacer>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    )
}

export default PhoneListModal
