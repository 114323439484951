import CareLevelLabel from "CareLevel/CareLevelLabel"
import TileSection from "components/TileSection"
import Tile from "components/Tiles/Tile";
import Spacer from "components/Spacer/Spacer";
import { ClinicalPicture } from "./ClinicalPicture";
import { FaHeartbeat, FaPen } from "react-icons/fa";
import useModal from "components/modal/useModal";
import ClinicalPictureModal from "./ClinicalPictureModal";
import ClinicalPictureList from "./ClinicalPictureList";

interface Props {
    clinicalPicture:ClinicalPicture
}

const ClinicalPictureTile = ({ clinicalPicture }:Props) => {

    const clinicalPictureModal = useModal<ClinicalPicture>()

    if(!clinicalPicture) {
        return (
            null
        )
    }

    return (
        <>
            <ClinicalPictureModal {...clinicalPictureModal}/>
            <Tile
                title='Krankengeschichte'
                Icon={<FaHeartbeat/>}
                actions={[
                    {
                        Icon:<FaPen/>,
                        execute:() => clinicalPictureModal.select(clinicalPicture)
                    }
                ]}
            >
                <Spacer hMedium>
                    <TileSection label='Pflegegrad' visible={Boolean(clinicalPicture?.care_level)}>
                        <CareLevelLabel
                            careLevel={clinicalPicture?.care_level}
                            referenceDate={clinicalPicture?.care_level_reference_date}
                        />
                    </TileSection>
                    <TileSection label='Krankheitsbild'>
                        <ClinicalPictureList clinicalPicture={clinicalPicture}/>
                    </TileSection>
                </Spacer>
            </Tile>
        </>
    )
}

export default ClinicalPictureTile