
const parseError = (error) => {

    if (error.toJSON === undefined) {
        return error
    }

    const json = error.toJSON()
    var status = json.status

    switch (status) {
        case 404:
            return {
                status,
                title: "Pfad nicht gefunden",
                details: error.response.data.path,
                actions: ['return', 'send_error']
            }
        case 421:
            return {
                status,
                title: "Bitte alle Felder ausfüllen",
                details: error.response.data.message,
                actions: []
            }
        case 422:
            return {
                status,
                title: "Konto für diesen Mitarbeiter existiert bereits",
                details: error.response.data.message,
                actions: []
            }
        case 423:
            return {
                status,
                title: "Mitarbeiter nicht gefunden",
                details: error.response.data.message,
                actions: []
            }
        case 431:
            return {
                status,
                title: "Bitte alle Felder ausfüllen",
                details: error.response.data.message,
                actions: []
            }
        case 432:
            return {
                status,
                title: "Kein Konto mit dieser E-Mail-Adresse gefunden",
                details: error.response.data.message,
                actions: []
            }
        case 433:
            return {
                status,
                title: "Passwort ist ungültig",
                details: error.response.data.message,
                actions: []
            }
        case 502:
            return {
                status,
                title: "Server konnte nicht erreicht werden",
                details: error.message,
                actions: ['signout']
            }
        case 500:
            return {
                status,
                title: "Interner Server Fehler",
                details: error.response.data.message,
                actions: ['return', 'send_error']
            }
        default:
            return {
                status,
                title: "Unbekannter Fehler",
                details: error.message,
                actions: ['signout', 'send_error']
            }
    }
}

export default parseError